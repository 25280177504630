export function chunkArray<T>(array: T[], chunkSize: number = 2000): T[][] {
  const chunks: T[][] = [];
  let i = 0;

  while (i < array.length) {
    chunks.push(array.slice(i, i + chunkSize));
    i += chunkSize;
  }

  return chunks;
}
