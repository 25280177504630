import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import ManufacturerImage from './5.1.1-Manufacturer.png';
import CautionImage from './5.4.4-caution.png';
import InstructionImage from './5.4.3-instructions.png';
import MedicalDevice from './5.7.7-medical-device.png';
import CEImage from './ce-num.png';
import UdiImage from './5.7.10-udi.png';

import packageJson from '../../../package.json';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: 'auto',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  software: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
  },
  imageContainer: {
    display: 'flex',
  },
  image: {
    height: '70px',
  },
  link: {
    fontSize: '1rem',
  },
  udiItem: {
    textAlign: 'center',
  },
  udiNumber: {
    marginTop: -theme.spacing(3),
  },
}));

function SoftwareInfo() {
  const classes = useStyles();

  return (
    <Grid xs={3} item>
      <Typography className={classes.software}>Zive AI v{packageJson.version}</Typography>
      <Link className={classes.link} href="https://zive.io/">
        https://zive.io/
      </Link>

      <Typography>Made in Lithuania, 2023</Typography>
    </Grid>
  );
}

function Manufacturer() {
  return (
    <Grid xs={2} item>
      <Typography>Zive UAB</Typography>
      <Typography>Linkmenų 5-35,</Typography>
      <Typography>LT-09300 Vilnius,</Typography>
      <Typography>LITHUANIA</Typography>
    </Grid>
  );
}

const images = [
  { src: ManufacturerImage, alt: 'manufacturer' },
  { src: CautionImage, alt: 'caution' },
  { src: InstructionImage, alt: 'instruction' },
  { src: MedicalDevice, alt: 'md' },
  { src: CEImage, alt: 'ce' },
];

function Icons() {
  const classes = useStyles();

  return (
    <Grid xs={6} alignItems="center" item>
      <div className={classes.imageContainer}>
        {images.map((img) => (
          <img key={img.alt} className={classes.image} src={img.src} alt={img.alt} />
        ))}
        <div className={classes.udiItem}>
          <img className={classes.image} src={UdiImage} alt="udi" />
          <Typography className={classes.udiNumber}>4779053460128</Typography>
        </div>
      </div>
    </Grid>
  );
}

export function Footer() {
  const classes = useStyles();

  return (
    <Container maxWidth="md" component="footer" className={classes.footer}>
      <Grid spacing={4} justifyContent="space-evenly" container>
        <SoftwareInfo />
        <Manufacturer />
        <Icons />
      </Grid>
    </Container>
  );
}
