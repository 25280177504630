import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { usePatients } from 'components/features/patients/usePatients';
import { useUpdatePatient } from 'components/features/patients/useUpdatePatient';
import { ButtonMenuLayout } from './button-menu.layout';
import { ChartSettings } from './chart-settings';
import { ShortcutsText } from './shortcuts';
import { useUploadContext } from '../../context/UploadContext';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    backgroundColor: 'white',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function ClinicUserLayout() {
  const classes = useStyles();

  const { uploadInProgress } = useUploadContext();

  const { patients, loading } = usePatients();

  const { updatePatient } = useUpdatePatient();

  if (loading || !patients) {
    return null;
  }

  const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === 'none') {
      const activePatient = patients.find((patient) => patient.isActive);

      if (activePatient) {
        await updatePatient({
          variables: {
            patientId: activePatient.id,
            patientInput: {
              isActive: false,
            },
          },
        });
      }

      window.location.reload();

      return null;
    }

    await updatePatient({
      variables: {
        patientId: event.target.value as string,
        patientInput: {
          isActive: true,
        },
      },
    });

    window.location.reload();
  };

  const patientsMap = patients.reduce((acc, patient) => {
    acc[patient.id] = `${patient.name} ${patient.surname ?? ''}`;

    return acc;
  }, {});

  // find patient that has isActive set to true
  const activePatient = patients.find((patient) => patient.isActive);

  return (
    <>
      <Button
        color="inherit"
        component={RouterLink}
        to="/recording-sequences"
        disabled={uploadInProgress}
      >
        Recording Sequences
      </Button>
      <Button color="inherit" component={RouterLink} to="/recordings" disabled={uploadInProgress}>
        Recordings
      </Button>
      <Button color="inherit" component={RouterLink} to="/upload" disabled={uploadInProgress}>
        Upload
      </Button>
      <Button color="inherit" component={RouterLink} to="/patients" disabled={uploadInProgress}>
        Patients
      </Button>

      <FormControl variant="filled" className={classes.formControl} disabled={uploadInProgress}>
        <InputLabel>Active Patient</InputLabel>
        <Select value={activePatient?.id ?? ''} onChange={handleChange}>
          <MenuItem value="none">
            <em>None</em>
          </MenuItem>
          {patients?.map((patient) => (
            <MenuItem key={patient.id} value={patient.id}>
              {patientsMap[patient.id]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className={classes.grow} />
      <ShortcutsText />
      <ChartSettings />
      <ButtonMenuLayout />
    </>
  );
}
