export function groupByRange(data: number[]) {
  if (data == null) {
    return {};
  }

  return data.reduce<{ number?: number[] }>((acc, val) => {
    if (acc[val]) {
      acc[val].push(val);
    } else {
      acc[val] = [val];
    }

    return acc;
  }, {});
}

export function groupByHour(timestampsInSs: number[], values: (null|number)[]) {
  // group by time: { '2023-08-27 19:00 - 20:00': [1, 2, 3, 4, 5, 6, 7, 8, 9] }
  const hoursGroup: { [key: string]: number[] } = {};

  if (timestampsInSs == null || values == null) {
    return hoursGroup;
  }

  timestampsInSs.forEach((timestamp, index) => {
    const date = new Date(timestamp * 1000);
    const hour = date.getHours();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    const hourEnd = (hour + 1) % 24;
    const hourRange = `${year}-${month}-${day} ${hour}:00 - ${hourEnd}:00`;

    if (!hoursGroup[hourRange]) {
      hoursGroup[hourRange] = [];
    }

    hoursGroup[hourRange].push(values[index] as number);
  });

  return hoursGroup;
}
