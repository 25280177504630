import { gql } from '@apollo/client';

export const DELETE_RECORDING = gql`
  mutation DeleteRecordingMutation($deleteRecordingRecordingId: MongoObjectID!) {
    deleteRecording(recordingId: $deleteRecordingRecordingId) {
      success
      error
    }
  }
`;
