import { gql } from '@apollo/client';

export const UPDATE_SUPRAVENTRICULAR_DUPLET = gql`
  mutation UpdateSupraVentricularDuplet($id: MongoObjectID!, $isValid: Boolean!) {
    updateSupraVentricularDuplet(id: $id, isValid: $isValid) {
      error
      success
    }
  }
`;

export const UPDATE_SUPRAVENTRICULAR_TRIPLET = gql`
  mutation UpdateSupraVentricularTriplet($id: MongoObjectID!, $isValid: Boolean!) {
    updateSupraVentricularTriplet(id: $id, isValid: $isValid) {
      error
      success
    }
  }
`;

export const UPDATE_SUPRAVENTRICULAR_RUN = gql`
  mutation UpdateSupraVentricularRun($id: MongoObjectID!, $isValid: Boolean!) {
    updateSupraVentricularRun(id: $id, isValid: $isValid) {
      error
      success
    }
  }
`;

export const UPDATE_VENTRICULAR_DUPLET = gql`
  mutation UpdateVentricularDuplet($id: MongoObjectID!, $isValid: Boolean!) {
    updateVentricularDuplet(id: $id, isValid: $isValid) {
      error
      success
    }
  }
`;

export const UPDATE_VENTRICULAR_TRIPLET = gql`
  mutation UpdateVentricularTriplet($id: MongoObjectID!, $isValid: Boolean!) {
    updateVentricularTriplet(id: $id, isValid: $isValid) {
      error
      success
    }
  }
`;

export const UPDATE_VENTRICULAR_RUN = gql`
  mutation UpdateVentricularRun($id: MongoObjectID!, $isValid: Boolean!) {
    updateVentricularRun(id: $id, isValid: $isValid) {
      error
      success
    }
  }
`;

export const getMutationForTypeName = (typeName) => {
  const mutations = {
    VentricularDuplet: UPDATE_VENTRICULAR_DUPLET,
    VentricularTriplet: UPDATE_VENTRICULAR_TRIPLET,
    VentricularRun: UPDATE_VENTRICULAR_RUN,
    SupraVentricularDuplet: UPDATE_SUPRAVENTRICULAR_DUPLET,
    SupraVentricularTriplet: UPDATE_SUPRAVENTRICULAR_TRIPLET,
    SupraVentricularRun: UPDATE_SUPRAVENTRICULAR_RUN,
  };

  return mutations[typeName] || UPDATE_SUPRAVENTRICULAR_RUN;
};
