import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import { HealthIndication } from 'types/schema.type';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 600,
  },
  cell: {
    border: 'none',
  },
  cellTime: {
    border: 'none',
    cursor: 'pointer',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

type HealthIndicationTableProps = {
  healthIndication: HealthIndication[];
  excerpt?: boolean;
  callback: (timestamp: number) => void;
};

export function HealthIndicationTable(props: HealthIndicationTableProps) {
  const classes = useStyles();

  const { healthIndication, excerpt } = props;

  return (
    <Container className={classes.root} component={Paper}>
      {!excerpt && (
        <Typography className={classes.title} variant="h6">
          Health Indications
        </Typography>
      )}
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>Duration (sec)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {healthIndication.map((it) => (
            <TableRow key={it.id}>
              <TableCell className={classes.cell}>{it.type}</TableCell>
              <TableCell className={classes.cellTime} onClick={() => props.callback(it.recordedAt)}>
                {format(it.recordedAt, 'yyy-MM-dd H:mm:ss')}
              </TableCell>
              <TableCell className={classes.cell}>{it.content}</TableCell>
              <TableCell className={classes.cell}>{it.duration_s ?? 0}</TableCell>
            </TableRow>
          ))}
          {healthIndication.length === 0 && (
            <TableRow>
              <TableCell className={classes.cell}>None</TableCell>
              <TableCell className={classes.cell} align="right" />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
}
