import { gql } from '@apollo/client';

export const CREATE_RECORDING = gql`
  mutation CreateMyRecordingMutation($createMyRecordingChannelCount: Int) {
    createMyRecording(channelCount: $createMyRecordingChannelCount) {
      success
      recordingId
      ecgRecordingUploadUrl
      error
    }
  }
`;
