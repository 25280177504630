import { gql } from '@apollo/client';

export const GET_RECORDING_DATA = gql`
  query GetRecordingData($getMyRecordingId: MongoObjectID!) {
    getMyRecording(id: $getMyRecordingId) {
      id
      recordingStartedAt
      channelCount
      comment
      analysis {
        id
        annotations {
          sampleIndex
          annotationValue
        }
        pPeaks
        qPeaks
        sPeaks
        tPeaks
        automaticRpeakAnnotations {
          sampleIndex
          annotationValue
        }
        noises {
          id
          startIndex
          endIndex
          annotationValue
        }
        afibs {
          id
          startIndex
          endIndex
          annotationValue
        }
        rate
      }
      json {
        ch1
        ch2
        ch3
      }
    }
  }
`;
