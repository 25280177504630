import { useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import Logo from '../../assets/logo.avif';
import CENum from '../footer/ce-num.png';
import { SequenceListItem } from './SequenceListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
  },
  logo: {
    height: '100px',
  },
  ceNum: {
    height: '50px',
  },
  header: {
    width: 900,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(4),
  },
  logos: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

export function SequenceReport() {
  const classes = useStyles();
  
  const params = new URLSearchParams(window.location.search);
  const language = params.get('lang') || 'en';

  useEffect(() => {
    i18next.changeLanguage(language); // Change the language based on the URL parameter

    return () => {
      i18next.changeLanguage('en'); // Reset the language to English when the component is unmounted
    };
  }, [language]);


  const { sequenceId } = useParams<{ sequenceId: string }>();

  if (sequenceId == null) {
    throw new Error('Invalid parameters. Sequence ID missing.');
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.header} spacing={4} container>
        <div className={classes.logos}>
          <img className={classes.logo} src={Logo} alt="logo" />
          <img className={classes.ceNum} src={CENum} alt="CE" />
        </div>
        <div>
          <Typography variant="h6">www.zive.io</Typography>
          <Typography variant="h6">hello@zive.io</Typography>
        </div>
      </Grid>
      <SequenceListItem />
    </div>
  );
}
