import { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { DataGrid, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { format } from 'date-fns';

import { LoadingSpinner } from '../LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    width: 1400,
    marginTop: theme.spacing(4),
  },
  generateButton: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const GET_ACTIVATION_CODES = gql`
  query GetActivationCodes {
    getActivationCodes {
      error
      success
      activationCodes {
        id
        code
        isActive
        isListed
        activatedAt
        createdAt
        expiresAt
        durationSec
        user {
          id
          email
        }
      }
    }
  }
`;

export const GENERATE_ACTIVATION_CODES = gql`
  mutation GenerateActivationCodes($input: GenerateActivationCodesInput!) {
    generateActivationCodes(input: $input) {
      activationCodes {
        id
        code
        isActive
        isListed
        activatedAt
        createdAt
        expiresAt
        durationSec
        user {
          id
          email
        }
      }
      error
      success
    }
  }
`;

export const UPDATE_ACTIVATION_CODES = gql`
  mutation UpdateActivationCodes($input: ActivationCodeInput!) {
    updateActivationCodes(input: $input) {
      error
      success
    }
  }
`;

const columns = [
  { field: 'code', headerName: 'Code', width: 175 },
  { field: 'duration', headerName: 'Duration d.', width: 150 },
  { field: 'isActive', headerName: 'Is Active', width: 150 },
  { field: 'isListed', headerName: 'Is Listed', width: 150 },
  { field: 'activatedAt', headerName: 'Activated At', width: 200 },
  { field: 'expiresAt', headerName: 'Expires At', width: 200 },
  { field: 'createdAt', headerName: 'Created At', width: 200 },
  { field: 'email', headerName: 'User Email', width: 220 },
];

export function ActivationCodes() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<GridSelectionModel>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);
  const [codeType, setCodeType] = useState('S1M');

  const { loading: dataLoading, error, data } = useQuery(GET_ACTIVATION_CODES);

  const [generateActivationCodes, { loading: generateLoading, error: generateError }] = useMutation(
    GENERATE_ACTIVATION_CODES,
    {
      refetchQueries: [{ query: GET_ACTIVATION_CODES }],
    },
  );

  const [updateActivationCodes] = useMutation(UPDATE_ACTIVATION_CODES, {
    refetchQueries: [{ query: GET_ACTIVATION_CODES }],
  });

  // data fetch error handling
  if (dataLoading) return <LoadingSpinner />;

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error.message}
      </Alert>
    );
  }

  const { getActivationCodes } = data;

  if (!getActivationCodes.success) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {getActivationCodes.error}
      </Alert>
    );
  }

  // data mutation error handling
  if (generateLoading) return <LoadingSpinner />;

  if (generateError) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {generateError.message}
      </Alert>
    );
  }

  const { activationCodes } = getActivationCodes;

  const rows = activationCodes.map((activationCode) => ({
    id: activationCode.id,
    code: activationCode.code,
    duration: activationCode.durationSec && activationCode.durationSec / 60 / 60 / 24,
    activatedAt:
      activationCode.activatedAt && format(activationCode.activatedAt, 'yyy-MM-dd H:mm:ss'),
    expiresAt: activationCode.expiresAt && format(activationCode.expiresAt, 'yyy-MM-dd H:mm:ss'),
    createdAt: format(activationCode.createdAt, 'yyy-MM-dd H:mm:ss'),
    isActive: activationCode.isActive,
    isListed: activationCode.isListed,
    email: activationCode.user?.email,
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    generateActivationCodes({
      variables: {
        input: {
          count: 10,
          type: codeType,
        },
      },
    });
    setOpen(false);
  };

  const handleCodeTypeChange = (event) => {
    setCodeType(event.target.value);
  };

  const handleUpdateActivationCodes = () => {
    updateActivationCodes({
      variables: {
        input: {
          ids: selected,
          isListed: true,
        },
      },
    });
    setSelected([]);
  };

  return (
    <>
      <Container className={classes.container} maxWidth={false}>
        <Button
          className={classes.generateButton}
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          Generate Codes
        </Button>

        {selected.length > 0 && (
          <Button
            className={classes.generateButton}
            variant="contained"
            color="secondary"
            onClick={handleUpdateActivationCodes}
          >
            Set to listed
          </Button>
        )}

        <div style={{ width: '100%', height: 650 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            sortModel={sortModel}
            selectionModel={selected}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(newSelection) => {
              setSelected([...newSelection]);
            }}
            onSortModelChange={(model) => setSortModel(model)}
          />
        </div>

        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
        >
          <DialogTitle id="alert-dialog-title">Generate Codes?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please choose the activation code period:
            </DialogContentText>
            <Select value={codeType} autoFocus onChange={handleCodeTypeChange}>
              <MenuItem value="S1M">1 Month (10 codes)</MenuItem>
              <MenuItem value="L1Y">1 Year (10 codes)</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" autoFocus onClick={handleConfirm}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
