import { gql } from '@apollo/client';

export const EDIT_INTERVAL = gql`
  mutation Mutation($input: EditIntervalInput!) {
    editInterval(input: $input) {
      success
      error
      noise {
        id
        startIndex
        endIndex
        annotationValue
      }
    }
  }
`;
