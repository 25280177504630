import { gql, useQuery } from '@apollo/client';
import { Recording } from 'types/schema.type';
import { TachycardiaResultsTable } from './TachycardiaResultsTable';

const sampleData = {
  recordingStartedAt: 1661074449122,
  ventricularDuplets: [{ sampleIndexes: [1, 2] }, { sampleIndexes: [3, 4] }],
  supraVentricularDuplets: [{ sampleIndexes: [5, 6] }, { sampleIndexes: [7, 8] }],
  ventricularTriplets: [{ sampleIndexes: [9, 10, 11] }],
  supraVentricularTriplets: [],
  ventricularRuns: [{ sampleIndexes: [12, 13, 14, 15] }, { sampleIndexes: [16, 17, 18] }],
  supraVentricularRuns: [],
};

const GET_TACHYCARDIA_RESULTS = gql`
  query GetMyRecording($getMyRecordingId: MongoObjectID!) {
    getMyRecording(id: $getMyRecordingId) {
      recordingStartedAt
      analysis {
        id
        ventricularDuplets {
          sampleIndexes
        }
        supraVentricularDuplets {
          sampleIndexes
        }
        ventricularTriplets {
          sampleIndexes
        }
        supraVentricularTriplets {
          sampleIndexes
        }
        ventricularRuns {
          sampleIndexes
        }
        supraVentricularRuns {
          sampleIndexes
        }
      }
    }
  }
`;

type GetTachycardiaResultsResponse = { getMyRecording: Recording };

export function TachycardiaResultsContainer(props: {
  recordingId: string;
  scale: [number, number];
  setScale: (scale: [number, number]) => void;
}) {
  const { loading, data } = useQuery<GetTachycardiaResultsResponse>(GET_TACHYCARDIA_RESULTS, {
    variables: {
      getMyRecordingId: props.recordingId,
    },
  });

  const handleCallback = (sampleIndex) => {
    const halfScale = Math.round((props.scale[1] - props.scale[0]) / 2);

    const start = sampleIndex - halfScale;
    const end = sampleIndex + halfScale;

    props.setScale([start, end]);
  };

  if (loading) {
    return <div>Loading tachycardia results...</div>;
  }

  if (
    !data?.getMyRecording ||
    !data.getMyRecording.analysis ||
    !data.getMyRecording.recordingStartedAt
  ) {
    return (
      <TachycardiaResultsTable
        recordingStartedAt={sampleData.recordingStartedAt}
        ventricularDuplets={sampleData.ventricularDuplets}
        supraVentricularDuplets={sampleData.supraVentricularDuplets}
        ventricularTriplets={sampleData.ventricularTriplets}
        supraVentricularTriplets={sampleData.supraVentricularTriplets}
        ventricularRuns={sampleData.ventricularRuns}
        supraVentricularRuns={sampleData.supraVentricularRuns}
        callback={handleCallback}
      />
    );
  }

  const {
    recordingStartedAt,
    analysis: {
      ventricularDuplets,
      supraVentricularDuplets,
      ventricularTriplets,
      supraVentricularTriplets,
      ventricularRuns,
      supraVentricularRuns,
    },
  } = data.getMyRecording;

  return (
    <TachycardiaResultsTable
      recordingStartedAt={recordingStartedAt}
      ventricularDuplets={ventricularDuplets ?? []}
      supraVentricularDuplets={supraVentricularDuplets ?? []}
      ventricularTriplets={ventricularTriplets ?? []}
      supraVentricularTriplets={supraVentricularTriplets ?? []}
      ventricularRuns={ventricularRuns ?? []}
      supraVentricularRuns={supraVentricularRuns ?? []}
      callback={handleCallback}
    />
  );
}
