import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useAuth0 } from '@auth0/auth0-react';

import { UserMapper } from 'mappers/UserMapper';
import { UpdateUserDetailsForm } from './UpdateUserDetailsForm';
import { ActivateCodeForm } from './ActivateCodeForm';
import { SharedLinks } from './SharedLinks';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export function Account() {
  const classes = useStyles();
  const { user: currentUser, getAccessTokenSilently } = useAuth0();

  const user = UserMapper.toDto(currentUser);

  const refetchUser = async () => {
    const token = await getAccessTokenSilently({ ignoreCache: true });

    return token;
  };

  return (
    <Container component="main" className={classes.root}>
      <CssBaseline />
      <UpdateUserDetailsForm user={user} refetchUser={refetchUser} />
      <div>
        <ActivateCodeForm user={user} />
        <SharedLinks user={user} />
      </div>
    </Container>
  );
}
