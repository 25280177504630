import { useLocation } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { RecordingItem } from '../RecordingItem';
import { GET_ENUMS } from '../../queries';
import { REANALYZE_RECORDING, DELETE_RECORDING } from '../../mutations';

/**
 * Styling
 */
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  row: {
    '& > th': {
      fontWeight: 'bold',
    },
  },
  container: {
    marginTop: theme.spacing(12),
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    textAlign: 'center',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export const GET_RECORDINGS_BY_ID = gql`
  query GetRecordingsById($recordingIds: [String]) {
    getRecordingsById(recordingIds: $recordingIds) {
      id
      fileName
      recordingStartedAt
      channelCount
      uploadedAt
      metadata {
        createdAt
        updatedAt
      }
      recordingIsUploaded
      downloadUrl
      comment
      analysis {
        id
        rpeaksCount
        success
        error
      }
      flags
    }
  }
`;

// eslint-disable-next-line no-undef
export function RecordingView(): JSX.Element {
  const classes = useStyles();

  const query = new URLSearchParams(useLocation().search);
  const recordingIds = query.get('id')?.split(',') ?? [];

  /**
   * Queries and mutations
   */
  const { data, loading, error } = useQuery(GET_RECORDINGS_BY_ID, {
    variables: {
      recordingIds,
    },
  });
  const { data: enumData } = useQuery(GET_ENUMS, {
    variables: { enumName: 'RecordingFlag' },
  });
  const [reanalyze] = useMutation(REANALYZE_RECORDING);
  const [deleteRecording] = useMutation(DELETE_RECORDING);

  /**
   * Throw error if recording Id was not passed as argument
   */
  if (!recordingIds.length) return <div>Recording chart loading failed. Missing recording ID</div>;

  /**
   * Load and handle recording data
   */
  if (loading || !data) return <div>Loading recording data...</div>;
  if (error) return <div>Error occured while loading recording data: {error}</div>;

  const flagOptions = enumData?.__type?.enumValues?.map(({ name }: { name: string }) => name) ?? [];

  return (
    <Container className={classes.container} maxWidth={false}>
      <TableContainer className={classes.container} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell align="right">Started</StyledTableCell>
              <StyledTableCell align="right">Updated</StyledTableCell>
              <StyledTableCell align="right">Channel</StyledTableCell>
              <StyledTableCell align="right">File Name</StyledTableCell>
              <StyledTableCell align="right">Analysis Status</StyledTableCell>
              <StyledTableCell align="right">rPeaks</StyledTableCell>
              <StyledTableCell align="right">Comments</StyledTableCell>
              <StyledTableCell align="right">Flags</StyledTableCell>
              <StyledTableCell align="right">Download Annotations</StyledTableCell>
              <StyledTableCell align="right">Download File</StyledTableCell>
              <StyledTableCell align="right">Reanalyze</StyledTableCell>
              <StyledTableCell align="right">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.getRecordingsById.map(
              ({
                fileName,
                id,
                recordingStartedAt,
                metadata,
                analysis,
                channelCount,
                comment,
                flags,
              }) => (
                <RecordingItem
                  key={id}
                  recordingId={id}
                  analysis={analysis}
                  channelCount={channelCount}
                  comment={comment}
                  fileName={fileName}
                  flagOptions={flagOptions}
                  flags={flags ?? []}
                  updatedAt={metadata?.updatedAt}
                  recordingStartedAt={recordingStartedAt}
                  onDelete={deleteRecording}
                  onReanalyze={reanalyze}
                />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
