import { useQuery, gql } from '@apollo/client';
import { Patient } from 'types/schema.type';

const GET_PATIENTS = gql`
  query GetPatientsByClinic($clinicId: String) {
    getPatientsByClinic(clinicId: $clinicId) {
      error
      patients {
        id
        clinicId
        patientRefId
        name
        surname
        email
        sex
        height
        weight
        dateOfBirth
        createdAt
        isActive
      }
      success
    }
  }
`;

export function usePatients() {
  const { data, loading, error, refetch } = useQuery<{
    getPatientsByClinic: { patients: [Patient] };
  }>(GET_PATIENTS);

  return {
    patients: data?.getPatientsByClinic?.patients,
    loading,
    error,
    refetch,
  };
}
