import { gql } from '@apollo/client';

export const DELETE_NOISE = gql`
  mutation DeleteNoiseMutation($deleteNoiseId: MongoObjectID) {
    deleteNoise(id: $deleteNoiseId) {
      success
      error
      noise {
        id
        startIndex
        endIndex
        annotationValue
      }
    }
  }
`;
