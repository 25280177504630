import { gql } from '@apollo/client';

export const GET_RECORDING_ANNOTATIONS = gql`
  query GetRecordingAnnotations($recordingId: MongoObjectID!) {
    getMyRecording(id: $recordingId) {
      analysis {
        id
        annotations {
          sampleIndex
          annotationValue
        }
      }
    }
  }
`;
