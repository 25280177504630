import { t } from 'i18next';
import { formatDateTimeRange, formatTimeDuration } from './formatTime';

export function getSequenceTitle({
  from,
  to,
  seconds,
}: {
  from: number;
  to: number;
  seconds: number;
  // this is i18n function to translate the text
  // t: (key: string, ...args: any) => string;
}) {
  const dateRange = formatDateTimeRange({
    fromMiliseconds: from,
    toMiliseconds: to,
    showSeconds: false,
    detailed: true,
  });

  // Get the human-readable duration with detailed output
  const durationHumanReadable = formatTimeDuration({
    durationInMilliseconds: seconds * 1000,
    detailed: true,
  });

  const title = t('sequenceTitle.title', { durationHumanReadable });
  const subtitle = t('sequenceTitle.subtitle', { dateRange });

  return {
    title,
    subtitle,
  };
}
