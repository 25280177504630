import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import { GenericResponse } from 'types/schema.type';
import { ExcerptMultipleChart } from '../charts/ExcerptMultipleChart';
import { AutomaticAnnotationClusterData } from '../utils/getSequenceAutomaticAnnotationsData';

export const UPDATE_CLUSTER_DATA = gql`
  mutation UpdateClusterData($clusterId: ID!, $input: UpdateClusterDataInput!) {
    updateClusterData(clusterId: $clusterId, input: $input) {
      success
      error
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cell: {
    border: 'none',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  chart: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chartContainer: {
    display: 'flex',
  },
}));

type ClusterDataWithChartsProps = {
  data: AutomaticAnnotationClusterData[];
};

export function ClusterDataWithCharts(props: ClusterDataWithChartsProps) {
  const classes = useStyles();
  const { data } = props;

  const [updateCluster] = useMutation<{
    updateClusterData: GenericResponse;
  }>(UPDATE_CLUSTER_DATA);

  const handleUpdate = async (cluster) => {
    if (!cluster) return;

    const { clusterDataId, annotationValue, clusterNumber, isValid } = cluster;

    await updateCluster({
      variables: {
        clusterId: clusterDataId,
        input: {
          currentAnnotationValue: annotationValue,
          isValid: !isValid,
          clusterNumber,
        },
      },
      update(cache) {
        const annotationValueDataId = cache.identify({
          __typename: 'ClusterData',
          id: clusterDataId,
        });

        cache.modify({
          id: annotationValueDataId,
          fields: {
            annotationValues(existingAnnotationValues = []) {
              return existingAnnotationValues.map((av) => {
                if (
                  av.__typename === 'AnnotationValueData' &&
                  av.annotationValue === annotationValue
                ) {
                  return {
                    ...av,
                    clusters: av.clusters.map((cl) => {
                      if (cl.__typename === 'Cluster' && cl.clusterNumber === clusterNumber) {
                        return {
                          ...cl,
                          isValid: !cl.isValid,
                        };
                      }

                      return cl;
                    }),
                  };
                }

                return av;
              });
            },
          },
        });
      },
    });
  };

  const renderEmpty = () => (
    <TableBody>
      <TableRow>
        <TableCell className={classes.cell}>None detected</TableCell>
      </TableRow>
    </TableBody>
  );

  const renderFull = () => (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Annotation Value</TableCell>
          <TableCell>Cluster Number</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((cluster) => (
          <React.Fragment key={cluster.clusterDataId}>
            <TableRow>
              <TableCell className={classes.cell}>{cluster.annotationValue}</TableCell>
              <TableCell className={classes.cell}>{cluster.clusterNumber + 1}</TableCell>
              <TableCell className={classes.cell}>
                <IconButton edge="end" aria-label="" onClick={() => handleUpdate(cluster)}>
                  {cluster.isValid ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </TableCell>
            </TableRow>
            {cluster.isValid && cluster.excerpts.length > 0 && (
              <TableRow>
                <TableCell className={classes.cell} colSpan={3}>
                  <div className={classes.chartContainer}>
                    {cluster.excerpts.length > 3 ? (
                      <>
                        <div className={classes.chart}>
                          <ExcerptMultipleChart
                            annotationValue={cluster.annotationValue}
                            clusterNumber={cluster.clusterNumber}
                            data={cluster.excerpts}
                          />
                        </div>
                        <div className={classes.chart}>
                          <ExcerptMultipleChart
                            annotationValue={cluster.annotationValue}
                            clusterNumber={cluster.clusterNumber}
                            data={[cluster.excerpts[0]]}
                          />
                        </div>
                        <div className={classes.chart}>
                          <ExcerptMultipleChart
                            annotationValue={cluster.annotationValue}
                            clusterNumber={cluster.clusterNumber}
                            data={[cluster.excerpts[1]]}
                          />
                        </div>
                      </>
                    ) : (
                      <div className={classes.chart}>
                        <ExcerptMultipleChart
                          annotationValue={cluster.annotationValue}
                          clusterNumber={cluster.clusterNumber}
                          data={cluster.excerpts}
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        ))}
      </TableBody>
    </>
  );

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Annotation Clusters with Charts
      </Typography>
      <Table size="small">{data?.length ? renderFull() : renderEmpty()}</Table>
    </Container>
  );
}
