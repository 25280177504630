export enum AnnotationValue {
  N = 'N',
  S = 'S',
  V = 'V',
  F = 'F',
  U = 'U',
  AFib = 'AFib',
}

export type RpeakAnnotation = {
  __typename?: string;
  sampleIndex: number;
  annotationValue: AnnotationValue;
};

export type AutomaticRpeakAnnotation = {
  sampleIndex: number;
  annotationValue: AnnotationValue;
};
