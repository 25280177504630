import { useEffect, useMemo, useRef, useState } from 'react';
import ChartsEmbedSDK, { Dashboard as EmbeddedDashboard } from '@mongodb-js/charts-embed-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { gql, useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    width: 1400,
    marginTop: theme.spacing(4),
  },
  generateButton: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const GENERATE_MONGO_CHART_JWT = gql`
  mutation GenerateMongoChartJWT {
    generateMongoChartJWT {
      success
      error
      token
    }
  }
`;

const Dashboard = () => {
  const [generateJWT] = useMutation(GENERATE_MONGO_CHART_JWT);

  const sdk = useMemo(
    () =>
      new ChartsEmbedSDK({
        baseUrl: 'https://charts.mongodb.com/charts-ecg-pgzbrxz',
        getUserToken: async () => {
          try {
            const { data } = await generateJWT();

            if (data.generateMongoChartJWT.success) {
              return data.generateMongoChartJWT.token;
            }

            console.error('Error generating JWT:', data.generateMongoChartJWT.error);

            return null;
          } catch (err) {
            console.error('Error fetching token:', err);

            return null;
          }
        },
      }),
    [generateJWT],
  );

  const dashboardDiv = useRef<HTMLDivElement | null>(null);
  const [dashboard, setDashboard] = useState<EmbeddedDashboard | null>(null);

  useEffect(() => {
    const fetchTokenAndRenderDashboard = async () => {
      try {
        const dashboardInstance = sdk.createDashboard({
          dashboardId: '66964ae8-4298-48ea-8e91-d10ea0b1e57b',
          height: '1200px',
        });

        setDashboard(dashboardInstance);
      } catch (err) {
        console.error('Error fetching token or rendering dashboard:', err);
      }
    };

    fetchTokenAndRenderDashboard();
  }, [generateJWT, sdk]);

  useEffect(() => {
    if (dashboard && dashboardDiv.current) {
      dashboard
        .render(dashboardDiv.current)
        .catch((err) => console.log('Error during Charts rendering.', err));
    }
  }, [dashboard]);

  return <div className="dashboard" ref={dashboardDiv} />;
};

export function ActivitySummary() {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth={false}>
      <Dashboard />
    </Container>
  );
}
