import { gql, useMutation } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTranslation } from 'react-i18next';

import { BradycardiaEpisode, GenericResponse } from 'types/schema.type';
import { getTime } from '../utils/getTime';
import { SequenceEpisodes } from '../utils/getSequenceEpisodes';
import { formatSeconds } from '../utils/formatSeconds';
import { calculatePercentage } from '../utils/calculatePercentage';

export const TOGGLE_BRADYCARDIA_EPISODE_VALIDATION = gql`
  mutation ToggleBradycardiaEpisodeValidation($input: ToggleBradycardiaEpisodeValidationInput!) {
    toggleBradycardiaEpisodeValidation(input: $input) {
      error
      success
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 700,
  },
  cell: {
    border: 'none',
  },
  cellSummary: {
    borderBottom: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

type BradycardiaTableProps = {
  bradycardiaEpisodes: SequenceEpisodes['bradycardiaEpisodes'];
  sequenceDurationMs: number;
  edit?: boolean;
  analysisId?: string;
};

export function BradycardiaTable(props: BradycardiaTableProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { bradycardiaEpisodes, edit, analysisId } = props;

  // use mutation
  const [toggleBradycardiaEpisodeValidation] = useMutation<{
    toggleBradycardiaEpisodeValidation: GenericResponse;
  }>(TOGGLE_BRADYCARDIA_EPISODE_VALIDATION);

  const episodesToShow = edit
    ? bradycardiaEpisodes
    : bradycardiaEpisodes?.filter((ep) => ep.isValid);

  const getTotalDuration = () => {
    const time = episodesToShow?.reduce<number>((acc, ep) => {
      const duration = ep?.duration_s ?? ((ep?.end ?? 0) - (ep?.start ?? 0)) / 200;

      return acc + (duration ?? 0);
    }, 0);
    const percentage = calculatePercentage(time, props.sequenceDurationMs / 1000);
    const formattedTime = formatSeconds(time);

    return `${formattedTime} (${percentage})`;
  };

  const handleToggleBradycardiaEpisodeValidation = async (
    startIndex?: number,
    analysisIdRef?: string,
  ) => {
    if (!analysisIdRef || !startIndex) return;

    await toggleBradycardiaEpisodeValidation({
      variables: {
        input: {
          analysisId: analysisIdRef,
          start: startIndex,
        },
      },
      update(cache) {
        cache.modify({
          id: cache.identify({ __typename: 'Analysis', id: analysisIdRef }),
          fields: {
            bradycardiaEpisodes(existingEpisodes = []) {
              const result = existingEpisodes.map((episode: BradycardiaEpisode) => {
                if (episode.start === startIndex) {
                  return {
                    ...episode,
                    isValid: !episode.isValid,
                  };
                }

                return episode;
              });

              return result;
            },
          },
        });
      },
    });
  };

  const renderEmpty = () => (
    <TableBody>
      <TableRow>
        <TableCell className={classes.cell}>{t('bradycardiaTable.noneDetected')}</TableCell>
      </TableRow>
    </TableBody>
  );

  const renderFull = () => (
    <>
      <TableHead>
        <TableRow>
          <TableCell>{t('bradycardiaTable.from')}</TableCell>
          <TableCell>{t('bradycardiaTable.to')}</TableCell>
          <TableCell>{t('bradycardiaTable.duration')}</TableCell>
          <TableCell>{t('bradycardiaTable.minBeat')}</TableCell>
          <TableCell>{t('bradycardiaTable.avgBeat')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {episodesToShow?.map<any>((ep) => (
          <TableRow key={ep.start}>
            <TableCell className={classes.cell}>
              {getTime(ep.recordingStartedAt ?? 0, ep?.start ?? 0)}
            </TableCell>
            <TableCell className={classes.cell}>
              {getTime(ep.recordingStartedAt ?? 0, ep?.end ?? 0)}
            </TableCell>
            <TableCell className={classes.cell}>
              {formatSeconds(ep.duration_s ?? ((ep?.end ?? 0) - (ep?.start ?? 0)) / 200)}
            </TableCell>
            <TableCell className={classes.cell}>{ep.minBeat}</TableCell>
            <TableCell className={classes.cell}>{ep.avgBeat}</TableCell>
            {edit && (
              <IconButton
                edge="end"
                aria-label=""
                onClick={() => handleToggleBradycardiaEpisodeValidation(ep.start, analysisId)}
              >
                {ep.isValid ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            )}
          </TableRow>
        ))}
        <TableRow>
          <TableCell className={classes.cell} colSpan={1} />
          <TableCell className={classes.cellSummary}>{t('bradycardiaTable.total')}</TableCell>
          <TableCell className={classes.cellSummary} colSpan={2}>
            {getTotalDuration()}
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        {t('bradycardiaTable.title')}
      </Typography>
      <Table className={classes.table} size="small">
        {episodesToShow?.length ? renderFull() : renderEmpty()}
      </Table>
    </Container>
  );
}
