import $ from 'jquery';
import { Interval } from 'utilities/types';

export const buildAFibInterval = ({
  fromX,
  toX,
  content,
  annotationId,
  isFromAnalysis,
  onClick,
}: Interval) => {
  const height = 130;
  const top = 0;

  const isPartialLeft = fromX < 0;
  const isPartialRight = toX > 1100;

  const left = isPartialLeft ? 0 : fromX;
  const right = isPartialRight ? 1100 : toX;

  const width = right - left;

  const container = $('<div></div>').css({
    top,
    left,
    width,
    height,
    position: 'absolute',
  });

  const deleteButton = isFromAnalysis ? '' : '<div class="delete">Delete</div>';

  const ruler = $(`
    <div class="ruler-afib" data-id=${annotationId}>
        <div class="content">
            ${content}
            ${deleteButton}
        </div>
  </div>`);

  if (isFromAnalysis) {
    ruler.addClass('ecg');
  } else {
    $(ruler).find('.content').on('click', onClick);
  }

  container.append(ruler);

  container.addClass('uplot-additional-element');

  return container;
};
