import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { ButtonMenuLayout } from './button-menu.layout';
import { ChartSettings } from './chart-settings';
import { ShortcutsText } from './shortcuts';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
}));

export function BaseUserLayout() {
  const classes = useStyles();

  return (
    <>
      <Button color="inherit" component={RouterLink} to="/recording-sequences">
        Recording Sequences
      </Button>
      <Button color="inherit" component={RouterLink} to="/recordings">
        Recordings
      </Button>
      <div className={classes.grow} />
      <ShortcutsText />
      <ChartSettings />
      <ButtonMenuLayout />
    </>
  );
}
