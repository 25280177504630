export function createBradycardiaSeries(
  sampleIndexes: number[],
  start: number,
  end: number,
  value: number,
): (null | number)[] {
  return sampleIndexes.map((sampleIndex) =>
    sampleIndex >= start && sampleIndex <= end ? value : null,
  );
}
