/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { AnnotationValue, Sequence } from 'types/schema.type';
import { getTime } from './getTime';

export type AutomaticAnnotationClusterData = {
  clusterDataId: string;
  annotationValue: AnnotationValue;
  excerpts: number[][];
  recordingStartedAt: number;
  clusterNumber: number;
  isValid: boolean;
};

export type AutomaticAnnotationTableData = {
  annotationValue: AnnotationValue;
  time: string;
};

// TODO: check if this code is used anywhere and is safe to remove
// export function getSequenceAutomaticAnnotationsData(recordingsSequence: RecordingsSequence): {
//   clusterData: AutomaticAnnotationClusterData[];
//   tableData: AutomaticAnnotationTableData[];
// } {
//   if (!recordingsSequence?.recordings?.length) {
//     return { clusterData: [], tableData: [] };
//   }

//   const clusterData: AutomaticAnnotationClusterData[] = [];
//   const tableData: AutomaticAnnotationTableData[] = [];

//   for (const recording of recordingsSequence.recordings) {
//     if (!recording.analysis) {
//       continue;
//     }

//     const recordingStartedAt = recording.recordingStartedAt ?? 0;

//     if (recording.analysis.clusterData) {
//       for (const annotationValueObj of recording.analysis.clusterData.annotationValues) {
//         for (const cluster of annotationValueObj.clusters) {
//           clusterData.push({
//             annotationValue: annotationValueObj.annotationValue as AnnotationValue,
//             clusterNumber: cluster.clusterNumber,
//             excerpts: cluster.excerpts,
//             recordingStartedAt,
//           });
//         }
//       }
//     }

//     const automaticAnnotations = recording.analysis.automaticRpeakAnnotations || [];
    
//     for (const annotation of automaticAnnotations) {
//       if ([AnnotationValue.S, AnnotationValue.V].includes(annotation.annotationValue)) {
//         tableData.push({
//           annotationValue: annotation.annotationValue,
//           time: getTime(recordingStartedAt, annotation.sampleIndex),
//         });
//       }
//     }
//   }

//   return { clusterData, tableData };
// }

export function getSequenceAnalysisAutomaticAnnotationsData(sequence: Sequence | undefined): {
  clusterData: AutomaticAnnotationClusterData[];
  tableData: AutomaticAnnotationTableData[];
} {
  const clusterData: AutomaticAnnotationClusterData[] = [];
  const tableData: AutomaticAnnotationTableData[] = [];

  if (!sequence?.analysis) {
    return { clusterData, tableData };
  }

  if (sequence.analysis.clusterData) {
    for (const annotationValueObj of sequence.analysis.clusterData.annotationValues) {
      for (const cluster of annotationValueObj.clusters) {
        clusterData.push({
          clusterDataId: sequence.analysis.clusterData.id,
          annotationValue: annotationValueObj.annotationValue as AnnotationValue,
          clusterNumber: cluster.clusterNumber,
          excerpts: cluster.excerpts,
          recordingStartedAt: sequence.startedAt ?? 0,
          isValid: cluster.isValid,
        });
      }
    }
  }

  const automaticAnnotations = sequence.analysis.automaticRpeakAnnotations || [];

  for (const annotation of automaticAnnotations) {
    if ([AnnotationValue.S, AnnotationValue.V].includes(annotation.annotationValue)) {
      tableData.push({
        annotationValue: annotation.annotationValue,
        time: getTime(sequence.startedAt, annotation.sampleIndex),
      });
    }
  }

  return { clusterData, tableData };
}
