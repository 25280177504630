import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerSize: {
    width: 350,
    fontWeight: 'bold',
  },
  headerFont: {
    fontWeight: 'bold',
  },
});

const keys = [
  {
    name: 'N, S, V, F',
    description: 'Set value of highlighted annotation',
  },
  {
    name: 'Shift + N, S, V, U',
    description: 'Jump to the next annotation in the chart',
  },
  {
    name: 'Shift + Alt + N, S, V, U',
    description: 'Jump to the previous annotation in the chart',
  },
  {
    name: 'A',
    description: 'Add new annotation at green bar (click again to confirm)',
  },
  {
    name: 'D',
    description: 'Delete the currently highlighted annotation',
  },
  {
    name: 'Arrow Up, Arrow Down',
    description: 'Scroll left and right in the chart manually',
  },
  {
    name: 'Shift + Arrow Left, Shift + Arrow Right',
    description: 'Scroll left and right in the chart one sample at a time',
  },
  {
    name: 'Spacebar',
    description: 'Enable and disable autoscroll of the chart',
  },
  {
    name: 'Shift + select interval with mouse',
    description: 'Create noise interval annotation',
  },
  {
    name: 'Mouse click on noise interval',
    description: 'Delete noise interval annotation',
  },
  {
    name: 'Hold ALT + Mouse click on annotations',
    description: 'Shows interval in seconds between selected annotations',
  },
];

export function ShortcutsTextContent() {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="dense table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerSize}>Key</TableCell>
              <TableCell className={classes.headerFont}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
