import $ from 'jquery';

export const buildIndicator = ({
  relativeOffsetX = 100,
  relativeOffsetY = 100,
  content = 'ERROR',
  placement = 'SW',
  background = 'lightgray',
  onClick = () => {},
}) => {
  let wrapperRotation;
  let indicatorRotation;

  switch (placement) {
    case 'NW':
      wrapperRotation = 45;
      indicatorRotation = -45;
      break;
    case 'SW':
      wrapperRotation = -45;
      indicatorRotation = 45;
      break;
    case 'NE':
      wrapperRotation = 135;
      indicatorRotation = -135;
      break;
    case 'SE':
      wrapperRotation = -135;
      indicatorRotation = 135;
      break;
    case 'N':
      wrapperRotation = 90;
      indicatorRotation = -90;
      break;
    default:
      break;
  }

  const wrapper = $(`<div></div>`).css({
    position: 'absolute',
    left: relativeOffsetX,
    top: relativeOffsetY,
    display: 'flex',
    alignItems: 'center',
    // outline: '1px dashed green',
    transform: `translate(-100%, -50%) rotate(${wrapperRotation}deg)`,
    transformOrigin: 'right',
  });

  const indicator = $(`<div>
    <div>${content}</div>
  </div>`)
    .css({
      transform: `rotate(${indicatorRotation}deg)`,
      background,
      border: '1px solid purple',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      display: 'flex',
      alignContent: 'space-around',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    })
    .on('click', onClick);

  const handle = $(`<div></div>`).css({
    width: 25,
    height: 2,
    background: 'purple',
  });

  wrapper.append(indicator);
  wrapper.append(handle);

  wrapper.addClass('uplot-additional-element');

  return wrapper;
};
