/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useMutation, useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import List from '@material-ui/core/List';

import { CREATE_RECORDING, DELETE_RECORDING,  } from 'mutations';
import { MY_RECORDINGS } from '../../queries';
import { useUploadContext } from '../../context/UploadContext';
import { RecordingUpload } from './RecordingUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    marginLeft: theme.spacing(2),
  },
  stats: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

export function FileUploader() {
  const classes = useStyles();

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [uploadQueue, setUploadQueue] = useState<any[]>([]);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const [failedFilesCount, setFailedFilesCount] = useState(0);
  const [accessToken, setAccessToken] = useState('');

  const [createRecording] = useMutation(CREATE_RECORDING);
  const [deleteRecording] = useMutation(DELETE_RECORDING);

  const { getAccessTokenSilently } = useAuth0();

  const { uploadInProgress, setUploadInProgress } = useUploadContext();

  const client = useApolloClient();

  useEffect(() => {
    const allUploadsCompleted = uploadQueue.length === uploadedFilesCount + failedFilesCount;

    if (allUploadsCompleted && uploadInProgress) {
      setUploadInProgress(false);
      client.query({
        query: MY_RECORDINGS,
        variables: {
          offset: 0,
          limit: 10,
        },
        fetchPolicy: 'network-only', // Forces a network request
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFilesCount, failedFilesCount, uploadQueue.length, uploadInProgress]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    setSelectedFiles(files);
    setUploadQueue(files.map((file) => ({ file, status: 'Pending', error: '' })));
  };

  const triggerUpload = async () => {
    const token = await getAccessTokenSilently();

    setAccessToken(token);
    setUploadInProgress(true);
    // Reset the counters
    setUploadedFilesCount(0);
    setFailedFilesCount(0);
  };

  const handleUploadStatus = (file, status, error = '') => {
    setUploadQueue((currentQueue) =>
      currentQueue.map((item) =>
        item.file.name === file.name ? { ...item, status, error } : item,
      ),
    );

    // Update the count for uploaded and failed files based on status
    if (status === 'Uploaded') {
      setUploadedFilesCount((prevCount) => prevCount + 1);
    } else if (status === 'Failed') {
      setFailedFilesCount((prevCount) => prevCount + 1);
    }
  };

  const resetState = () => {
    setSelectedFiles([]);
    setUploadQueue([]);
    setUploadedFilesCount(0);
    setFailedFilesCount(0);
    setUploadInProgress(false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Upload recordings:
      </Typography>
      <div>
        <input
          className={classes.input}
          id="contained-button-file"
          type="file"
          disabled={uploadInProgress}
          multiple
          onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span" disabled={uploadInProgress}>
            Choose Files
          </Button>
        </label>

        <Button
          className={classes.uploadButton}
          variant="contained"
          disabled={uploadInProgress || !selectedFiles.length}
          onClick={triggerUpload}
        >
          Start Upload
        </Button>
        
        <Button
          className={classes.uploadButton}
          color="secondary"
          variant="contained"
          disabled={uploadInProgress}
          onClick={resetState}
        >
          Reset
        </Button>
      </div>

      <Typography className={classes.stats} variant="h6">
        Total Files Selected: {selectedFiles.length}  |  Upload Progress: {uploadedFilesCount} / {selectedFiles.length} files  |  Failed uploads: {failedFilesCount}
      </Typography>

      <List component="ol">
        {uploadQueue.map((item, index) => (
          <RecordingUpload
            key={item.file.name}
            file={item.file}
            accessToken={accessToken}
            uploadInProgress={uploadInProgress}
            lineNumber={index + 1}
            status={item.status}
            error={item.error}
            onCreateRecording={createRecording}
            onDeleteRecording={deleteRecording}
            onUploadStatusChange={handleUploadStatus}
          />
        ))}
      </List>
    </div>
  );
}
