import { useQuery, gql } from '@apollo/client';
import React from 'react';

const CHECK_SUBSCRIPTION = gql`
  query CheckUserSubscription {
    checkUserSubscription {
      success
      error
      isSubscribed
      activationCode {
        id
        code
        isActive
        userId
        createdAt
        activatedAt
      }
    }
  }
`;

interface ActivationCode {
  id: string;
  code: string;
  isActive: boolean;
  userId?: string;
  createdAt: Date;
  activatedAt?: Date;
}

interface CheckUserSubscriptionResponse {
  success: boolean;
  error?: string;
  isSubscribed: boolean;
  activationCode?: ActivationCode;
}

interface UseCheckSubscriptionResponse {
  loading?: boolean;
  error?: Error;
  data?: CheckUserSubscriptionResponse;
}

export function useCheckSubscription(): UseCheckSubscriptionResponse {
  const { loading, error, data } =
    useQuery<{ checkUserSubscription: CheckUserSubscriptionResponse }>(CHECK_SUBSCRIPTION);

  const response = React.useMemo(() => {
    if (loading) return { loading };
    if (error) return { error };
    if (data) return { data: data.checkUserSubscription };
    return {};
  }, [loading, error, data]);

  return response;
}
