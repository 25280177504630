import { gql } from '@apollo/client';

export const GET_RECORDINGS_DOWNLOAD_DATA = gql`
  query GetRecordingDownloadData($recordingIds: [String]) {
    getRecordingsById(recordingIds: $recordingIds) {
      downloadUrl
      comment
      flags
      fileName
      user {
        id
        email
      }
      analysis {
        id
        annotations {
          sampleIndex
          annotationValue
        }
        noises {
          id
          startIndex
          endIndex
          annotationValue
        }
        afibs {
          id
          startIndex
          endIndex
          annotationValue
        }
      }
    }
  }
`;
