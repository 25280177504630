/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { useState, useMemo, useEffect } from 'react';
import UPlotReact from 'uplot-react';
import uPlot from 'uplot';

import { chunkArray } from '../utilities';

function getUnixTime(timeStamp, index) {
  // 200 samples per 1000 ms = 1 sample index per 5 ms
  const time = (timeStamp + index * 5) / 1000;

  return time;
}

export function RateChart({ rate, rpeaks, recordingStartedAt, xAxisTime, sync }) {
  const isTimeSeries = xAxisTime && Boolean(recordingStartedAt);

  const [sampleSize, setSampleSize] = useState(200);
  const [options, setOptions] = useState(
    useMemo(
      () => ({
          title: 'HR Line Chart',
          width: 1200,
          height: 200,
          cursor: {
            sync: {
              key: sync.key,
            },
          },
          axes: [
            {
              grid: {
                width: 1 / devicePixelRatio,
                stroke: '#e3e3e3',
              },
              ticks: {
                width: 1 / devicePixelRatio,
                stroke: '#e3e3e3',
              },
            },
            {
              label: 'Rate (bpm)',
            },
          ],
          series: [
            {
              label: 'Index',
            },
            {
              label: 'Rate',
              width: 1 / devicePixelRatio,
              drawStyle: 0,
              lineInterpolation: null,
              stroke: '#E24D42',
            },
            {
              label: 'Average',
              stroke: 'blue',
              spanGaps: true,
            },
          ],
          plugins: [],
          scales: { x: { time: isTimeSeries } },
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  );

  const initialState = useMemo(
    () => {
      const data = isTimeSeries
        ? rpeaks.map((rpeak) => getUnixTime(recordingStartedAt, rpeak.sampleIndex))
        : rpeaks.map((rpeak) => rpeak.sampleIndex);

      return [data, rate, chunkArray(rate, sampleSize)];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  ) as uPlot.AlignedData;

  const [data, setData] = useState<uPlot.AlignedData>(initialState);

  useEffect(() => {
    const newRateAverage = chunkArray(rate, sampleSize);

    const newValues = isTimeSeries
      ? rpeaks.map((rpeak) => getUnixTime(recordingStartedAt, rpeak.sampleIndex))
      : rpeaks.map((rpeak) => rpeak.sampleIndex);

    const newData = [newValues, rate, newRateAverage] as unknown as uPlot.AlignedData;

    setOptions({ ...options, scales: { x: { time: isTimeSeries } } });
    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleSize, xAxisTime]);

  return (
    <>
      <label htmlFor="samples">Average Heart Rate (blue line) bucket size:</label>
      <input
        name="samples"
        type="range"
        id="samples"
        min="10"
        max="500"
        step="10"
        value={sampleSize}
        onChange={(e) => setSampleSize(Number(e.target.value))}
      />{' '}
      {sampleSize}
      <UPlotReact
        key={sync}
        options={options}
        data={data}
        onDelete={() => {}}
        onCreate={() => {}}
      />
    </>
  );
}
