import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

import { useChartSettings } from 'hooks/use-chart-settings';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  form: {
    margin: theme.spacing(1),
  },
  slider: {
    paddingTop: theme.spacing(4),
    width: 250,
  },
  pointComment: {
    paddingTop: theme.spacing(2),
  },
}));

export function ChartSettingsContent() {
  const classes = useStyles();

  const {
    xAxisTime,
    scrollSpeed,
    allowCreatePointComment,
    jumpToML,
    showCharts,
    visiblePeaks,
    visibleAnnotations,
    visibleIntervals,
    setXAxisTime,
    setScrollSpeed,
    setAllowCreatePointComment,
    setJumpToML,
    setShowCharts,
    setVisiblePeaks,
    setVisibleAnnotations,
    setVisibleIntervals,
  } = useChartSettings();

  const handleVisiblePeaks = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setVisiblePeaks((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleVisibleAnnotations = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setVisibleAnnotations((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleVisibleIntervals = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setVisibleIntervals((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleShowCharts = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setShowCharts((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleSliderChange = (event: any, value: number | number[]) => {
    setScrollSpeed(value as number);
  };

  return (
    <div className={classes.root}>
      <Grid justifyContent="center" spacing={3} container>
        <Grid xs={3} item>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={xAxisTime} color="primary" onChange={() => setXAxisTime(true)} />
                }
                label="Show Time (seconds)"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={!xAxisTime}
                    color="primary"
                    onChange={() => setXAxisTime(false)}
                  />
                }
                label="Show Sample ID"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={allowCreatePointComment}
                    color="primary"
                    onChange={(e) => setAllowCreatePointComment(e.target.checked)}
                  />
                }
                label="Create Point Comment"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={jumpToML}
                    color="primary"
                    onChange={(e) => setJumpToML(e.target.checked)}
                  />
                }
                label="Jump To ML"
              />
            </FormGroup>
          </FormControl>

          <div className={classes.slider}>
            <Typography gutterBottom>Autoscroll Speed</Typography>
            <Typography variant="body2" gutterBottom>
              (1 = 200 samples scrolled per second)
            </Typography>
            <Slider
              value={scrollSpeed}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              min={1}
              max={10}
              marks
              onChange={handleSliderChange}
            />
          </div>
        </Grid>
        <Grid xs={2} item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Show peaks</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={visiblePeaks.P}
                    name="P"
                    color="primary"
                    onChange={handleVisiblePeaks}
                  />
                }
                label="P"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visiblePeaks.Q}
                    name="Q"
                    color="primary"
                    onChange={handleVisiblePeaks}
                  />
                }
                label="Q"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visiblePeaks.R}
                    name="R"
                    color="primary"
                    onChange={handleVisiblePeaks}
                  />
                }
                label="R"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visiblePeaks.S}
                    name="S"
                    color="primary"
                    onChange={handleVisiblePeaks}
                  />
                }
                label="S"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visiblePeaks.T}
                    name="T"
                    color="primary"
                    onChange={handleVisiblePeaks}
                  />
                }
                label="T"
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid xs={2} item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Show annotations</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={visibleAnnotations.ML}
                    name="ML"
                    color="primary"
                    onChange={handleVisibleAnnotations}
                  />
                }
                label="ML"
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid xs={2} item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Show intervals</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={visibleIntervals.PQ}
                    name="PQ"
                    color="primary"
                    onChange={handleVisibleIntervals}
                  />
                }
                label="PQ"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visibleIntervals.QRS}
                    name="QRS"
                    color="primary"
                    onChange={handleVisibleIntervals}
                  />
                }
                label="QRS"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visibleIntervals.QT}
                    name="QT"
                    color="primary"
                    onChange={handleVisibleIntervals}
                  />
                }
                label="QT"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visibleIntervals.RR}
                    name="RR"
                    color="primary"
                    onChange={handleVisibleIntervals}
                  />
                }
                label="RR"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visibleIntervals.U}
                    name="U"
                    color="primary"
                    onChange={handleVisibleIntervals}
                  />
                }
                label="Noise"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visibleIntervals.AFib}
                    name="AFib"
                    color="primary"
                    onChange={handleVisibleIntervals}
                  />
                }
                label="AFib - Atrial Fibrillation"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={visibleIntervals.PE}
                    name="PE"
                    color="primary"
                    onChange={handleVisibleIntervals}
                  />
                }
                label="Pause Episodes"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid xs={2} item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Show charts</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={showCharts.rate}
                    name="rate"
                    color="primary"
                    onChange={handleShowCharts}
                  />
                }
                label="HR Line"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showCharts.histogram}
                    name="histogram"
                    color="primary"
                    onChange={handleShowCharts}
                  />
                }
                label="HR Histogram"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showCharts.interval}
                    name="interval"
                    color="primary"
                    onChange={handleShowCharts}
                  />
                }
                label="Interval and Segment"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showCharts.annotation}
                    name="annotation"
                    color="primary"
                    onChange={handleShowCharts}
                  />
                }
                label="Annotation summary"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showCharts.tachycardia}
                    name="tachycardia"
                    color="primary"
                    onChange={handleShowCharts}
                  />
                }
                label="Tachycardia results"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showCharts.healthIndication}
                    name="healthIndication"
                    color="primary"
                    onChange={handleShowCharts}
                  />
                }
                label="Health Indication"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
