import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { AutomaticRpeakAnnotation, RpeakAnnotation } from 'types';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    height: 300,
    overflow: 'scroll',
  },
  table: {
    width: 400,
    height: 'max-content',
    margin: 'auto',
    marginBottom: theme.spacing(2),
    borderStyle: 'solid',
    borderColor: 'grey',
    borderWidth: 1,
  },
  row: {
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
  },
}));

type AnnotationData = {
  sampleIndex: number;
  humanAnnotationValue: string;
  machineAnnotationValue: string;
};

function createData(
  sampleIndex: number,
  humanAnnotationValue: string,
  machineAnnotationValue: string,
): AnnotationData {
  return { sampleIndex, humanAnnotationValue, machineAnnotationValue };
}

function makeRows(
  humanAnnotationValues: RpeakAnnotation[],
  machineAnnotationValues: AutomaticRpeakAnnotation[],
) {
  const indexes = new Set<number>();

  const humanMap = humanAnnotationValues.reduce((acc, val) => {
    acc.set(val.sampleIndex, val.annotationValue);
    indexes.add(val.sampleIndex);

    return acc;
  }, new Map<number, string>());

  const machineMap = machineAnnotationValues.reduce((acc, val) => {
    acc.set(val.sampleIndex, val.annotationValue);
    indexes.add(val.sampleIndex);

    return acc;
  }, new Map<number, string>());

  const result: AnnotationData[] = [];

  indexes.forEach((index) => {
    const data = createData(index, humanMap.get(index) ?? '', machineMap.get(index) ?? '');

    result.push(data);
  });

  return result;
}

type AnnotationSummaryProps = {
  recordingId: string;
  scale: [number, number];
  setScale: (scale: [number, number]) => void;
};

const GET_RECORDING_ANNOTATIONS = gql`
  query GetRecordingAnnotations($getMyRecordingId: MongoObjectID!) {
    getMyRecording(id: $getMyRecordingId) {
      analysis {
        id
        humanAnnotations {
          sampleIndex
          annotationValue
        }
        automaticRpeakAnnotations {
          sampleIndex
          annotationValue
        }
      }
    }
  }
`;

export function AnnotationSummary(props: AnnotationSummaryProps) {
  const classes = useStyles();

  const { recordingId, scale, setScale } = props;

  const { loading, data } = useQuery(GET_RECORDING_ANNOTATIONS, {
    variables: {
      getMyRecordingId: recordingId,
    },
  });

  if (loading) {
    return <div>Loading annotations...</div>;
  }

  const { humanAnnotations = [], automaticRpeakAnnotations = [] } = data.getMyRecording?.analysis;

  const rows = makeRows(humanAnnotations, automaticRpeakAnnotations);

  const handleRowClick = (sampleIndex: number) => {
    const halfScale = Math.round((scale[1] - scale[0]) / 2);

    const start = sampleIndex - halfScale;
    const end = sampleIndex + halfScale;

    setScale([start, end]);
  };

  return (
    <>
      <Typography variant="h6" className={classes.title} gutterBottom>
        Annotation differences
      </Typography>
      <TableContainer className={classes.conteiner}>
        <Table className={classes.table} size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Sample Index</TableCell>
              <TableCell align="right">Human opinion</TableCell>
              <TableCell align="right">ML opinion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.sampleIndex}
                className={classes.row}
                onClick={() => handleRowClick(row.sampleIndex)}
              >
                <TableCell component="th" scope="row">
                  {row.sampleIndex}
                </TableCell>
                <TableCell align="right">{row.humanAnnotationValue}</TableCell>
                <TableCell align="right">{row.machineAnnotationValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
