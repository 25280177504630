export function getUnixTime(timestamp: number | undefined, sampleIndex: number | undefined) {
  const secondInMs = 1000;
  const samplesPerSecond = 200;
  const msPerSample = secondInMs / samplesPerSecond;


  const time = Math.abs(timestamp ?? 0);
  const index =  Math.abs(sampleIndex ?? 0);
  
  const result = (time + (index * msPerSample)) / secondInMs;

  return result;
}
