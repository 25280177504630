import { gql } from '@apollo/client';

export const GET_ALL_RECORDINGS = gql`
  query GetAllRecordings {
    getRecordingsById {
      id
      channelCount
      comment
      fileName
      flags
      recordingStartedAt
      uploadedAt
      rpeaksCount
    }
  }
`;
