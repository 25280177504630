/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import UPlotReact from 'uplot-react';
import uPlot from 'uplot';
import { useTranslation } from 'react-i18next';
import styles from './HistogramChart.module.css';

function groupByRange(data) {
  return data.reduce((acc, val) => {
    if (acc[val]) {
      acc[val].push(val);
    } else {
      acc[val] = [val];
    }

    return acc;
  }, {});
}

function prepData(data, grouped) {
  const total = data.length;

  return Object.entries(grouped).reduce(
    (acc, [key, val]) => {
      // prepare x axis (rate range: [60, 70....])
      acc[0].push(Number(key));
      // prepare y axix (% of bpm)
      acc[1].push(((val.length / total) * 100).toFixed(3));

      return acc;
    },
    [[], []],
  );
}

const { linear, stepped, bars, spline } = uPlot.paths;
const lineInterpolations = {
  linear: 0,
  stepAfter: 1,
  stepBefore: 2,
  spline: 3,
};

const drawStyles = {
  line: 0,
  bars: 1,
  points: 2,
  barsLeft: 3,
  barsRight: 4,
};

// generate bar builder with 60% bar (40% gap) & 100px max bar width
const _bars60_100 = bars({ size: [0.9, 100] });
const _bars100Left = bars({ size: [1], align: 1 });
const _bars100Right = bars({ size: [1], align: -1 });
const _stepBefore = stepped({ align: -1 });
const _stepAfter = stepped({ align: 1 });
const _linear = linear();
const _spline = spline();

function paths(u, seriesIdx, idx0, idx1, extendGap, buildClip) {
  const s = u.series[seriesIdx];
  const style = s.drawStyle;
  const interp = s.lineInterpolation;

  const renderer =
    style === drawStyles.line
      ? interp === lineInterpolations.linear
        ? _linear
        : interp === lineInterpolations.stepAfter
        ? _stepAfter
        : interp === lineInterpolations.stepBefore
        ? _stepBefore
        : interp === lineInterpolations.spline
        ? _spline
        : //	interp == lineInterpolations.spline2    ? _spline2 :
          null
      : style === drawStyles.bars
      ? _bars60_100
      : style === drawStyles.barsLeft
      ? _bars100Left
      : style === drawStyles.barsRight
      ? _bars100Right
      : style === drawStyles.points
      ? () => null
      : () => null;

  return renderer(u, seriesIdx, idx0, idx1, extendGap, buildClip);
}

const formatValueRange = (_, rawValue) => rawValue;

// eslint-disable-next-line react/prop-types
export function HistogramChart({ rate }) {
  const { t } = useTranslation();

  const options = useMemo(
    () => ({
      title: t('histogramChart.title'), // Dynamic translation for title
      width: 400,
      height: 300,
      axes: [
        {
          label: t('histogramChart.axisRateLabel'),
          grid: {
            width: 1 / devicePixelRatio,
            stroke: '#e3e3e3',
          },
          ticks: {
            width: 1 / devicePixelRatio,
            stroke: '#e3e3e3',
          },
        },
        {
          label: t('histogramChart.axisBeatsPercentageLabel'),
        },
      ],
      series: [
        {
          label: t('histogramChart.seriesRateRangeLabel'),
          value: formatValueRange,
        },
        {
          label: t('histogramChart.seriesBeatsPercentageLabel'),
          width: 1 / devicePixelRatio,
          drawStyle: 1,
          lineInterpolation: null,
          stroke: '#E24D42',
          fill: '#E24D421A',
          paths,
        },
      ],
      plugins: [],
      scales: { x: { time: false } },
    }),
    [t],
  );

  const data = useMemo(() => {
    const grouped = groupByRange(rate);
    const prepped = prepData(rate, grouped);

    return prepped;
  }, [rate]);

  return (
    <div className={styles.root}>
      <UPlotReact key="histogram" options={options} data={data} />
    </div>
  );
}
