/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import uPlot from 'uplot';
import $ from 'jquery';
import { format } from 'date-fns';

import { AutomaticRpeakAnnotation } from 'types';
import {
  buildIndicator,
  buildIntervalIndicator,
  getSamplePosition,
  computeInvervalPositions,
  buildNoiseInterval,
  buildIntervalRuler,
  buildPointCommentIndicator,
  buildPauseEpisodeInterval,
  buildAFibInterval,
} from '../utilities';
import { buildHealthIndicationChartAnnotation } from './features/healthIndication/utils/buildHealthIndicationAnnotation';
import { buildHealthIndicationInterval } from './features/healthIndication/utils/buildHealthIndicationInterval';

export function Chart({
  rpeaks,
  updateAnnotation,
  recordingStartedAt,
  xAxisTime,
  sync,
  plotScale,
  onPlotScaleChange,
  data,
  highlightIndex,
  highlightMiddleSample,
  qpeaks,
  speaks,
  tpeaks,
  automaticRpeakAnnotations,
  ppeaks,
  noises,
  afibs,
  pauseEpisodes,
  pointComments,
  healthIndications,
  allowCreatePointComment,
  setAllowCreatePointComment,
  showPeaks,
  showAnnotations,
  showIntervals,
  showCharts,
  onCreateNoise,
  onDeleteNoise,
  onCreateAFib,
  onDeleteAFib,
  onUpdatePointComment,
  onDeletePointComment,
  onCreatePointComment,
  onEditAnnotationInterval,
}) {
  const plotRef = useRef<HTMLDivElement | null>(null);

  const automaticRpeaks: AutomaticRpeakAnnotation[] = automaticRpeakAnnotations;

  // WARNING: i0 and i1 are integers, while plotScale is an array of floats!
  // Previously used i0 and i1!
  const visibleRpeaks = rpeaks.filter(
    ({ sampleIndex }) => sampleIndex > plotScale[0] && sampleIndex < plotScale[1],
  );
  const visiblePpeaks = ppeaks.filter(
    (sampleIndex) => sampleIndex > plotScale[0] && sampleIndex < plotScale[1],
  );
  const visibleQpeaks = qpeaks.filter(
    (sampleIndex) => sampleIndex > plotScale[0] && sampleIndex < plotScale[1],
  );
  const visibleSpeaks = speaks.filter(
    (sampleIndex) => sampleIndex > plotScale[0] && sampleIndex < plotScale[1],
  );
  const visibleTpeaks = tpeaks.filter(
    (sampleIndex) => sampleIndex > plotScale[0] && sampleIndex < plotScale[1],
  );
  const visibleAutomaticRpeaks = automaticRpeaks.filter(
    (item) => item.sampleIndex > plotScale[0] && item.sampleIndex < plotScale[1],
  );
  const visibleNoises = noises.filter((noise) => {
    const { startIndex, endIndex } = noise;
    const [x, y] = plotScale;

    const isStartIndexInRange = startIndex >= x && startIndex <= y;
    const isEndIndexInRange = endIndex >= x && endIndex <= y;
    const isCrossing = startIndex <= x && endIndex >= y;

    return isStartIndexInRange || isEndIndexInRange || isCrossing;
  });
  const visibleAfibs = afibs.filter((afib) => {
    const { startIndex, endIndex } = afib;
    const [x, y] = plotScale;

    return startIndex >= x && endIndex <= y;
  });

  const visiblePauseEpisodes = pauseEpisodes.filter((pauseEpisode) => {
    const { startIndex, endIndex } = pauseEpisode;
    const [x, y] = plotScale;

    const isStartIndexInRange = startIndex >= x && startIndex <= y;
    const isEndIndexInRange = endIndex >= x && endIndex <= y;
    const isCrossing = startIndex <= x && endIndex >= y;

    return isStartIndexInRange || isEndIndexInRange || isCrossing;
  });

  const visiblePointComments = pointComments.filter(
    ({ sampleIndex }) => sampleIndex > plotScale[0] && sampleIndex < plotScale[1],
  );

  /**
   * Ruler measures interval distance between peaks
   * Hold ALT + Click (OPTION + Click) on peak annotations to show the distance between them
   */
  const ruler = { active: false, x: [], y: [], index: [] };

  const annotation: {
    on: boolean;
    edit: boolean;
    start: null | number;
    end: null | number;
    cursor: null | number;
    id: null | string;
  } = {
    on: false,
    edit: false,
    start: null,
    end: null,
    cursor: null,
    id: null,
  };

  const uplotOptions: any = {
    width: 1200,
    height: 200,
    series: [
      {
        label: xAxisTime ? 'Time' : 'Sample id',
        value: (_, rawValue) =>
          xAxisTime
            ? format(recordingStartedAt + (rawValue / 200) * 1000, 'yyy-MM-dd H:mm:ss.SSS')
            : rawValue,
      },
      {
        label: 'Voltage, mV',
        stroke: 'red',
      },
    ],
    axes: [
      {
        values: (_, splits) =>
          xAxisTime
            ? splits.map((sampleIndex) =>
                format(recordingStartedAt + (sampleIndex / 200) * 1000, 'H:mm:ss'),
              )
            : splits,
      },
      {
        label: 'Voltage, mV',
      },
    ],
    scales: {
      x: {
        time: false,
      },
    },
    cursor: {
      focus: {
        prox: 16,
      },
      sync: {
        key: sync.key,
        setSeries: true,
      },
      bind: {
        mousedown: (u, targ, handler) => (e) => {
          if (e.button === 0) {
            handler(e);

            const isEdit = e.target.className.includes('bar');

            if (isEdit) {
              const noise = visibleNoises.find(
                ({ id }) => id === e.target?.parentElement?.dataset?.id,
              );

              if (!noise) return;

              annotation.on = true;
              annotation.edit = true;
              annotation.id = noise.id;

              // check if left or right bar selected
              if (e.target.className.includes('right')) {
                annotation.start = noise.startIndex;
              } else {
                annotation.end = noise.endIndex;
              }

              u.root.querySelector('.u-select').classList.add('u-annotate');
            }

            if (e.shiftKey) {
              annotation.on = true;
              annotation.start = u.cursor.idx;

              // switch select region to annotation color
              u.root.querySelector('.u-select').classList.add('u-annotate');
            }

            if (e.altKey) {
              const sampleIndex = u.cursor.idx;
              const [samplePosX, samplePosY] = getSamplePosition({
                u,
                sampleIndex,
                data,
              });

              buildIntervalRuler(ruler, samplePosX, samplePosY, sampleIndex, u);
            }

            if (allowCreatePointComment) {
              const newPointComment = window.prompt('Enter new point comment');

              if (newPointComment) {
                onCreatePointComment({
                  sampleIndex: u.cursor.idx,
                  comment: newPointComment,
                });
                setAllowCreatePointComment(false);
              }
            }
          }
        },
        mouseup: (self, targ, handler) => (e) => {
          if (e.button === 0) {
            if (annotation.on) {
              // cursor.idx can be null if selection not is out of chart zone
              if (annotation.start) {
                annotation.end = self.cursor.idx ?? annotation.cursor;
              } else {
                annotation.start = self.cursor.idx ?? annotation.cursor;
              }

              // tmp disable drag.setScale
              const _setScale = self.cursor.drag.setScale;

              self.cursor.drag.setScale = false;
              // fire original handler
              handler(e);
              // revert drag.setScale
              self.cursor.drag.setScale = _setScale;
            } else {
              handler(e);
            }
          }
        },
        mousemove: (u, targ, handler) => (e) => {
          if (annotation.on) {
            annotation.cursor = u.cursor.idx;
          }

          handler(e);
        },
      },
    },
    hooks: {
      setSelect: [
        (u) => {
          const { min, max } = u.scales.x;

          if (annotation.on) {
            const { start, end } = annotation;

            if (start === null || end === null) {
              return console.error('Noise interval is not a number');
            }

            const [from, to] = [start, end].sort((a, b) => a - b);

            if (annotation.edit && annotation.id) {
              u.root.querySelector('.u-select').classList.remove('u-annotate');

              onEditAnnotationInterval({
                id: annotation.id,
                startIndex: from,
                endIndex: to,
              });
              annotation.on = false;
              annotation.edit = false;
              annotation.start = null;
              annotation.end = null;
              annotation.cursor = null;
              annotation.id = null;

              return null;
            }

            const intervalValue = prompt(
              'Enter:\n  "Noise" for Noise Interval\n  "AFib" for Atrial Fibrillation',
            );

            if (!['AFib', 'Noise'].includes(intervalValue ?? ''))
              alert(`You entered "${intervalValue}", which is not "Noise" or "AFib"`);

            if (intervalValue === 'Noise')
              onCreateNoise({
                startIndex: from,
                endIndex: to,
              });

            if (intervalValue === 'AFib')
              onCreateAFib({
                startIndex: from,
                endIndex: to,
              });

            // revert select region to default color
            u.root.querySelector('.u-select').classList.remove('u-annotate');

            annotation.on = false;
            annotation.edit = false;
            annotation.start = null;
            annotation.end = null;
            annotation.cursor = null;
            annotation.id = null;

            return null;
          }

          return onPlotScaleChange([min, max]);
        },
      ],
      drawAxes: [
        (u) => {
          const { ctx } = u;
          const { top, height } = u.bbox;

          const interpolatedColorWithAlpha = '#fcb0f17a';

          ctx.save();

          ctx.strokeStyle = interpolatedColorWithAlpha;
          ctx.beginPath();

          const [i0, i1] = u.series[0].idxs;

          $(u.root.querySelector('.u-over')).find('.uplot-additional-element').remove();

          if (showIntervals.PQ) {
            const pqIntervals = computeInvervalPositions({
              u,
              data,
              sourceFrom: visiblePpeaks,
              sourceTo: visibleQpeaks,
            });

            pqIntervals.forEach((interval) => {
              $(u.root.querySelector('.u-over')).append(buildIntervalIndicator(interval));
            });
          }

          if (showIntervals.QRS) {
            const qrsIntervals = computeInvervalPositions({
              u,
              data,
              sourceFrom: visibleQpeaks,
              sourceTo: visibleSpeaks,
            });

            qrsIntervals.forEach((interval) => {
              $(u.root.querySelector('.u-over')).append(buildIntervalIndicator(interval));
            });
          }

          if (showIntervals.QT) {
            const qtIntervals = computeInvervalPositions({
              u,
              data,
              sourceFrom: visibleQpeaks,
              sourceTo: visibleTpeaks,
            });

            qtIntervals.forEach((interval) => {
              $(u.root.querySelector('.u-over')).append(buildIntervalIndicator(interval));
            });
          }

          /**
           * Handle noise (U) intervals
           */
          if (showIntervals.U) {
            const [noisesFrom, noisesTo] = visibleNoises.reduce(
              (acc, v) => {
                /**
                 * start index might be bigger then end index,
                 * need to sort numbers first for interval to build correctly
                 */
                const [from, to] = [v.startIndex, v.endIndex].sort((a, b) => a - b);

                const [start, end] = acc;

                start.push(from);
                end.push(to);

                return acc;
              },
              [[], []],
            );

            const noiseIntervals = computeInvervalPositions({
              u,
              data,
              sourceFrom: noisesFrom,
              sourceTo: noisesTo,
              annotations: visibleNoises,
              onClick: (noiseId) => onDeleteNoise(noiseId),
            });

            noiseIntervals.forEach((interval) => {
              $(u.root.querySelector('.u-over')).append(buildNoiseInterval(interval));
            });
          }

          /**
           * Handle atrial fibrillation (AFib) intervals
           */
          if (showIntervals.AFib) {
            const [afibFrom, afibTo] = visibleAfibs.reduce(
              (r, v) => {
                const [start, end] = r;

                start.push(v.startIndex);
                end.push(v.endIndex);

                return r;
              },
              [[], []],
            );
            const afibIntervals = computeInvervalPositions({
              u,
              data,
              sourceFrom: afibFrom,
              sourceTo: afibTo,
              annotations: visibleAfibs,
              onClick: (afibId) => onDeleteAFib(afibId),
            });

            afibIntervals.forEach((interval) => {
              $(u.root.querySelector('.u-over')).append(buildAFibInterval(interval));
            });
          }

          /**
           * Handle Pause Episodes
           */
          if (showIntervals.PE) {
            const [from, to] = visiblePauseEpisodes.reduce(
              (r, v) => {
                const [start, end] = r;

                start.push(v.startIndex);
                end.push(v.endIndex);

                return r;
              },
              [[], []],
            );
            const pauseEpisodeIntervals = computeInvervalPositions({
              u,
              data,
              sourceFrom: from,
              sourceTo: to,
              annotations: visiblePauseEpisodes,
            });

            pauseEpisodeIntervals.forEach((interval) => {
              $(u.root.querySelector('.u-over')).append(buildPauseEpisodeInterval(interval));
            });
          }

          /**
           * Handle RR intervals
           */
          if (showIntervals.RR) {
            let lastSampleIndex;

            visibleRpeaks.forEach(({ sampleIndex }) => {
              if (!lastSampleIndex) {
                lastSampleIndex = sampleIndex;

                return;
              }

              const currValPos = getSamplePosition({
                u,
                data,
                sampleIndex: lastSampleIndex,
              });
              const nextValPos = getSamplePosition({
                u,
                data,
                sampleIndex,
              });

              $(u.root.querySelector('.u-over')).append(
                buildIntervalIndicator({
                  fromX: currValPos[0],
                  fromY: currValPos[1],
                  toY: nextValPos[1],
                  toX: nextValPos[0],
                  content: `${(sampleIndex - lastSampleIndex) / 200}s`,
                }),
              );

              lastSampleIndex = sampleIndex;
            });
          }

          /** Automatic Rpeak annotations */
          if (showAnnotations.ML) {
            visibleAutomaticRpeaks.forEach((item) => {
              const [samplePosX, samplePosY] = getSamplePosition({
                u,
                sampleIndex: item.sampleIndex,
                data,
              });

              $(u.root.querySelector('.u-over')).append(
                buildIndicator({
                  relativeOffsetX: samplePosX,
                  relativeOffsetY: samplePosY,
                  content: item.annotationValue,
                  placement: 'NE',
                  background: 'orange',
                }),
              );
            });
          }

          [
            ['P', visiblePpeaks],
            ['Q', visibleQpeaks],
            ['S', visibleSpeaks],
            ['T', visibleTpeaks],
          ].forEach(([label, peaks]) => {
            if (!showPeaks[label]) {
              return;
            }

            peaks.forEach((sample) => {
              const [samplePosX, samplePosY] = getSamplePosition({
                u,
                sampleIndex: sample,
                data,
              });

              let placement = 'NW';

              if (label === 'E') {
                placement = 'NE';
              }

              $(u.root.querySelector('.u-over')).append(
                buildIndicator({
                  relativeOffsetX: samplePosX,
                  relativeOffsetY: samplePosY,
                  content: label,
                  placement,
                }),
              );
            });
          });

          if (showPeaks.R) {
            visibleRpeaks.forEach(({ sampleIndex, annotationValue }) => {
              if (sampleIndex < i0 || sampleIndex > i1) {
                return;
              }

              // Value of the current sample
              const sampleValue = u.data[1][sampleIndex];
              // X position of the current sample
              const samplePosX = Math.round(u.valToPos(sampleIndex, 'x', false));
              // Y position of the current sample
              const samplePosY = Math.round(u.valToPos(sampleValue, 'y', false));

              $(u.root.querySelector('.u-over')).append(
                buildIndicator({
                  relativeOffsetX: samplePosX,
                  relativeOffsetY: samplePosY,
                  content: annotationValue,
                  placement: 'SW',
                  background: highlightIndex === sampleIndex ? 'palevioletred' : 'lightgray',
                  onClick: () => {
                    const newVal = prompt('Enter N, S, V or U');

                    if (!['N', 'S', 'V', 'F', 'U'].includes(newVal ?? '')) {
                      alert(`You entered "${newVal}", which is not N, S, V, or U`);
                    } else {
                      updateAnnotation({
                        sampleIndex,
                        value: newVal,
                      });
                    }
                  },
                }),
              );

              $(u.root.querySelector('.u-over')).append(
                buildIndicator({
                  relativeOffsetX: samplePosX,
                  relativeOffsetY: samplePosY,
                  content: 'R',
                  placement: 'NW',
                }),
              );
            });
          }

          /** Point Comments */
          visiblePointComments.forEach((pointComment) => {
            const [samplePosX, samplePosY] = getSamplePosition({
              u,
              sampleIndex: pointComment.sampleIndex,
              data,
            });

            const label = pointComment.comment;

            $(u.root.querySelector('.u-over')).append(
              buildPointCommentIndicator({
                relativeOffsetX: samplePosX,
                relativeOffsetY: samplePosY,
                content: label,
                onUpdate: onUpdatePointComment(pointComment.id),
                onDelete: onDeletePointComment(pointComment.id),
              }),
            );
          });

          /**
           * Health Indications
           */
          if (showCharts.healthIndication) {
            const visibleHealthIndications = healthIndications.filter(
              ({ sampleIndex }) => sampleIndex > plotScale[0] && sampleIndex < plotScale[1],
            );

            visibleHealthIndications.forEach((item) => {
              const [samplePosX, samplePosY] = getSamplePosition({
                u,
                sampleIndex: item.sampleIndex,
                data,
              });

              if (!item.duration_s) {
                return $(u.root.querySelector('.u-over')).append(
                  buildHealthIndicationChartAnnotation({
                    relativeOffsetX: samplePosX,
                    relativeOffsetY: samplePosY,
                    content: item.content,
                    type: item.type,
                    time: item.time,
                  }),
                );
              }

              const [samplePosToX] = getSamplePosition({
                u,
                sampleIndex: item.sampleIndex + item.duration_s * 200,
                data,
              });

              return $(u.root.querySelector('.u-over')).append(
                buildHealthIndicationInterval({
                  fromX: samplePosX,
                  toX: samplePosToX,
                  content: item.content,
                  type: item.type,
                  time: item.time,
                  duration_s: item.duration_s,
                  height: 130,
                }),
              );
            });
          }

          if (highlightMiddleSample) {
            ctx.closePath();
            ctx.stroke();

            const markerColor = '#00ff007a';
            const middlemostPoint = Math.round(i0 + (i1 - i0) / 2);
            const cx = Math.round(u.valToPos(middlemostPoint, 'x', true));

            ctx.strokeStyle = markerColor;
            ctx.beginPath();
            ctx.moveTo(cx, top);
            ctx.lineTo(cx, top + height);
            ctx.closePath();
            ctx.stroke();
          }

          ctx.closePath();
          ctx.stroke();
          ctx.restore();
        },
      ],
    },
  };

  useEffect(() => {
    let uplot: uPlot;

    if (plotRef.current) {
      // eslint-disable-next-line new-cap
      uplot = new uPlot(uplotOptions, data, plotRef.current);

      if (plotScale) {
        uplot.setScale('x', {
          min: plotScale[0],
          max: plotScale[1],
        });
      }
    }

    return () => {
      uplot.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rpeaks, updateAnnotation, xAxisTime, sync, plotScale, onPlotScaleChange, data]);

  return (
    <div style={{ position: 'relative' }}>
      <div ref={plotRef} />
    </div>
  );
}
