import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 800,
      flexGrow: 1,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

// Mapping warning keys to translation keys
const warnings: { id: number; type: string; textKey: string }[] = [
  { type: 'Warning', textKey: 'warnings.messages.notDiagnosis' },
  { type: 'Warning', textKey: 'warnings.messages.intendedUse' },
  { type: 'Warning', textKey: 'warnings.messages.personalizedData' },
  { type: 'Warning', textKey: 'warnings.messages.independentExamination' },
  { type: 'Warning', textKey: 'warnings.messages.healthChanges' },
  { type: 'Warning', textKey: 'warnings.messages.noDiagnosis' },
  { type: 'Warning', textKey: 'warnings.messages.healthStateChange' },
  { type: 'Warning', textKey: 'warnings.messages.physicianLink' },
  { type: 'Warning', textKey: 'warnings.messages.readingPreparation' },
  { type: 'Warning', textKey: 'warnings.messages.avoidVibrations' },
  { type: 'Warning', textKey: 'warnings.messages.electrodesWet' },
  { type: 'Warning', textKey: 'warnings.messages.bodyHair' },
  { type: 'Warning', textKey: 'warnings.messages.electrodesPosition' },
  { type: 'Warning', textKey: 'warnings.messages.userManual' },
  { type: 'Warning', textKey: 'warnings.messages.defibrillatorWarning' },
  { type: 'Warning', textKey: 'warnings.messages.noExternalDevices' },
  { type: 'Warning', textKey: 'warnings.messages.highElectromagneticRadiation' },
  { type: 'Warning', textKey: 'warnings.messages.avoidElectrostatics' },
  { type: 'Warning', textKey: 'warnings.messages.notForPacemakers' },
  { type: 'Attention', textKey: 'warnings.messages.checkDateTime' },
  { type: 'Attention', textKey: 'warnings.messages.contactSupport' },
].map((it, index) => ({ id: index + 1, ...it }));

export function Warnings() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid spacing={3} container>
        <Grid xs={12} item>
          {warnings.map((it) => (
            <Typography key={it.id} paragraph>
              <b>{t(`warnings.${it.type.toLowerCase()}`)}</b> {t(it.textKey)}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
