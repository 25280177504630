import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import { AnnotationValue } from 'types/schema.type';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 400,
  },
  cell: {
    border: 'none',
  },
  cellTime: {
    border: 'none',
    cursor: 'pointer',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

type EctopicBeat =
  | {
      sampleIndexes: number[];
    }
  | undefined;

type TachycardiaResultsTableProps = {
  recordingStartedAt: number;
  ventricularDuplets: EctopicBeat[];
  supraVentricularDuplets: EctopicBeat[];
  ventricularTriplets: EctopicBeat[];
  supraVentricularTriplets: EctopicBeat[];
  ventricularRuns: EctopicBeat[];
  supraVentricularRuns: EctopicBeat[];
  callback: (arg: unknown) => void;
};

export function TachycardiaResultsTable(props: TachycardiaResultsTableProps) {
  const classes = useStyles();

  const {
    recordingStartedAt,
    ventricularDuplets,
    supraVentricularDuplets,
    ventricularTriplets,
    supraVentricularTriplets,
    ventricularRuns,
    supraVentricularRuns,
    callback,
  } = props;

  const getTime = (sampleIndex: number = 0) =>
    format(recordingStartedAt + (sampleIndex / 200) * 1000, 'yyy-MM-dd H:mm:ss');

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Tachycardia Results
      </Typography>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ventricularDuplets.map((it) => (
            <TableRow key={it?.sampleIndexes[0] ?? 1}>
              <TableCell className={classes.cell}>Duplet {AnnotationValue.V}</TableCell>
              <TableCell
                className={classes.cellTime}
                align="right"
                onClick={() => callback(it?.sampleIndexes[0] ?? 0)}
              >
                {getTime(it?.sampleIndexes[0])}
              </TableCell>
            </TableRow>
          ))}
          {ventricularDuplets.length === 0 && (
            <TableRow>
              <TableCell className={classes.cell}>Duplet {AnnotationValue.V}</TableCell>
              <TableCell className={classes.cell} align="right">
                None
              </TableCell>
            </TableRow>
          )}
          {supraVentricularDuplets.map((it) => (
            <TableRow key={it?.sampleIndexes[0] ?? 1}>
              <TableCell className={classes.cell}>Duplet {AnnotationValue.S}</TableCell>
              <TableCell
                className={classes.cellTime}
                align="right"
                onClick={() => callback(it?.sampleIndexes[0] ?? 0)}
              >
                {getTime(it?.sampleIndexes[0])}
              </TableCell>
            </TableRow>
          ))}
          {supraVentricularDuplets.length === 0 && (
            <TableRow>
              <TableCell className={classes.cell}>Duplet {AnnotationValue.S}</TableCell>
              <TableCell className={classes.cell} align="right">
                None
              </TableCell>
            </TableRow>
          )}
          {ventricularTriplets.map((it) => (
            <TableRow key={it?.sampleIndexes[0] ?? 1}>
              <TableCell className={classes.cell}>Triplet {AnnotationValue.V}</TableCell>
              <TableCell
                className={classes.cellTime}
                align="right"
                onClick={() => callback(it?.sampleIndexes[0] ?? 0)}
              >
                {getTime(it?.sampleIndexes[0])}
              </TableCell>
            </TableRow>
          ))}
          {ventricularTriplets.length === 0 && (
            <TableRow>
              <TableCell className={classes.cell}>Triplet {AnnotationValue.V}</TableCell>
              <TableCell className={classes.cell} align="right">
                None
              </TableCell>
            </TableRow>
          )}
          {supraVentricularTriplets.map((it) => (
            <TableRow key={it?.sampleIndexes[0] ?? 1}>
              <TableCell className={classes.cell}>Triplet {AnnotationValue.S}</TableCell>
              <TableCell
                className={classes.cellTime}
                align="right"
                onClick={() => callback(it?.sampleIndexes[0] ?? 0)}
              >
                {getTime(it?.sampleIndexes[0])}
              </TableCell>
            </TableRow>
          ))}
          {supraVentricularTriplets.length === 0 && (
            <TableRow>
              <TableCell className={classes.cell}>Triplet {AnnotationValue.S}</TableCell>
              <TableCell className={classes.cell} align="right">
                None
              </TableCell>
            </TableRow>
          )}
          {ventricularRuns.map((it) => (
            <TableRow key={it?.sampleIndexes[0] ?? 1}>
              <TableCell className={classes.cell}>Run {AnnotationValue.V}</TableCell>
              <TableCell
                className={classes.cellTime}
                align="right"
                onClick={() => callback(it?.sampleIndexes[0] ?? 0)}
              >
                {getTime(it?.sampleIndexes[0])}
              </TableCell>
            </TableRow>
          ))}
          {ventricularRuns.length === 0 && (
            <TableRow>
              <TableCell className={classes.cell}>Run {AnnotationValue.V}</TableCell>
              <TableCell className={classes.cell} align="right">
                None
              </TableCell>
            </TableRow>
          )}
          {supraVentricularRuns.map((it) => (
            <TableRow key={it?.sampleIndexes[0] ?? 1}>
              <TableCell className={classes.cell}>Run {AnnotationValue.S}</TableCell>
              <TableCell
                className={classes.cellTime}
                align="right"
                onClick={() => callback(it?.sampleIndexes[0] ?? 0)}
              >
                {getTime(it?.sampleIndexes[0])}
              </TableCell>
            </TableRow>
          ))}
          {supraVentricularRuns.length === 0 && (
            <TableRow>
              <TableCell className={classes.cell}>Run {AnnotationValue.S}</TableCell>
              <TableCell className={classes.cell} align="right">
                None
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
}
