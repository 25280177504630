import $ from 'jquery';
import lodash from 'lodash';
import { Interval } from 'utilities/types';

export const buildIntervalIndicator = ({
  fromX,
  fromY,
  toX,
  toY,
  content,
  onClick,
  isFromAnalysis,
}: Interval) => {
  const height = Math.abs(fromY - toY) + 20;
  const top = lodash?.min([fromY, toY]) ?? 0 - 10;

  const container = $('<div></div>').css({
    top,
    left: fromX,
    width: toX - fromX,
    height,
    // minHeight: 20,
    position: 'absolute',
    // outline: '1px dashed green'
  });

  const ruler = $(`<div class="ruler">
    <div></div>
    <div></div>
    <div>${content}</div>
    <div></div>
    <div></div>
  </div>`);

  if (isFromAnalysis) {
    ruler.addClass('ecg');
  }

  $(ruler).on('click', onClick);

  container.append(ruler);

  container.addClass('uplot-additional-element');

  return container;
};
