interface CreateEpisodeContentSeriesArgs {
  xAxis: number[];
  start: number;
  end: number;
  yAxisValue: number;
}

/**
 * Function creates series to display episode content in the given series by provided start and end
 */
export function createEpisodeContentSeries({
  xAxis,
  start,
  end,
  yAxisValue,
}: CreateEpisodeContentSeriesArgs): (null | number)[] {
  const series = xAxis.map((sampleIndex) => (sampleIndex >= start && sampleIndex <= end ? yAxisValue : null));

  return series;
}
