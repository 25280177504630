import { useState, useEffect } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import lodash from 'lodash';

import { useUser } from 'actions/useUser';
import { Role } from 'types/schema.type';
import { AutomaticRpeakAnnotation, RpeakAnnotation, AnnotationValue } from 'types/index';
import { ThreeChannelChart } from './ThreeChannelChart';
import { LoadingSpinner } from './LoadingSpinner';
import { RECORDING_JSON, RECORDING_RPEAKS } from '../queries';
import {
  CREATE_AFIB,
  CREATE_NOISE,
  DELETE_AFIB,
  DELETE_NOISE,
  CREATE_POINT_COMMENT,
  UPDATE_POINT_COMMENT,
  DELETE_POINT_COMMENT,
  EDIT_INTERVAL,
} from '../mutations';

export function RecordingChart({ recordingId }: { recordingId: string }) {
  const [localRpeaks, setLocalRpeaks] = useState<RpeakAnnotation[]>([]);
  const { user, loading: userLoading } = useUser();

  const [updateAnnotation] = useMutation(gql`
    mutation Mutation(
      $updateAnalysisRPeakRecordingId: MongoObjectID!
      $updateAnalysisRPeakRpeakSampleIndex: Int!
      $updateAnalysisRPeakValue: AnnotationValue!
    ) {
      updateAnnotation(
        recordingId: $updateAnalysisRPeakRecordingId
        sampleIndex: $updateAnalysisRPeakRpeakSampleIndex
        value: $updateAnalysisRPeakValue
      ) {
        success
        error
      }
    }
  `);

  const [deleteAnnotation] = useMutation(gql`
    mutation Mutation($recordingId: MongoObjectID!, $sampleIndex: Int!) {
      deleteAnnotation(recordingId: $recordingId, sampleIndex: $sampleIndex) {
        success
        error
      }
    }
  `);

  const [createNoise] = useMutation(CREATE_NOISE, {
    refetchQueries: [
      {
        query: RECORDING_RPEAKS,
        variables: {
          getMyRecordingId: recordingId,
        },
      },
    ],
  });

  const [createAFib] = useMutation(CREATE_AFIB, {
    refetchQueries: [
      {
        query: RECORDING_RPEAKS,
        variables: {
          getMyRecordingId: recordingId,
        },
      },
    ],
  });

  const [deleteNoise] = useMutation(DELETE_NOISE, {
    refetchQueries: [
      {
        query: RECORDING_RPEAKS,
        variables: {
          getMyRecordingId: recordingId,
        },
      },
    ],
  });

  const [deleteAFib] = useMutation(DELETE_AFIB, {
    refetchQueries: [
      {
        query: RECORDING_RPEAKS,
        variables: {
          getMyRecordingId: recordingId,
        },
      },
    ],
  });

  const [createPointComment] = useMutation(CREATE_POINT_COMMENT, {
    refetchQueries: [
      {
        query: RECORDING_JSON,
        variables: {
          getMyRecordingId: recordingId,
        },
      },
    ],
  });

  const [updatePointComment] = useMutation(UPDATE_POINT_COMMENT, {
    refetchQueries: [
      {
        query: RECORDING_JSON,
        variables: {
          getMyRecordingId: recordingId,
        },
      },
    ],
  });

  const [deletePointComment] = useMutation(DELETE_POINT_COMMENT, {
    refetchQueries: [
      {
        query: RECORDING_JSON,
        variables: {
          getMyRecordingId: recordingId,
        },
      },
    ],
  });

  const [editInterval] = useMutation(EDIT_INTERVAL, {
    refetchQueries: [
      {
        query: RECORDING_RPEAKS,
        variables: {
          getMyRecordingId: recordingId,
        },
      },
    ],
  });

  const { loading, error, data } = useQuery(RECORDING_JSON, {
    variables: {
      getMyRecordingId: recordingId,
    },
  });

  const {
    loading: rpeaksLoading,
    data: rpeaks,
    refetch: refetchRpeaks,
  } = useQuery(RECORDING_RPEAKS, {
    variables: {
      getMyRecordingId: recordingId,
    },
  });

  useEffect(() => {
    refetchRpeaks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingId]);

  useEffect(() => {
    setLocalRpeaks(rpeaks?.getMyRecording?.analysis?.annotations.map((i) => ({ ...i })));
  }, [recordingId, rpeaks]);

  if (userLoading || !user) return <div>Loading user...</div>;

  const isUserRole = user.role === Role.User;

  if (!recordingId) {
    return <div>Please select a recording</div>;
  }



  if (loading || rpeaksLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    console.log(error);

    return <div>Error while loading data</div>;
  }

  const updateAnnotationHandler = async ({ sampleIndex, value }) => {
    if (isUserRole) return;

    if (value === null) {
      lodash.remove(localRpeaks, (rpeak) => rpeak.sampleIndex === sampleIndex);
      setLocalRpeaks(localRpeaks);
      await deleteAnnotation({
        variables: {
          recordingId,
          sampleIndex,
        },
      });
    } else {
      const existingPeak = lodash(localRpeaks).find((i) => sampleIndex === i.sampleIndex);

      if (!existingPeak) {
        localRpeaks.push({
          __typename: 'Annotation',
          sampleIndex,
          annotationValue: AnnotationValue.N,
        });
      } else {
        existingPeak.annotationValue = value;
      }

      setLocalRpeaks(lodash.sortBy(localRpeaks, 'sampleIndex'));

      try {
        await updateAnnotation({
          variables: {
            updateAnalysisRPeakRecordingId: recordingId,
            updateAnalysisRPeakRpeakSampleIndex: sampleIndex,
            updateAnalysisRPeakValue: value,
          },
        });
      } catch (updateError: any) {
        alert(
          `Failed to update annotation. Please contact your administrator or try again. Error: ${
            updateError?.message ?? 'unknown'
          }`,
        );
        throw updateError;
      }
    }

    // await refetchRpeaks();
  };

  const handleCreateNoise = ({ startIndex, endIndex }) => {
    if (isUserRole) return;

    createNoise({
      variables: {
        createNoiseInput: {
          recordingId,
          startIndex,
          endIndex,
        },
      },
    });
  };

  const handleCreateAFib = ({ startIndex, endIndex }) => {
    if (isUserRole) return;

    createAFib({
      variables: {
        createAFibInput: {
          recordingId,
          startIndex,
          endIndex,
        },
      },
    });
  };

  const handleDeleteNoise = (noiseId) => {
    if (isUserRole) return;

    deleteNoise({
      variables: {
        deleteNoiseId: noiseId,
      },
    });
  };

  const handleDeleteAFib = (afibId) => {
    if (isUserRole) return;

    deleteAFib({
      variables: {
        deleteAFibId: afibId,
      },
    });
  };

  const handleCreatePointComment = ({ sampleIndex, comment }) => {
    if (isUserRole) return;

    createPointComment({
      variables: {
        input: {
          recordingId,
          sampleIndex,
          comment,
        },
      },
    });
  };

  const handleUpdatePointComment = (pointCommentId) => (newComment) => {
    if (isUserRole) return;

    updatePointComment({
      variables: {
        input: {
          pointCommentId,
          comment: newComment,
        },
      },
    });
  };

  const handleDeletePointComment = (pointCommentId) => () => {
    if (isUserRole) return;

    deletePointComment({
      variables: {
        deletePointCommentId: pointCommentId,
      },
    });
  };

  const handleEditAnnotationInterval = ({ id, startIndex, endIndex }) => {
    if (isUserRole) return;
    
    editInterval({
      variables: {
        input: {
          annotationId: id,
          startIndex,
          endIndex,
        },
      },
    });
  };

  if (!localRpeaks) {
    return <div>no rpeaks</div>;
  }

  const sortedNoises = [...(rpeaks?.getMyRecording?.analysis?.noises ?? [])].sort(
    (a, b) => a.startIndex - b.startIndex,
  );

  const sortedAfibs = [...(rpeaks?.getMyRecording?.analysis?.afibs ?? [])].sort(
    (a, b) => a.startIndex - b.startIndex,
  );

  const sortedPointComments = [...(data?.getMyRecording?.pointComments || [])].sort(
    (a, b) => a.sampleIndex - b.sampleIndex,
  );

  /**
   * Prepares x series with indexes based on y series
   */
  function prepData(yAxis) {
    const xAxis = yAxis.map((value, index) => index);

    return [xAxis, yAxis];
  }

  const automaticRpeakAnnotations: AutomaticRpeakAnnotation[] =
    rpeaks?.getMyRecording?.analysis?.automaticRpeakAnnotations ?? [];

  if (data.getMyRecording.channelCount === 3) {
    return (
      <ThreeChannelChart
        recordingId={recordingId}
        recordingStartedAt={data.getMyRecording.recordingStartedAt}
        data={{
          ch1: prepData(data.getMyRecording.json.ch1),
          ch2: prepData(data.getMyRecording.json.ch2),
          ch3: prepData(data.getMyRecording.json.ch3),
        }}
        updateAnnotationHandler={updateAnnotationHandler}
        rpeaks={localRpeaks}
        ppeaks={rpeaks?.getMyRecording?.analysis?.pPeaks || []}
        qpeaks={rpeaks?.getMyRecording?.analysis?.qPeaks || []}
        speaks={rpeaks?.getMyRecording?.analysis?.sPeaks || []}
        tpeaks={rpeaks?.getMyRecording?.analysis?.tPeaks || []}
        automaticRpeakAnnotations={automaticRpeakAnnotations}
        rate={rpeaks?.getMyRecording?.analysis?.rate || []}
        noises={sortedNoises}
        afibs={sortedAfibs}
        pauseEpisodes={rpeaks?.getMyRecording?.analysis?.pauseEpisodes}
        pointComments={sortedPointComments}
        comment={data.getMyRecording.comment}
        onCreateNoise={handleCreateNoise}
        onDeleteNoise={handleDeleteNoise}
        onCreateAFib={handleCreateAFib}
        onDeleteAFib={handleDeleteAFib}
        onUpdatePointComment={handleUpdatePointComment}
        onDeletePointComment={handleDeletePointComment}
        onCreatePointComment={handleCreatePointComment}
        onEditAnnotationInterval={handleEditAnnotationInterval}
      />
    );
  }

  return (
    <ThreeChannelChart
      recordingId={recordingId}
      recordingStartedAt={data.getMyRecording.recordingStartedAt}
      data={{
        ch1: prepData(data.getMyRecording.json.ch1),
      }}
      updateAnnotationHandler={updateAnnotationHandler}
      rpeaks={localRpeaks}
      ppeaks={rpeaks?.getMyRecording?.analysis?.pPeaks || []}
      qpeaks={rpeaks?.getMyRecording?.analysis?.qPeaks || []}
      speaks={rpeaks?.getMyRecording?.analysis?.sPeaks || []}
      tpeaks={rpeaks?.getMyRecording?.analysis?.tPeaks || []}
      automaticRpeakAnnotations={automaticRpeakAnnotations}
      rate={rpeaks?.getMyRecording?.analysis?.rate || []}
      noises={sortedNoises}
      afibs={sortedAfibs}
      pauseEpisodes={rpeaks?.getMyRecording?.analysis?.pauseEpisodes}
      pointComments={sortedPointComments}
      comment={data.getMyRecording.comment}
      onCreateNoise={handleCreateNoise}
      onDeleteNoise={handleDeleteNoise}
      onCreateAFib={handleCreateAFib}
      onDeleteAFib={handleDeleteAFib}
      onUpdatePointComment={handleUpdatePointComment}
      onDeletePointComment={handleDeletePointComment}
      onCreatePointComment={handleCreatePointComment}
      onEditAnnotationInterval={handleEditAnnotationInterval}
    />
  );
}
