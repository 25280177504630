import { gql } from '@apollo/client';

export const RECORDING_RPEAKS = gql`
  query RecordingRpeaks($getMyRecordingId: MongoObjectID!) {
    getMyRecording(id: $getMyRecordingId) {
      analysis {
        id
        annotations {
          sampleIndex
          annotationValue
        }
        pPeaks
        qPeaks
        sPeaks
        tPeaks
        automaticRpeakAnnotations {
          sampleIndex
          annotationValue
        }
        noises {
          id
          startIndex
          endIndex
          annotationValue
        }
        afibs {
          id
          startIndex
          endIndex
          annotationValue
          isFromAnalysis
        }
        rate
        avgHeartrate
        beatCount
        bradycardiaEpisodes {
          end
          duration_s
          mean_hr
          start
        }
        pauseEpisodes {
          startIndex
          endIndex
        }
        minHeartrate
        maxHeartrate
      }
    }
  }
`;
