/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { DataGrid, GridFilterInputValue, getGridStringOperators, GridSortModel } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { useUser } from 'actions/useUser';
import { Role } from 'types/schema.type';

import { LoadingSpinner } from '../LoadingSpinner';
import { DOWNLOAD_RECORDINGS_BATCH } from '../../mutations';
import { GridCellExpand } from '../datagrid/GridCellExpand';
import { GridCustomToolbar } from '../datagrid/GridCustomToolbar';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    width: 1400,
    marginTop: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  downloadButton: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const getIsoTime = (timestamp) => {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  const isoTime = date.toISOString();

  return isoTime;
};

const stringifyFlags = (flags) => {
  if (!flags) return '';
  if (!flags.length) return '';

  return flags.join(', ');
  // return flags.join(',\n');
};

const makeRows = (items) =>
  items.map((item) => ({
    id: item.id,
    channelCount: item.channelCount,
    userEmail: item.user.email,
    userId: item.user.id,
    comment: item.comment,
    rPeaks: item.rpeaksCount,
    fileName: item.fileName,
    flags: stringifyFlags(item.flags),
    recordingStartedAt: getIsoTime(item.recordingStartedAt),
    uploadedAt: getIsoTime(item.uploadedAt),
  }));

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value ? params.value.toString() : ''}
      width={params.colDef.computedWidth}
    />
  );
}

function prepFilterValues(item) {
  if (!item?.value) return [];

  return item.value.split(' ').map((it) => it.trim().toUpperCase());
}

const columns = [
  { field: 'id', headerName: 'ID', width: 220 },
  {
    field: 'channelCount',
    headerName: 'CH',
    width: 100,
  },
  {
    field: 'userEmail',
    headerName: 'User',
    width: 160,
    renderCell: renderCellExpand,
  },
  {
    field: 'userId',
    headerName: 'User ID',
    width: 220,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    width: 160,
    renderCell: renderCellExpand,
  },
  {
    field: 'rPeaks',
    headerName: 'R-Peaks',
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    field: 'fileName',
    headerName: 'File Name',
    width: 140,
  },
  {
    field: 'flags',
    headerName: 'Flags',
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: 'recordingStartedAt',
    headerName: 'Recorded At',
    width: 200,
  },
  {
    field: 'uploadedAt',
    headerName: 'Uploaded At',
    width: 200,
  },
];

export const GET_ALL_RECORDINGS = gql`
  query Query {
    getRecordingsById {
      id
      channelCount
      comment
      fileName
      flags
      recordingStartedAt
      uploadedAt
      rpeaksCount
      user {
        id
        email
      }
    }
  }
`;

export function RecordingManagement() {
  const classes = useStyles();
  const [selectedRecordingIds, setSelectedRecordingIds] = React.useState([]);
  const [showDownloadButton, setShowDownloadButton] = React.useState(true);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'recordingStartedAt',
      sort: 'desc',
    },
  ]);

  const { user, loading: authLoading } = useUser();

  const { loading: dataLoading, error, data } = useQuery(GET_ALL_RECORDINGS);

  const [
    downloadRecordingsBatch,
    { data: downloadData, loading: downloadLoading, error: downloadError },
  ] = useMutation(DOWNLOAD_RECORDINGS_BATCH);

  const downlaodSuccess = downloadData?.downloadRecordingsBatch?.success;

  React.useEffect(() => {
    if (downloadLoading) {
      setShowDownloadButton(false);
    }

    if (!downloadLoading && (downloadError || downlaodSuccess === false)) {
      setShowError(true);
    }

    if (downlaodSuccess) {
      setShowSuccess(true);
    }
  }, [downloadLoading, downloadError, downlaodSuccess]);

  if (authLoading || !user) return <div>Loading auth...</div>;

  if (user.role !== Role.Admin) return null;

  if (dataLoading) return <div>Loading data...</div>;

  if (error) return <div>Error: ${error.message}</div>;

  const recordings = data.getRecordingsById;
  const rows = makeRows(recordings);

  const handleSelectChange = (ids) => setSelectedRecordingIds(ids);

  const handleDownload = () => {
    downloadRecordingsBatch({
      variables: { recordingIds: selectedRecordingIds },
    });
  };

  const handleAlertClose = () => {
    setShowDownloadButton(true);
    setShowError(false);
    setShowSuccess(false);
  };

  const handleShowChart = () => {
    if (!selectedRecordingIds.length) return null;

    const query = selectedRecordingIds.join(',');

    return window.open(`/admin-dashboard/recording?id=${query}`, '_blank');
  };

  const flagOnlyOperators = [
    ...getGridStringOperators(),
    {
      label: 'contains AND',
      value: 'contain and',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }

        const filterValues = prepFilterValues(filterItem);

        return (params) => {
          const { value } = params;

          return filterValues.every((it) => value.includes(it));
        };
      },
      InputComponent: GridFilterInputValue,
      InputComponentProps: { type: 'string' },
    },
    {
      label: 'contains OR',
      value: 'contain or',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }

        const filterValues = prepFilterValues(filterItem);

        return (params) => {
          const { value } = params;

          return filterValues.some((it) => value.includes(it));
        };
      },
      InputComponent: GridFilterInputValue,
      InputComponentProps: { type: 'string' },
    },
  ];

  if (columns.length > 0) {
    const flagColumns = columns.find((col) => col.field === 'flags');
    const newRatingColumn = {
      ...flagColumns,
      filterOperators: flagOnlyOperators,
    };

    const flagsColIndex = columns.findIndex((col) => col.field === 'flags');

    columns[flagsColIndex] = newRatingColumn as any;
  }

  return (
    <>
      <Container className={classes.container} maxWidth={false}>
        {showSuccess && (
          <Alert
            severity="success"
            action={
              <Button color="inherit" size="small" onClick={handleAlertClose}>
                CLOSE
              </Button>
            }
          >
            <AlertTitle>Success</AlertTitle>
            Download link will be sent to {user.email}
          </Alert>
        )}
        {showError && (
          <Alert
            severity="error"
            action={
              <Button color="inherit" size="small" onClick={handleAlertClose}>
                CLOSE
              </Button>
            }
          >
            <AlertTitle>Error</AlertTitle>
            {downloadData?.downloadRecordingsBatch?.error}
          </Alert>
        )}
        {downloadLoading && <LoadingSpinner />}
        {showDownloadButton && (
          <>
            <Button
              className={classes.downloadButton}
              variant="contained"
              color="primary"
              disabled={!selectedRecordingIds.length}
              onClick={handleDownload}
            >
              Download selected
            </Button>

            <Button
              className={classes.downloadButton}
              variant="contained"
              color="primary"
              disabled={!selectedRecordingIds.length}
              onClick={handleShowChart}
            >
              Open in new tab
            </Button>
          </>
        )}

        <div style={{ width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: GridCustomToolbar,
            }}
            filterModel={filterModel}
            sortModel={sortModel}
            autoHeight
            checkboxSelection
            disableSelectionOnClick
            onFilterModelChange={setFilterModel as any}
            onSelectionModelChange={handleSelectChange}
            onSortModelChange={setSortModel}
          />
        </div>
      </Container>
    </>
  );
}
