import { gql } from '@apollo/client';

export const MY_RECORDINGS = gql`
  query GetMyRecordingsPaginated($offset: Int!, $limit: Int!) {
    getMyRecordingsPaginated(offset: $offset, limit: $limit) {
      success
      error
      totalCount
      recordings {
        id
        channelCount
        fileName
        recordingStartedAt
        recordingEndedAt
        uploadedAt
        metadata {
          createdAt
          updatedAt
        }
        recordingIsUploaded
        downloadUrl
        comment
        analysis {
          id
          rpeaksCount
          success
          error
        }
        flags
      }
    }
  }
`;
