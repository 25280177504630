import { Outlet, useParams } from 'react-router-dom';
import { Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { SequenceList } from './SequenceList';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

export function SequenceLayout() {
  const classes = useStyles();
  const { sequenceId: sequenceIdParam } = useParams<{ sequenceId: string }>();

  const renderContent = () => {
    if (!sequenceIdParam) {
      return (
        <Typography variant="h5" gutterBottom>
          Select recording sequence for summary
        </Typography>
      );
    }

    return <Outlet />;
  };

  return (
    <Container className={classes.container} maxWidth={false}>
      <Grid spacing={2} container>
        <Grid xs={4} item>
          <Paper className={classes.paper}>
            <SequenceList />
          </Paper>
        </Grid>
        <Grid xs={8} item>
          <Grid direction="row" alignItems="center" container>
            {renderContent()}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
