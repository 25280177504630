import { HealthIndicationTable } from './HealthIndicationTable';
import { useHealthIndications } from './useHealthIndications';
import { getSampleIndexFromTimestamp } from './utils/getSampleIndexFromTimestamp';

export function HealthIndicationContainer(props: {
  recordingId: string;
  recordingStartedAt: number;
  scale: [number, number];
  setScale: (scale: [number, number]) => void;
}) {
  const { data, loading, error } = useHealthIndications();

  const handleCallback = (timestamp: number) => {
    const sampleIndex = getSampleIndexFromTimestamp(props.recordingStartedAt, timestamp);

    const halfScale = Math.round((props.scale[1] - props.scale[0]) / 2);

    const start = sampleIndex - halfScale;
    const end = sampleIndex + halfScale;

    props.setScale([start, end]);
  };

  if (loading) {
    return <div>Health indications loading...</div>;
  }

  if (error) {
    return <div>Health indications error: {error.message}</div>;
  }

  return (
    <HealthIndicationTable
      healthIndication={data[props.recordingId] ?? []}
      callback={handleCallback}
    />
  );
}
