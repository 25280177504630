import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import UPlotReact from 'uplot-react';
import uPlot from 'uplot';
import $ from 'jquery';
import { buildIndicator } from 'utilities';
import { AnnotationValue } from 'types/schema.type';
import { format } from 'date-fns';

type ExcerptChartProps = {
  title: string;
  sequenceStartedAt: number;
  annotationValue: AnnotationValue;
  sampleIndexes: number[];
  signal: number[];
};

export function ExcerptChart(props: ExcerptChartProps) {
  const { title, sequenceStartedAt, annotationValue, sampleIndexes, signal } = props;
  // excerpt has 1000 samples on each side from first and last sample index
  // time between sample indexes are 5ms (200 samples = 1 second)
  const startTimeInMs = sequenceStartedAt + (sampleIndexes[0] - 1000) * 5;

  const { t } = useTranslation();

  const options = useMemo<uPlot.Options>(
    () => ({
      title,
      width: 700, // A4 format - 210mm == 800px
      height: 135,
      series: [
        {
          label: t('excerptChart.seriesIndexLabel'),
        },
        {
          label: t('excerptChart.seriesVoltageLabel'),
          stroke: 'red',
        },
      ],
      axes: [
        {
          values: (_, splits) => splits.map((idx) => format(startTimeInMs + idx * 5, 'H:mm:ss')),
        },
        {
          label: t('excerptChart.axisVoltageLabel'),
        },
      ],
      hooks: {
        drawAxes: [
          (u: uPlot) => {
            sampleIndexes.forEach((currentSampleIndex, index) => {
              // excerpt has 1000 signal values from first and last sample index
              // but the current sample index has the value in a sequence context
              let sampleIndex = 1000;

              if (index > 0) {
                const firstSampleIndex = sampleIndexes[0];

                sampleIndex = 1000 + (currentSampleIndex - firstSampleIndex);
              }

              const annotationVoltage = u.data[1][sampleIndex] as number;
              const samplePosX = Math.round(u.valToPos(sampleIndex, 'x', false));
              const samplePosY = Math.round(u.valToPos(annotationVoltage, 'y', false));

              const shouldRenderAnnotation = annotationVoltage < 2 && annotationVoltage > -2;

              if (shouldRenderAnnotation) {
                $(u.root.querySelector('.u-over')).append(
                  buildIndicator({
                    relativeOffsetX: samplePosX,
                    relativeOffsetY: samplePosY,
                    content: annotationValue,
                    placement: 'NE',
                    background: 'orange',
                  }),
                );
              }
            });
          },
        ],
      },
      plugins: [],
      legend: { show: false },
      scales: { x: { time: false }, y: { range: [-2, 3] } },
    }),
    [annotationValue, sampleIndexes, startTimeInMs, title, t],
  );

  const chartData = useMemo(() => {
    const xAxis = Array.from({ length: signal.length }, (_, i) => i);

    return [xAxis, signal];
  }, [signal]) as uPlot.AlignedData;

  return <UPlotReact options={options} data={chartData} />;
}
