/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { ListItemSecondaryAction, IconButton, Snackbar } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeft from '@material-ui/icons/RotateLeft';
import Tooltip from '@material-ui/core/Tooltip';

import { Alert } from '@material-ui/lab';
import {
  GenericResponse,
  QueryGetSequentialUserRecordingsArgs,
  Role,
  Sequence,
  SequenceStatus,
  SequencesResponse,
} from 'types/schema.type';

import { useUser } from 'actions/useUser';
import { useCheckSubscription } from 'hooks/useCheckSubscription';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { LoadingSpinner } from '../LoadingSpinner';
import { getSequenceDuration } from './utils/getSequenceDuration';
import { HiddenSections } from './SequenceListItem';

const GET_SEQUENTIAL_USER_RECORDINGS = gql`
  query GetSequentialUserRecordings {
    getSequentialUserRecordings {
      success
      error
      sequences {
        analysisId
        endedAt
        id
        recordingIds
        startedAt
        userId
        status
      }
    }
  }
`;

export const GENERATE_SEQUENCE_REPORT = gql`
  mutation GenerateSequenceReport(
    $sequenceId: MongoObjectID!
    $hiddenSections: HiddenSectionsInput
  ) {
    generateSequenceReport(sequenceId: $sequenceId, hiddenSections: $hiddenSections) {
      error
      message
      success
    }
  }
`;

export const ANALYZE_SEQUENCE = gql`
  mutation AnalyzeSequence($sequenceId: MongoObjectID!) {
    analyzeSequence(sequenceId: $sequenceId) {
      error
      success
    }
  }
`;

export const DOWNLOAD_SEQUENCE = gql`
  mutation Mutation($sequenceId: MongoObjectID!) {
    downloadSequence(sequenceId: $sequenceId) {
      error
      success
    }
  }
`;

type GetUserRecordingSequencesResponse = {
  getSequentialUserRecordings: SequencesResponse;
};

const showDownloadSequenceButton = (
  window.REACT_PUBLIC_SERVER_ENV.graphqlUrl || process.env.REACT_APP_GRAPHQL_URL
)?.includes('api-staging');

export function SequenceList() {
  const { data: subscriptionData, loading: subscriptionLoading } = useCheckSubscription();
  const { user } = useUser();
  const navigate = useNavigate();
  const { sequenceId: sequenceIdParam } = useParams<{ sequenceId: string }>();

  const [open, setOpen] = useState(false);
  const [sendMessage, setSendMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [selectedSequenceId, setSelectedSequenceId] = useState<string | null>(null);

  const [hiddenSections] = useLocalStorage<HiddenSections>(
    `hiddenSections_${selectedSequenceId}`,
    {},
  );

  useEffect(() => {
    if (sequenceIdParam) {
      setSelectedSequenceId(sequenceIdParam);
    } else {
      setSelectedSequenceId(null);
    }
  }, [sequenceIdParam]);

  const { data, loading, error } = useQuery<
    GetUserRecordingSequencesResponse,
    QueryGetSequentialUserRecordingsArgs
  >(GET_SEQUENTIAL_USER_RECORDINGS, {
    errorPolicy: 'all',
  });

  const [generateSequenceReport] = useMutation<{ generateSequenceReport: GenericResponse }>(
    GENERATE_SEQUENCE_REPORT,
    {
      refetchQueries: [{ query: GET_SEQUENTIAL_USER_RECORDINGS }],
    },
  );

  const [analyzeSequence] = useMutation<{ analyzeSequence: GenericResponse }>(ANALYZE_SEQUENCE, {
    refetchQueries: [{ query: GET_SEQUENTIAL_USER_RECORDINGS }],
  });

  const [downloadSequence] = useMutation<{ downloadSequence: GenericResponse }>(DOWNLOAD_SEQUENCE);

  if (loading || subscriptionLoading) {
    return <LoadingSpinner />;
  }

  if (error)
    return (
      <div>
        {error.graphQLErrors.map(({ message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i}>{message}</span>
        ))}
      </div>
    );

  if (!data) return <div>Unknown error happened. No data received.</div>;

  const { sequences } = data.getSequentialUserRecordings;

  if (!sequences || sequences.length === 0) {
    return <div>No recordings found for provided sequence</div>;
  }

  const sortedSequences = sequences.slice().sort((a, b) => {
    const createdAtA = new Date(a.startedAt).getTime();
    const createdAtB = new Date(b.startedAt).getTime();

    return createdAtB - createdAtA;
  });

  const isUserSubscribed = subscriptionData?.isSubscribed;

  const handleAnalyzeAndDownload = (sequence: Sequence) => async () => {
    if (!isUserSubscribed) {
      setOpen(true);
      setSendMessage('You need to be subscribed to download PDF report');

      return;
    }

    try {
      const result = await generateSequenceReport({
        variables: { sequenceId: sequence.id, hiddenSections },
      });

      if (result.data?.generateSequenceReport.error) {
        setSendMessage('Failed to send PDF report');
        setIsError(true);
      }

      const message = sequence.analysisId
        ? 'Your ECG has been analyzed, and the report will be sent to your email shortly.'
        : 'ECG analysis in progress; your report will be emailed once ready – this may take up to a few hours. Thank you for your patience!';

      setSendMessage(message);
      setOpen(true);
    } catch (err) {
      setSendMessage('Failed to send PDF report');
      setIsError(true);
      setOpen(true);
    }
  };

  const handleAnalyzeSequenceClick = (sequenceId: string) => async () => {
    try {
      const result = await analyzeSequence({
        variables: { sequenceId },
      });

      if (result.data?.analyzeSequence.error) {
        setSendMessage('Failed to analyze sequence');
        setIsError(true);
        setOpen(true);
      }
    } catch (err) {
      setSendMessage('Failed to analyze sequence');
      setIsError(true);
      setOpen(true);
    }
  };

  const handleDownloadClick = (sequenceId: string) => () => {
    localStorage.setItem('isDownloadRequest', 'true');
    window.open(`/report/${sequenceId}`, '_blank');
  };

  const handleCloseAlert = () => {
    setOpen(false);
    setSendMessage('');
    setIsError(false);
  };

  const handleDownloadSequence = async (sequenceId: string) => {
    try {
      const result = await downloadSequence({
        variables: { sequenceId },
      });

      if (result.data?.downloadSequence.error) {
        setSendMessage(result.data?.downloadSequence.error || 'Failed to download sequence files');
        setIsError(true);
      } else {
        setSendMessage('Downloading sequence files');
      }

      setOpen(true);
    } catch (downloadError: any) {
      setSendMessage(`Failed to download sequence files: ${downloadError?.message}`);
      setIsError(true);
    }
  };

  const handleSequenceClick = (sequence: Sequence) => {
    navigate(`/recording-sequences/${sequence.id}`);
  };

  const handleEditClick = (sequence: Sequence) => {
    const currentPath = window.location.pathname;
    const isEditPath = currentPath.includes('/edit');

    if (isEditPath) {
      navigate(`/recording-sequences/${sequence.id}`);
    } else {
      navigate(`/recording-sequences/${sequence.id}/edit`);
    }
  };

  const hasRequiredRoleToEditSequence = [Role.Clinic, Role.Doctor, Role.Admin].includes(
    user?.role as Role,
  );

  return (
    <>
      <List
        component="nav"
        aria-label="secondary mailbox folders"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Recording Sequences
          </ListSubheader>
        }
        dense
      >
        {sortedSequences.map((sequence) => {
          const durationMs = sequence.endedAt - sequence.startedAt;

          return (
            <ListItem
              key={sequence.id}
              selected={sequence.id === selectedSequenceId}
              button
              onClick={() => handleSequenceClick(sequence)}
            >
              <ListItemText
                primary={getSequenceDuration({
                  from: sequence.startedAt,
                  to: sequence.endedAt,
                  seconds: durationMs / 1000,
                })}
              />
              {sequence.status === SequenceStatus.Analyzing ? (
                <Tooltip title="ECG analysis in progress" placement="top">
                  <RotateLeft />
                </Tooltip>
              ) : (
                <ListItemSecondaryAction>
                  {hasRequiredRoleToEditSequence && (
                    <>
                      {selectedSequenceId === sequence.id && (
                        <Tooltip title="Edit Sequence" placement="top">
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            disabled={!isUserSubscribed}
                            onClick={() => handleEditClick(sequence)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {selectedSequenceId === sequence.id && showDownloadSequenceButton && (
                        <Tooltip title="Download Sequence Files" placement="top">
                          <IconButton
                            edge="end"
                            aria-label="download-sequence"
                            disabled={!isUserSubscribed}
                            onClick={() => handleDownloadSequence(sequence.id)}
                          >
                            <GetAppIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Aanalyze ECG" placement="top">
                        <IconButton
                          edge="end"
                          aria-label="analyze"
                          disabled={!isUserSubscribed}
                          onClick={handleAnalyzeSequenceClick(sequence.id)}
                        >
                          <AutorenewIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Download ECG report" placement="top">
                    <IconButton
                      edge="end"
                      aria-label="download"
                      disabled={!isUserSubscribed || !sequence.analysisId}
                      onClick={handleDownloadClick(sequence.id)}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Analyze ECG and send report to email" placement="top">
                    <IconButton
                      edge="end"
                      aria-label="analyze"
                      disabled={!isUserSubscribed}
                      onClick={handleAnalyzeAndDownload(sequence)}
                    >
                      <AssessmentIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleCloseAlert}>
        <Alert
          elevation={6}
          variant="filled"
          severity={isError ? 'error' : 'success'}
          onClose={handleCloseAlert}
        >
          {sendMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
