import { gql } from '@apollo/client';

export const UPDATE_POINT_COMMENT = gql`
  mutation Mutation($input: UpdatePointCommentInput!) {
    updatePointComment(input: $input) {
      success
      error
      pointComment {
        id
        sampleIndex
        comment
        recordingId
      }
    }
  }
`;
