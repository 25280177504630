/* eslint-disable react/prop-types */
import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';

const STEP = 1;

// Copy of TwoThumbs with `draggableTrack` prop added
export const TwoThumbsDraggableTrack = ({ limits, onChange, values }) => {
  const MIN = limits[0];
  const MAX = limits[1];

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        renderTrack={({ props, children }) => (
          <div
            role="presentation"
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
            }}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#ccc', '#548BF4', '#ccc'],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={{
              ...props.style,
              height: '42px',
              width: '10px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}
          >
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC',
              }}
            />
          </div>
        )}
        draggableTrack
        onChange={(newValues) => {
          onChange(newValues);
        }}
      />
    </div>
  );
};
