import { Recording, HealthIndication } from 'types/schema.type';

export function mapHealthIndicationsToRecordings(
  recordings: Recording[],
  healthIndications: HealthIndication[],
): { [recordingId: string]: HealthIndication[] } {
  return healthIndications.reduce<{ [recordingId: string]: HealthIndication[] }>(
    (result, indication) => {
      const assignableRecording = recordings.find(
        (recording) =>
          recording.recordingStartedAt &&
          recording.recordingEndedAt &&
          indication.recordedAt > recording.recordingStartedAt &&
          indication.recordedAt < recording.recordingEndedAt,
      );

      if (!assignableRecording) {
        return result;
      }

      if (!result[assignableRecording.id]) {
        // eslint-disable-next-line no-param-reassign
        result[assignableRecording.id] = [];
      }

      result[assignableRecording.id].push(indication);

      return result;
    },
    {},
  );
}
