import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { gql, useMutation } from '@apollo/client';

const UPDATE_USER = gql`
  mutation UpdateUserMutation(
    $updateUserEmail: String
    $updateUserName: String
    $updateUserSurname: String
    $updateUserSex: Sex
    $updateUserDateOfBirth: String
    $updateUserWeight: Int
    $updateUserHeight: Int
  ) {
    updateUser(
      email: $updateUserEmail
      name: $updateUserName
      surname: $updateUserSurname
      sex: $updateUserSex
      dateOfBirth: $updateUserDateOfBirth
      weight: $updateUserWeight
      height: $updateUserHeight
    ) {
      success
      error
    }
  }
`;

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  surname: yup.string().required('Last name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  sex: yup.string().oneOf(['MALE', 'FEMALE']),
  age: yup.number().positive().integer().min(1).max(120),
  weight: yup.number().positive().integer().min(3).max(400),
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 400, // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface UpdateValues {
  name: string;
  surname: string;
  email: string;
  sex: string;
  dateOfBirth: string;
  weight: number;
  height: number;
}

export function UpdateUserDetailsForm({
  user,
  refetchUser,
}: {
  user: any;
  refetchUser: any;
}) {
  const classes = useStyles();

  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER);

  const formik = useFormik<UpdateValues>({
    initialValues: {
      name: user.name ?? '',
      surname: user.surname ?? '',
      email: user.email ?? '',
      sex: user.sex,
      dateOfBirth: user.dateOfBirth,
      weight: user.weight,
      height: user.height,
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      if (error) setError('');

      const validValues = Object.keys(values).reduce<Partial<UpdateValues>>((acc, key) => {
        if (values[key] === user[key]) return acc;

        acc[key] = values[key];

        return acc;
      }, {});

      if (!Object.keys(validValues).length) {
        actions.resetForm();
      }

      const { email, name, surname, sex, dateOfBirth, weight, height } = values;

      try {
        const res = await updateUser({
          variables: {
            updateUserEmail: email,
            updateUserName: name,
            updateUserSurname: surname,
            updateUserSex: sex,
            updateUserDateOfBirth: dateOfBirth,
            updateUserWeight: weight,
            updateUserHeight: height || undefined,
          },
        });
        const { success, error: updateError } = res?.data?.updateUser;

        if (!success) throw updateError;

        // Refresh token to get newest user info
        await refetchUser();
      } catch (err: any) {
        setError(err || 'Error occurred');
        actions.resetForm();
      }

      setOpen(true);
      setEditing(false);
    },
  });

  const handleClose: any = (event, reason) => {
    if (reason === 'clickaway') return;
    setError('');
    setOpen(false);
  };

  const handleChange = (e) => {
    setEditing(true);
    if (error) setError('');
    formik.handleChange(e);
  };

  const sexOptions = [
    { value: '', display: 'None' },
    { value: 'MALE', display: 'Male' },
    { value: 'FEMALE', display: 'Female' },
    { value: 'DIVERSE', display: 'Diverse' },
  ];

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Account information
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid spacing={2} container>
            {['name', 'surname', 'email'].map((field) => (
              <Grid xs={12} key={field} item>
                <TextField
                  name={field}
                  autoComplete={field}
                  variant="outlined"
                  id={field}
                  label={capitalize(field)}
                  value={formik.values[field]}
                  error={formik.touched[field] && Boolean(formik.errors[field])}
                  helperText={formik.touched[field] && formik.errors[field]}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            ))}
            <Grid xs={12} item>
              <TextField
                id="sex"
                name="sex"
                label="Sex"
                variant="outlined"
                value={formik.values.sex}
                error={formik.touched.sex && Boolean(formik.errors.sex)}
                helperText={formik.touched.sex && formik.errors.sex}
                fullWidth
                select
                onChange={handleChange}
              >
                {sexOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.display}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} key="dateOfBirth" item>
              <TextField
                id="dateOfBirth"
                name="dateOfBirth"
                label={capitalize('dateOfBirth')}
                autoComplete="dateOfBirth"
                type="date"
                variant="outlined"
                value={formik.values.dateOfBirth}
                error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            {['weight', 'height'].map((field) => (
              <Grid xs={12} key={field} item>
                <TextField
                  id={field}
                  name={field}
                  label={capitalize(field)}
                  autoComplete={field}
                  type="number"
                  variant="outlined"
                  value={formik.values[field]}
                  error={formik.touched[field] && Boolean(formik.errors[field])}
                  helperText={formik.touched[field] && formik.errors[field]}
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            ))}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!editing}
              fullWidth
            >
              Save
            </Button>
          </Grid>
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          elevation={6}
          variant="filled"
          severity={error ? 'error' : 'success'}
          onClose={handleClose}
        >
          {error || 'Profile updated successfully!'}
        </Alert>
      </Snackbar>
    </div>
  );
}
