import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      name
      surname
      role
      dateOfBirth
      sex
      email
      weight
      height
    }
  }
`;
