/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider, AppState, Auth0ProviderOptions } from '@auth0/auth0-react';
import { BrowserRouter, useNavigate } from 'react-router-dom';

import '@fontsource/roboto';
import './index.css';

import './i18n';

import { getSharedTokenFromURL } from 'utilities/getSharedTokenFromURL';
import App from './App';
import { AuthorizedApolloProvider } from './AuthorizedApolloProvider';

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  // Check for shared token in the URL
  const sharedToken = getSharedTokenFromURL();
  
  // If a shared token is present, simply render the children without setting up Auth0
  if (sharedToken) {
    window.localStorage.setItem('sharedToken', sharedToken);

    return <>{children}</>;
  }

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback
      domain={window.REACT_PUBLIC_SERVER_ENV.AUTH0_DOMAIN ?? process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={
        window.REACT_PUBLIC_SERVER_ENV.AUTH0_CLIENT_ID ?? process.env.REACT_APP_AUTH0_CLIENT_ID
      }
      audience={
        window.REACT_PUBLIC_SERVER_ENV.AUTH0_AUDIENCE ?? process.env.REACT_APP_AUTH0_AUDIENCE
      }
      redirectUri={window.location.origin}
    >
      <AuthorizedApolloProvider>
        <App />
      </AuthorizedApolloProvider>
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>,
  document.getElementById('root'),
);
