import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { calculatePercentage } from './utils/calculatePercentage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 500,
  },
  cell: {
    border: 'none',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

function createData(name: string, count: string, percentages: string) {
  return { name, count, percentages };
}

function makeRows(
  {
    avgHeartrate,
    beatCount,
    minHeartrate,
    maxHeartrate,
    supraventricularBeats,
    ventricularBeats,
    normalBeats,
    bradycardiaBeats,
    tachycardiaBeats,
    averageRatesBeats,
    qtcIntervalMin,
    qtcIntervalMax,
    qtcIntervalAverage,
    qtcIntervalsAbove05Percentage,
    pauseTotalCount,
    pauseLongestEpisodeSeconds,
  },
  t,
) {
  return [
    createData(t('summary.rows.minMaxHeartrate'), `${minHeartrate || 0}/${maxHeartrate || 0}`, ''),
    createData(t('summary.rows.averageHeartrate'), avgHeartrate || 0, ''),
    createData(t('summary.rows.totalBeats'), beatCount || 0, '100 %'),
    createData(
      t('summary.rows.bradycardiaBeats', { bpm: t('common.below50bpm') }),
      '',
      calculatePercentage(bradycardiaBeats, averageRatesBeats),
    ),
    createData(
      t('summary.rows.tachycardiaBeats', { bpm: t('common.above100bpm') }),
      '',
      calculatePercentage(tachycardiaBeats, averageRatesBeats),
    ),
    createData(
      t('summary.rows.supraventricularBeats'),
      supraventricularBeats,
      calculatePercentage(supraventricularBeats, beatCount),
    ),
    createData(
      t('summary.rows.ventricularBeats'),
      ventricularBeats,
      calculatePercentage(ventricularBeats, beatCount),
    ),
    createData(t('summary.rows.normalBeats'), normalBeats, ''),
    createData(
      t('summary.rows.qtcIntervalMinMax'),
      `${qtcIntervalMin.toFixed(3)} / ${qtcIntervalMax.toFixed(3)}`,
      '',
    ),
    createData(t('summary.rows.qtcIntervalAverage'), qtcIntervalAverage.toFixed(3), ''),
    createData(
      t('summary.rows.qtcIntervalsAboveThreshold'),
      '',
      `${qtcIntervalsAbove05Percentage.toFixed(2)}%`,
    ),
    createData(t('summary.rows.pauseTotalCount'), pauseTotalCount, ''),
    createData(t('summary.rows.pauseLongestEpisode'), pauseLongestEpisodeSeconds, ''),
  ];
}

type SummeryProps = {
  avgHeartrate: number;
  beatCount: number;
  minHeartrate: number;
  maxHeartrate: number;
  supraventricularBeats: number;
  ventricularBeats: number;
  normalBeats: number;
  bradycardiaBeats: number;
  tachycardiaBeats: number;
  averageRatesBeats: number;
  qtcIntervalMin: number;
  qtcIntervalMax: number;
  qtcIntervalAverage: number;
  qtcIntervalsAbove05Percentage: number;
  pauseTotalCount: number;
  pauseLongestEpisodeSeconds: number;
};

export function Summary(props: { summary: SummeryProps }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        {t('summary.title')}
      </Typography>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('summary.table.name')}</TableCell>
            <TableCell align="right">{t('summary.table.count')}</TableCell>
            <TableCell align="right">{t('common.percentages')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {makeRows(props.summary, t).map((row) => (
            <TableRow key={row.name}>
              <TableCell className={classes.cell}>{row.name}</TableCell>
              <TableCell className={classes.cell} align="right">
                {row.count}
              </TableCell>
              <TableCell className={classes.cell} align="right">
                {row.percentages}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}
