import { useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { Role } from 'types/schema.type';
import { useUser } from 'actions/useUser';
import { BaseUserLayout } from './base-user.layout';
import { DoctorUserLayout } from './doctor-user.layout';
import { ClinicUserLayout } from './clinic-user.layout';
import { AdminUserLayout } from './admin-user.layout';
import { PatientManagementLayout } from './patient-management.layout';

export function Header() {
  const { pathname } = useLocation();
  const { user, loading } = useUser();

  if (loading || !user) {
    return null;
  }

  /** Remove header if pdf export view */
  if (pathname.includes('recording-pdf-export') || pathname.includes('/report/')) {
    return null;
  }

  const hasReadPatientsPermission = user.permissions?.includes('manage:auth0_patients');

  const renderButtonLayout = () => {
    if (user.role === Role.User) return <BaseUserLayout />;
    if (user.role === Role.Doctor) return <DoctorUserLayout />;
    if (user.role === Role.Clinic && hasReadPatientsPermission) return <PatientManagementLayout />;
    if (user.role === Role.Clinic) return <ClinicUserLayout />;
    if (user.role === Role.Admin) return <AdminUserLayout />;

    return null;
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>{renderButtonLayout()}</Toolbar>
      </AppBar>
    </>
  );
}
