import React from 'react';
import { useMutation } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

import { AnnotationValue, RecordingJson } from 'types/schema.type';
import { ExcerptChart } from '../charts/ExcerptChart';
import { getTime } from '../utils/getTime';
import { getMutationForTypeName } from './getMutationName';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 550,
  },
  cell: {
    border: 'none',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  chart: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export enum EctopyType {
  VentricularDuplet = 'Ventricular Duplet',
  VentricularTriplet = 'Ventricular Triplet',
  VentricularRun = 'Ventricular Run',
  SupraVentricularDuplet = 'Supraventricular Duplet',
  SupraVentricularTriplet = 'Supraventricular Triplet',
  SupraVentricularRun = 'Supraventricular Run',
}

type Beat = {
  id: string;
  sequenceStartedAt: number;
  annotationValue: AnnotationValue;
  sampleIndexes: number[];
  excerpt: RecordingJson;
  isValid: boolean;
  __typename: string;
};

type EctopyDataWithChartsProps = {
  title: EctopyType;
  ectopyItems: Beat[];
  beats: Beat[];
  sequenceStartedAt: number;
};

export function EctopyDataWithCharts(props: EctopyDataWithChartsProps) {
  const classes = useStyles();
  const { title, ectopyItems, beats, sequenceStartedAt } = props;

  const [updateMutation, { error }] = useMutation(
    getMutationForTypeName(ectopyItems[0]?.__typename),
  );

  if (error) {
    console.error('Mutation error:', error);
  }

  const handleUpdate = async (ectopy: Beat) => {
    const { id, isValid } = ectopy;

    if (!updateMutation) {
      console.error('No mutation available for this item type:', ectopy.__typename);

      return;
    }

    await updateMutation({
      variables: { id, isValid: !isValid },
      optimisticResponse: {
        [`update${ectopy.__typename}`]: {
          __typename: 'GenericResponse',
          error: null,
          success: true,
        },
      },
      update(cache) {
        cache.modify({
          id: cache.identify(ectopy),
          fields: {
            isValid(existingIsValid) {
              return !existingIsValid;
            },
          },
        });
      },
    });
  };

  if (ectopyItems.length === 0 && beats.length === 0) {
    return null;
  }

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ectopyItems.map((item) => (
            <React.Fragment key={item.id}>
              <TableRow>
                <TableCell className={classes.cell}>
                  {getTime(sequenceStartedAt ?? 0, item.sampleIndexes[0])}
                </TableCell>
                <TableCell className={classes.cell}>
                  <IconButton edge="end" aria-label="" onClick={() => handleUpdate(item)}>
                    {item.isValid ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
          {beats.map((beat) => (
            <TableRow key={beat.sampleIndexes[0]}>
              <TableCell className={classes.cell} colSpan={2}>
                <div className={classes.chart}>
                  <ExcerptChart
                    title={title}
                    sequenceStartedAt={beat.sequenceStartedAt}
                    sampleIndexes={beat.sampleIndexes}
                    annotationValue={beat.annotationValue}
                    signal={beat.excerpt.ch1}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}
