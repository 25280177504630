import { gql } from '@apollo/client';

export const GET_RECORDINGS_EXTERNAL_ANNOTATING = gql`
  query GetRecordingExternalAnnotating {
    getRecordingsForExternalAnnotating {
      id
      channelCount
      fileName
      recordingStartedAt
      uploadedAt
      metadata {
        createdAt
        updatedAt
      }
      recordingIsUploaded
      comment
      analysis {
        rpeaksCount
        success
        error
      }
      flags
      externalAnnotationMetadata {
        startedAt
        completedAt
        startedBy
        completedBy
      }
    }
  }
`;
