/* eslint-disable no-param-reassign */
import $ from 'jquery';
import { buildIntervalIndicator } from './buildIntervalIndicator';

function sortAsc(a, b) {
  return a - b;
}

/**
 * Build ruler interval between peaks
 * Do not clone "ruler" object, it needs to hace a reference to parent component
 *
 * @param {Object} ruler Ruler object { active: Boolean, x: [Number], y: [Number], index: [Number] }
 * @param {Number} samplePosX Sample position in pixels on canvas
 * @param {Numer} samplePosY Sample position in pixels on canvas
 * @param {Number} sample Sample index
 * @param {Object} u Uplot object
 * @returns
 */
export function buildIntervalRuler(ruler, samplePosX, samplePosY, sample, u) {
  if (!ruler.active) {
    ruler.active = true;
    ruler.x = [samplePosX];
    ruler.y = [samplePosY];
    ruler.index = [sample];

    return;
  }

  if (ruler.active) {
    ruler.x.push(samplePosX);
    ruler.y.push(samplePosY);
    ruler.index.push(sample);

    // Sort ascending, because user might select a further annotation first with a higher value
    ruler.x.sort(sortAsc);
    ruler.y.sort(sortAsc);

    $(u.root.querySelector('.u-over')).append(
      buildIntervalIndicator({
        fromX: ruler.x[0],
        fromY: ruler.y[0],
        toX: ruler.x[1],
        toY: ruler.y[1],
        content: `${Math.abs(ruler.index[1] - ruler.index[0]) / 200}s`,
        isFromAnalysis: true,
      }),
    );

    // Set ruler to default state;
    ruler.active = false;
    ruler.x = [];
    ruler.y = [];
    ruler.index = [];
  }
}
