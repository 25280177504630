/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import { UPDATE_RECORDING } from '../mutations';
import './FlagSelector.css';

const useStyles = makeStyles(() => ({
  formControl: {
    width: 120,
  },
  select: {
    fontSize: '14px',
  },
}));

export const FlagSelector = ({ recordingId, flags: selectedFlags, flagOptions, disabled = false }) => {
  const [updateRecording] = useMutation(UPDATE_RECORDING);

  const classes = useStyles();

  const handleRenderValue = (selected) => {
    const { length } = selected;

    return length > 1 ? `${length} selected` : selected;
  };

  const handleChange = (e) => {
    const flags = e.target.value;

    updateRecording({
      variables: {
        updateRecordingId: recordingId,
        updateRecordingInput: { flags },
      },
    });
  };

  if (!flagOptions) {
    return null;
  }

  return (
    <FormControl className={classes.formControl}>
      <Select
        className={classes.select}
        labelId="mutiple-checkbox-label"
        id="mutiple-checkbox"
        value={selectedFlags}
        input={<Input />}
        renderValue={handleRenderValue}
        disabled={disabled}
        multiple
        onChange={handleChange}
      >
        {flagOptions.map((flag) => (
          <MenuItem key={flag} value={flag} disableGutters dense>
            <Checkbox size="small" checked={selectedFlags.indexOf(flag) > -1} />
            <ListItemText primary={flag} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FlagSelector.displayName = 'FlagSelector';
