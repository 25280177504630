import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Role } from 'types/schema.type';
import { useGetSharedTokens } from './actions/useGetSharedTokens';
import { useCreateSharedToken } from './actions/useCreateSharedToken';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export function SharedLinks({ user }: { user: any }) {
  const classes = useStyles();

  const { tokens, loading } = useGetSharedTokens();
  const { createSharedToken } = useCreateSharedToken();

  if (user.role === Role.Clinic) {
    return null;
  }

  if (loading || !tokens) {
    return <div>Loading...</div>;
  }

  const baseURL = window.location.origin;

  const shouldBeDisabled = user.role === Role.Admin || loading || tokens.length > 0;

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Button
          variant="contained"
          color="primary"
          disabled={shouldBeDisabled}
          fullWidth
          onClick={() => {
            createSharedToken();
          }}
        >
          Generate a shared link
        </Button>

        <Typography>Shared links:</Typography>

        {!tokens.length && <Typography>No shared links yet</Typography>}

        {tokens.map((token) => (
          <Typography key={token.id}>
            {baseURL}?sharedToken={token.token}
          </Typography>
        ))}
      </div>
    </div>
  );
}
