import $ from 'jquery';
import { Interval } from 'utilities/types';

export const buildNoiseInterval = ({ fromX, toX, content, annotationId, onClick }: Interval) => {
  const height = 130;
  const top = 0;

  const isPartialLeft = fromX < 0;
  const isPartialRight = toX > 1100;

  const left = isPartialLeft ? 0 : fromX;
  const right = isPartialRight ? 1100 : toX;

  const width = right - left;

  const container = $('<div></div>').css({
    top,
    left,
    width,
    height,
    position: 'absolute',
  });

  const ruler = $(`
    <div class="ruler-noise" data-id=${annotationId}>
        <div class="bar bar-left"></div>
        <div class="content">
            ${content}
            <div class="delete">Delete</div>
        </div>
        <div class="bar bar-right"></div>
  </div>`);

  $(ruler).find('.content').on('click', onClick);

  container.append(ruler);

  container.addClass('uplot-additional-element');

  return container;
};
