import { gql } from '@apollo/client';

export const CREATE_AFIB = gql`
  mutation CreateAFibMutation($createAFibInput: CreateIntervalInput!) {
    createAFib(input: $createAFibInput) {
      success
      error
      afib {
        id
        startIndex
        endIndex
        annotationValue
      }
    }
  }
`;
