export const getSamplePosition = ({ u, sampleIndex, data }) => {
  // Value of the current sample
  const sampleValue = data[1][sampleIndex];
  // X position of the current sample
  const samplePosX = Math.round(u.valToPos(sampleIndex, 'x', false));
  // Y position of the current sample
  const samplePosY = Math.round(u.valToPos(sampleValue, 'y', false));

  return [samplePosX, samplePosY];
};
