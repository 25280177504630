import React from 'react';
import { gql, useMutation } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { BradycardiaEpisode, GenericResponse } from 'types/schema.type';
import { BradycardiaChart } from './BradycardiaChart';
import { getTime } from '../utils/getTime';
import { formatSeconds } from '../utils/formatSeconds';
import { calculatePercentage } from '../utils/calculatePercentage';
import { SequenceEpisodes } from '../utils/getSequenceEpisodes';

export const TOGGLE_BRADYCARDIA_EPISODE_VALIDATION = gql`
  mutation ToggleBradycardiaEpisodeValidation($input: ToggleBradycardiaEpisodeValidationInput!) {
    toggleBradycardiaEpisodeValidation(input: $input) {
      error
      success
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 700,
  },
  cell: {
    border: 'none',
  },
  chart: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  cellSummary: {
    borderBottom: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
}));

type BradycardiaEpisodesWithChartsProps = {
  bradycardiaEpisodes: SequenceEpisodes['bradycardiaEpisodes'];
  rpeaks: number[];
  sequenceDurationMs: number;
  edit?: boolean;
  analysisId?: string;
};

export function BradycardiaTableWithCharts(props: BradycardiaEpisodesWithChartsProps) {
  const classes = useStyles();
  const { bradycardiaEpisodes, rpeaks, sequenceDurationMs, edit, analysisId } = props;

  const [toggleBradycardiaEpisodeValidation] = useMutation<{
    toggleBradycardiaEpisodeValidation: GenericResponse;
  }>(TOGGLE_BRADYCARDIA_EPISODE_VALIDATION);

  const episodesToShow = edit
    ? bradycardiaEpisodes
    : bradycardiaEpisodes?.filter((ep) => ep.isValid);

  const getTotalDuration = () => {
    const time = episodesToShow?.reduce<number>((acc, ep) => acc + (ep?.duration_s ?? 0), 0);
    const percentage = calculatePercentage(time, sequenceDurationMs / 1000);
    const formattedTime = formatSeconds(time);

    return `${formattedTime} (${percentage})`;
  };

  const handleToggleBradycardiaEpisodeValidation = async (
    startIndex?: number,
    analysisIdRef?: string,
  ) => {
    if (!analysisIdRef || !startIndex) return;

    await toggleBradycardiaEpisodeValidation({
      variables: {
        input: {
          analysisId: analysisIdRef,
          start: startIndex,
        },
      },
      update(cache) {
        cache.modify({
          id: cache.identify({ __typename: 'Analysis', id: analysisIdRef }),
          fields: {
            bradycardiaEpisodes(existingEpisodes = []) {
              const result = existingEpisodes.map((episode: BradycardiaEpisode) => {
                if (episode.start === startIndex) {
                  return {
                    ...episode,
                    isValid: !episode.isValid,
                  };
                }

                return episode;
              });

              return result;
            },
          },
        });
      },
    });
  };

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Bradycardia Episodes with Charts
      </Typography>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Min Beat</TableCell>
            <TableCell>Avg Beat</TableCell>
            {edit && <TableCell>Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {episodesToShow?.map((ep) => (
            <React.Fragment key={ep.start}>
              <TableRow>
                <TableCell className={classes.cell}>
                  {getTime(ep.recordingStartedAt ?? 0, ep.start ?? 0)}
                </TableCell>
                <TableCell className={classes.cell}>
                  {getTime(ep.recordingStartedAt ?? 0, ep.end ?? 0)}
                </TableCell>
                <TableCell className={classes.cell}>{formatSeconds(ep.duration_s ?? 0)}</TableCell>
                <TableCell className={classes.cell}>{ep.minBeat}</TableCell>
                <TableCell className={classes.cell}>{ep.avgBeat}</TableCell>
                {edit && (
                  <TableCell className={classes.cell}>
                    <IconButton
                      edge="end"
                      aria-label=""
                      onClick={() => handleToggleBradycardiaEpisodeValidation(ep.start, analysisId)}
                    >
                      {ep.isValid ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
              {ep.isValid && ep?.excerpt?.ch1.length > 0 && (
                <TableRow>
                  <TableCell className={classes.cell} colSpan={edit ? 6 : 5}>
                    <div className={classes.chart}>
                      <BradycardiaChart
                        recordingStartedAt={ep.recordingStartedAt ?? 0}
                        startIndex={ep.start ?? 0}
                        endIndex={ep.end ?? 0}
                        smallestAverageIndex={(ep as any).lowestAvgBeatIndex}
                        signal={ep.excerpt.ch1}
                        rpeaks={rpeaks}
                        showTitle={false}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell className={classes.cell} colSpan={2} />
            <TableCell className={classes.cellSummary} colSpan={2}>
              Total
            </TableCell>
            <TableCell className={classes.cellSummary}>{getTotalDuration()}</TableCell>
            {edit && <TableCell className={classes.cellSummary} />}
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
}
