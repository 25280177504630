import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 300,
  },
  root: {
    textAlign: 'center',
  },
}));

function createData(name, count) {
  return { name, count };
}

const makeRows = ({
  avgHeartrate,
  beatCount,
  bradycardiaEpisodes,
  pauseEpisodes,
  minHeartrate,
  maxHeartrate,
}) => [
  createData('Bradycardia episodes', bradycardiaEpisodes?.length ?? 0),
  createData('Pause episodes', pauseEpisodes?.length ?? 0),
  createData('Min/max heartrate', `${minHeartrate || 0}/${maxHeartrate || 0}`),
  createData('Average heartrate', avgHeartrate || 0),
  createData('Beats', beatCount || 0),
];

// eslint-disable-next-line react/prop-types
export function PulseSummary(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Pulse Summary
      </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {makeRows(props).map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
