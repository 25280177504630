import { PauseEpisode } from 'types/schema.type';

export function getSummaryData({
  beatCount,
  averageRates,
  ectopicBeats,
  averageQtcIntervals,
  pauseEpisodes,
}: {
  beatCount: number;
  averageRates: (null | number)[];
  ectopicBeats: {
    supraventricular: {
      annotationValues: number[];
    };
    ventricular: {
      annotationValues: number[];
    };
    unrecognized: {
      annotationValues: number[];
    };
  };
  averageQtcIntervals: (null | number)[];
  pauseEpisodes: PauseEpisode[];
}) {
  const rates = averageRates.filter((rate) => rate !== null) as number[];
  const avgHeartrate = Math.round(rates.reduce((a, b) => a + b, 0) / rates.length);
  const minHeartrateUnsafe = Math.min(...rates);
  const maxHeartrateUnsafe = Math.max(...rates);
  const minHeartrate = Number.isFinite(minHeartrateUnsafe) ? minHeartrateUnsafe : 0;
  const maxHeartrate = Number.isFinite(maxHeartrateUnsafe) ? maxHeartrateUnsafe : 0;

  const filteredAverageRates = averageRates.filter((rate): rate is number => rate !== null);
  const ratesLowerThan50 = filteredAverageRates.filter((rate) => rate < 50);
  const ratesHigherThan100 = filteredAverageRates.filter((rate) => rate > 100);

  // QTc interval values
  const filteredAverageQtcIntervals = averageQtcIntervals.filter(
    (interval): interval is number => interval != null && !Number.isNaN(interval),
  );
  const qtcIntervalMin = Math.min(...filteredAverageQtcIntervals);
  const qtcIntervalMax = Math.max(...filteredAverageQtcIntervals);

  const qtcIntervalsAbove05 = filteredAverageQtcIntervals.filter((interval) => interval > 0.5);
  const qtcIntervalsAbove05Percentage =
    qtcIntervalsAbove05.length / filteredAverageQtcIntervals.length;
  const qtcIntervalAverage =
    filteredAverageQtcIntervals.reduce((a, b) => a + b, 0) / filteredAverageQtcIntervals.length;

  // Pauses
  const validPauseEpisodes = pauseEpisodes.filter((ep) => ep.isValid);
  const pauseTotalCount = validPauseEpisodes.length;
  const pauseLongestEpisodeUnsafe = Math.max(
    ...validPauseEpisodes.map((pause) =>
      Math.round(((pause.endIndex || 0) - (pause.startIndex || 0)) / 200),
    ),
  );
  const pauseLongestEpisodeSeconds = Number.isFinite(pauseLongestEpisodeUnsafe)
    ? pauseLongestEpisodeUnsafe
    : 0;

  const normalBeats =
    beatCount -
    ectopicBeats.supraventricular.annotationValues.length -
    ectopicBeats.ventricular.annotationValues.length -
    ectopicBeats.unrecognized.annotationValues.length;

  return {
    avgHeartrate,
    beatCount,
    minHeartrate,
    maxHeartrate,
    supraventricularBeats: ectopicBeats.supraventricular.annotationValues.length,
    ventricularBeats: ectopicBeats.ventricular.annotationValues.length,
    unrecognizedBeats: ectopicBeats.unrecognized.annotationValues.length,
    normalBeats,
    bradycardiaBeats: ratesLowerThan50.length,
    tachycardiaBeats: ratesHigherThan100.length,
    averageRatesBeats: filteredAverageRates.length,
    qtcIntervalMin: Number.isFinite(qtcIntervalMin) ? qtcIntervalMin : 0,
    qtcIntervalMax: Number.isFinite(qtcIntervalMax) ? qtcIntervalMax : 0,
    qtcIntervalAverage,
    qtcIntervalsAbove05Percentage,
    pauseTotalCount,
    pauseLongestEpisodeSeconds,
  };
}
