import React from 'react';
import { useMutation } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { GenericResponse } from 'types/schema.type';
import { PauseEpisodeChart } from './PauseEpisodeChart';
import { getTime } from '../utils/getTime';
import { formatTimeDuration } from '../utils/formatTime';
import { SequenceEpisodes } from '../utils/getSequenceEpisodes';
import { TOGGLE_PAUSE_EPISODE_VALIDATION } from './PauseEpisodesTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 550,
  },
  cell: {
    border: 'none',
  },
  chart: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

type CombinedComponentProps = {
  pauseEpisodes: SequenceEpisodes['pauseEpisodes'];
  rpeaks: number[];
  analysisId?: string;
  edit?: boolean;
};

export function PauseEpisodesWithCharts(props: CombinedComponentProps) {
  const classes = useStyles();
  const { pauseEpisodes, rpeaks, analysisId, edit } = props;

  const [togglePauseEpisodeValidation] = useMutation<{
    togglePauseEpisodeValidation: GenericResponse;
  }>(TOGGLE_PAUSE_EPISODE_VALIDATION);

  const handleTogglePauseEpisodeValidation = async (
    onsetSampleno?: number,
    analysisIdRef?: string,
  ) => {
    if (!analysisIdRef || !onsetSampleno) return;

    await togglePauseEpisodeValidation({
      variables: {
        input: {
          analysisId: analysisIdRef,
          onsetSampleno,
        },
      },
      update(cache) {
        cache.modify({
          id: cache.identify({ __typename: 'Analysis', id: analysisIdRef }),
          fields: {
            pauseEpisodes(existingEpisodes = []) {
              const result = existingEpisodes.map((episode) => {
                if (episode.endIndex === onsetSampleno) {
                  return {
                    ...episode,
                    isValid: !episode.isValid,
                  };
                }

                return episode;
              });

              return result;
            },
          },
        });
      },
    });
  };

  const episodesToShow = edit ? pauseEpisodes : pauseEpisodes?.filter((ep) => ep.isValid);

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Pause episodes
      </Typography>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Duration</TableCell>
            {edit && <TableCell>Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {episodesToShow?.map((ep) => {
            const startIndex = ep?.startIndex ?? 0;
            const endIndex = ep?.endIndex ?? 0;
            const duration = formatTimeDuration({
              durationInMilliseconds: (endIndex - startIndex) * 5,
              showSeconds: true,
            });

            return (
              <React.Fragment key={ep.endIndex}>
                <TableRow>
                  <TableCell className={classes.cell}>
                    {getTime(ep.recordingStartedAt ?? 0, startIndex)}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {getTime(ep.recordingStartedAt ?? 0, endIndex)}
                  </TableCell>
                  <TableCell className={classes.cell}>{duration}</TableCell>
                  {edit && (
                    <TableCell className={classes.cell}>
                      <IconButton
                        edge="end"
                        aria-label=""
                        onClick={() => handleTogglePauseEpisodeValidation(endIndex, analysisId)}
                      >
                        {ep.isValid ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
                {ep.isValid && ep?.excerpt?.ch1.length > 0 && (
                  <TableRow>
                    <TableCell className={classes.cell} colSpan={4}>
                      <div className={classes.chart}>
                        <PauseEpisodeChart
                          recordingStartedAt={ep.recordingStartedAt ?? 0}
                          startIndex={startIndex}
                          endIndex={endIndex}
                          signal={ep.excerpt.ch1}
                          rpeaks={rpeaks}
                          showTitle={false}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
}
