/* eslint-disable no-restricted-syntax */
import { AnnotationValue, Sequence } from 'types/schema.type';
import { getUnixTime } from './getUnixTime';
import { groupByHour } from '../histogram/histogramUtils';
import { getSequenceAnalysisAnnotations } from './getSequenceAnnotations';
import { getSequenceAnalysisTachycardia } from './getSequenceTachycardia';

function findInvalidClusters(data) {
  const result = {};

  if (!data?.annotationValues) {
    return result;
  }

  for (const annotation of data.annotationValues) {
    const invalidLocations = new Set();

    if (!annotation.clusters) {
      // eslint-disable-next-line no-continue
      continue;
    }

    for (const cluster of annotation.clusters) {
      if (cluster?.isValid === false) {
        for (const location of cluster.rpeakLocations) {
          invalidLocations.add(location);
        }
      }
    }

    result[annotation.annotationValue] = invalidLocations;
  }

  return result;
}

export function getSequenceBeatSummaryData(sequence: Sequence, averageRates: (null | number)[]) {
  const clusterData = sequence?.analysis?.clusterData ?? [];
  const invalidEctopicBeats = findInvalidClusters(clusterData);

  const ectopicBeats = (sequence?.analysis?.automaticRpeakAnnotations ?? []).reduce<{
    ventricular: { timestamps: number[]; annotationValues: number[] };
    supraventricular: { timestamps: number[]; annotationValues: number[] };
    unrecognized: { annotationValues: number[] };
  }>(
    (acc, val) => {
      if (
        val.annotationValue === AnnotationValue.V &&
        !invalidEctopicBeats[AnnotationValue.V].has(val.sampleIndex)
      ) {
        const timestampInSS = getUnixTime(sequence.startedAt, val.sampleIndex);

        acc.ventricular.timestamps.push(timestampInSS);
        acc.ventricular.annotationValues.push(1);
      }

      if (
        val.annotationValue === AnnotationValue.S &&
        !invalidEctopicBeats[AnnotationValue.S].has(val.sampleIndex)
      ) {
        const timestampInSS = getUnixTime(sequence.startedAt, val.sampleIndex);

        acc.supraventricular.timestamps.push(timestampInSS);
        acc.supraventricular.annotationValues.push(2);
      }

      if (val.annotationValue === AnnotationValue.U) {
        acc.unrecognized.annotationValues.push(3);
      }

      return acc;
    },
    {
      ventricular: { timestamps: [], annotationValues: [] },
      supraventricular: { timestamps: [], annotationValues: [] },
      unrecognized: { annotationValues: [] },
    },
  );

  const sequenceAnnotationsTimestampsInSs = getSequenceAnalysisAnnotations(sequence);

  const {
    ventricularDuplets,
    ventricularTriplets,
    ventricularRuns,
    supraVentricularDuplets,
    supraVentricularTriplets,
    supraVentricularRuns,
  } = getSequenceAnalysisTachycardia(sequence);

  const beatsTableData = groupByHour(sequenceAnnotationsTimestampsInSs, averageRates);

  const ventricularTableData = groupByHour(
    ectopicBeats.ventricular.timestamps,
    ectopicBeats.ventricular.annotationValues,
  );
  const supraventricularTableData = groupByHour(
    ectopicBeats.supraventricular.timestamps,
    ectopicBeats.supraventricular.annotationValues,
  );

  const ventricularDupletsTableData = groupByHour(
    ventricularDuplets.map((v) => v / 1000),
    ventricularDuplets,
  );
  const ventricularTripletsTableData = groupByHour(
    ventricularTriplets.map((v) => v / 1000),
    ventricularTriplets,
  );
  const ventricularRunsTableData = groupByHour(
    ventricularRuns.map((v) => v / 1000),
    ventricularRuns,
  );
  const supraVentricularDupletsTableData = groupByHour(
    supraVentricularDuplets.map((v) => v / 1000),
    supraVentricularDuplets,
  );
  const supraVentricularTripletsTableData = groupByHour(
    supraVentricularTriplets.map((v) => v / 1000),
    supraVentricularTriplets,
  );
  const supraVentricularRunsTableData = groupByHour(
    supraVentricularRuns.map((v) => v / 1000),
    supraVentricularRuns,
  );

  return {
    ectopicBeats,
    beatsTableData,
    ventricularTableData,
    ventricularDupletsTableData,
    ventricularTripletsTableData,
    ventricularRunsTableData,
    supraventricularTableData,
    supraVentricularDupletsTableData,
    supraVentricularTripletsTableData,
    supraVentricularRunsTableData,
  };
}
