import { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { format } from 'date-fns';

import { Patient } from 'types/schema.type';
import { useCreatePatientMutation } from './useCreatePatientMutation';
import { useUpdatePatient } from './useUpdatePatient';

const validationSchema = yup.object({
  name: yup.string().required(),
  surname: yup.string().nullable(),
  email: yup.string().email().required(),
  patientRefId: yup.string().nullable(),
  sex: yup.mixed().oneOf(['MALE', 'FEMALE', 'DIVERSE']).nullable(),
  height: yup.number().positive().integer().min(20).max(250).nullable(),
  weight: yup.number().positive().integer().min(3).max(400).nullable(),
  dateOfBirth: yup.date().nullable(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: 400, // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface UpdateValues {
  name?: string;
  surname?: string;
  email?: string;
  patientRefId?: string;
  sex?: string;
  height?: number;
  weight?: number;
  dateOfBirth?: string | number;
}

const sexOptions = [
  { value: 'MALE', display: 'Male' },
  { value: 'FEMALE', display: 'Female' },
  { value: 'DIVERSE', display: 'Diverse' },
];

type CreatePatientFormProps = {
  patient?: Patient;
  onClose: () => void;
};

export function CreatePatientForm(props: CreatePatientFormProps) {
  const classes = useStyles();

  const [error, setError] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editing, setEditing] = useState(false);

  const { createPatient } = useCreatePatientMutation();
  const { updatePatient } = useUpdatePatient();

  const { onClose, patient } = props;

  const formik = useFormik<UpdateValues>({
    initialValues: {
      name: patient?.name,
      surname: patient?.surname || undefined,
      email: patient?.email,
      patientRefId: patient?.patientRefId || undefined,
      sex: patient?.sex || undefined,
      dateOfBirth: patient?.dateOfBirth || undefined,
      weight: patient?.weight || undefined,
      height: patient?.height || undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (error) setError('');

      const { email, name, surname, patientRefId, sex, dateOfBirth, weight, height } = values;

      const convertedDateOfBirth = dateOfBirth ? new Date(dateOfBirth).toISOString() : undefined;

      try {
        if (patient) {
          const res = await updatePatient({
            variables: {
              patientId: patient.id,
              patientInput: {
                name,
                surname,
                patientRefId,
                dateOfBirth: convertedDateOfBirth,
                sex,
                height,
                weight,
              },
            },
          });

          const { success, error: updateError } = res?.data?.updatePatient;

          if (!success) throw updateError;
        } else {
          const res = await createPatient({
            variables: {
              patientInput: {
                name,
                surname,
                dateOfBirth: convertedDateOfBirth,
                email,
                height,
                sex,
                weight,
                patientRefId,
              },
            },
          });
          const { success, error: updateError } = res?.data?.createPatient;

          if (!success) throw updateError;
        }

        onClose();
      } catch (err: any) {
        setError(err || 'Error occurred');
      }

      setSnackbarOpen(true);
      setEditing(false);
    },
  });

  const handleClose: any = (event, reason) => {
    if (reason === 'clickaway') return;
    setError('');
    setSnackbarOpen(false);
  };

  const handleChange = (e) => {
    setEditing(true);
    if (error) setError('');
    formik.handleChange(e);
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {patient ? 'Edit Patient Details' : 'Create Patient'}
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid spacing={2} container>
            {['name', 'surname', 'email', 'patientRefId'].map((field) => (
              <Grid xs={12} key={field} item>
                <TextField
                  name={field}
                  autoComplete={field}
                  variant="outlined"
                  id={field}
                  label={capitalize(field)}
                  value={formik.values[field]}
                  error={formik.touched[field] && Boolean(formik.errors[field])}
                  helperText={formik.touched[field] && formik.errors[field]}
                  required={field === 'name' || field === 'email'}
                  disabled={field === 'email' && !!patient}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            ))}
            <Grid xs={12} item>
              <TextField
                id="sex"
                name="sex"
                label="Sex"
                variant="outlined"
                value={formik.values.sex}
                error={formik.touched.sex && Boolean(formik.errors.sex)}
                helperText={formik.touched.sex && formik.errors.sex}
                fullWidth
                select
                onChange={handleChange}
              >
                {sexOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.display}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} key="dateOfBirth" item>
              <TextField
                id="dateOfBirth"
                name="dateOfBirth"
                label={capitalize('dateOfBirth')}
                autoComplete="dateOfBirth"
                type="date"
                variant="outlined"
                value={formik.values.dateOfBirth && format(new Date(formik.values.dateOfBirth), 'yyyy-MM-dd') }
                error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            {['weight', 'height'].map((field) => (
              <Grid xs={12} key={field} item>
                <TextField
                  id={field}
                  name={field}
                  label={capitalize(field)}
                  autoComplete={field}
                  type="number"
                  variant="outlined"
                  value={formik.values[field]}
                  error={formik.touched[field] && Boolean(formik.errors[field])}
                  helperText={formik.touched[field] && formik.errors[field]}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            ))}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!editing}
              fullWidth
            >
              {patient ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </form>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          elevation={6}
          variant="filled"
          severity={error ? 'error' : 'success'}
          onClose={handleClose}
        >
          {error || 'Patient updated successfully!'}
        </Alert>
      </Snackbar>
    </div>
  );
}
