import { User } from '@auth0/auth0-react';
import { differenceInYears, format } from 'date-fns';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { capitalize } from './utils/capitalize';
import { calculateBMIWithCategory } from './utils/calculateBMIWithCategory';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 400,
  },
  cell: {
    border: 'none',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

export function UserInfo({ user: currentUser }: { user: User }) {
  const classes = useStyles();

  const { t } = useTranslation();

  const { name = '', surname = '', email, sex = '', dateOfBirth = '', weight = '', height = '' } = currentUser;

  const age = differenceInYears(new Date(), new Date(currentUser.dateOfBirth));

  const bmi = calculateBMIWithCategory({ sex, age, weight, height, t });

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        {t('userInformation.sectionTitle')}
      </Typography>
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell}>{t('userInformation.name')}</TableCell>
            <TableCell className={classes.cell} align="right">
              {capitalize(name) || 'N/A'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>{t('userInformation.lastName')}</TableCell>
            <TableCell className={classes.cell} align="right">
              {capitalize(surname) || 'N/A'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>{t('userInformation.email')}</TableCell>
            <TableCell className={classes.cell} align="right">
              {email}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>{t('userInformation.sex.label')}</TableCell>
            <TableCell className={classes.cell} align="right">
              {sex ? t(`userInformation.sex.${sex.toLowerCase()}`) : 'N/A'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>{t('userInformation.dateOfBirth')}</TableCell>
            <TableCell className={classes.cell} align="right">
              {dateOfBirth ? `${format(new Date(dateOfBirth), 'yyy-MM-dd')} (${age})` : 'N/A'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>{t('userInformation.weight')}</TableCell>
            <TableCell className={classes.cell} align="right">
              {weight ?? '-'} kg
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>{t('userInformation.height')}</TableCell>
            <TableCell className={classes.cell} align="right">
              {height ?? '-'} cm
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>{t('userInformation.bmi.label')}</TableCell>
            <TableCell className={classes.cell} align="right">
              {bmi}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
}
