import $ from 'jquery';

export const buildPointCommentIndicator = ({
  relativeOffsetX = 100,
  relativeOffsetY = 100,
  content = '',
  onDelete = () => {},
  onUpdate = () => {},
}) => {
  const wrapperRotation = 90;
  const indicatorRotation = -90;

  const wrapper = $(`<div></div>`).css({
    position: 'absolute',
    left: relativeOffsetX,
    top: relativeOffsetY,
    display: 'flex',
    alignItems: 'center',
    // outline: '1px dashed green',
    transform: `translate(-100%, -50%) rotate(${wrapperRotation}deg)`,
    transformOrigin: 'right',
  });

  const tooltip = $(`<span class="tooltiptext">${content}</span>`).css({
    visibility: 'hidden',
    width: '120px',
    backgroundColor: '#555',
    color: '#fff',
    textAlign: 'center',
    padding: '5px 0',
    borderRadius: '6px',

    /* Position the tooltip text */
    position: 'absolute',
    bottom: '125%',
    left: '50%',
    marginLeft: '-60px',
    zIndex: 2,

    /* Fade in tooltip */
    opacity: 0,
    transition: 'opacity 0.3s',
  });

  const indicator = $(`<div>
    <div>PC</div>
  </div>`)
    .css({
      transform: `rotate(${indicatorRotation}deg)`,
      background: 'yellow',
      border: '1px solid black',
      width: '25px',
      height: '25px',
      borderRadius: '10%',
      display: 'flex',
      alignContent: 'space-around',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'relative',
    })
    .append(tooltip)
    .on('mouseenter', () => {
      $(tooltip).css({
        visibility: 'visible',
        opacity: 1,
      });
    })
    .on('mouseleave', () => {
      $(tooltip).css({
        visibility: 'hidden',
        opacity: 0,
      });
    })
    .on('click', () => {
      const newComment = window.prompt('Edit point comment:', content);

      if (!newComment) {
        onDelete();

        return;
      }

      onUpdate(newComment);
    });

  const handle = $(`<div></div>`).css({
    width: 25,
    height: 2,
    background: 'black',
  });

  wrapper.append(indicator);
  wrapper.append(handle);

  wrapper.addClass('uplot-additional-element');

  return wrapper;
};
