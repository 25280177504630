import { AnnotationValue, RecordingsSequence, Sequence } from 'types/schema.type';
import { getUnixTime } from './getUnixTime';

export function getSequenceAnnotations(recordingsSequence: RecordingsSequence): number[] {
  const defaultReturnValue = [];

  if (!recordingsSequence?.recordings?.length) {
    return defaultReturnValue;
  }

  const result = recordingsSequence.recordings.reduce<number[]>((acc, item) => {
    const annotations = item?.analysis?.annotations || [];

    const timestamps = annotations
      .filter((it) => it.annotationValue === AnnotationValue.N)
      .map((it) => getUnixTime(item.recordingStartedAt, it.sampleIndex));

    acc.push(...timestamps);

    return acc;
  }, []);

  return result;
}

export function getSequenceAnalysisAnnotations(sequence: Sequence): number[] {
  const annotations = sequence?.analysis?.annotations.reduce<number[]>((acc, item) => {
    if (item.annotationValue === AnnotationValue.N) {
      const timestampUnix = getUnixTime(sequence.startedAt, item.sampleIndex);
      acc.push(timestampUnix);
    }

    return acc;
  }, []);

  return annotations || [];
}
