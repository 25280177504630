import { useMutation, useApolloClient, gql } from '@apollo/client';

const UPDATE_PATIENT = gql`
  mutation UpdatePatient($patientId: ID!, $patientInput: CreatePatientInput!) {
    updatePatient(patientId: $patientId, patientInput: $patientInput) {
      error
      success
    }
  }
`;

export function useUpdatePatient() {
  const client = useApolloClient();

  const [updatePatient, { data, loading, error }] = useMutation(UPDATE_PATIENT, {
    refetchQueries: ['GetPatientsByClinic'],
  });

  const updatePatientWrapper = async (options) => {
    const response = await updatePatient(options);

    // Clear cache when switching between patients
    await client.resetStore();

    return response;
  };

  return {
    updatePatient: updatePatientWrapper,
    success: data?.updatePatient?.success,
    loading,
    error,
  };
}
