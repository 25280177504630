/**
 * Calculates the average values of given data points within specified intervals.
 *
 * @param {number[]} timestamps - An array of timestamps for the data points.
 * @param {number[]} values - An array of values corresponding to each timestamp.
 * @param {number} [averageIntervalSec=30] - The interval in seconds for which the average should be calculated.
 * @returns {(number | null)[]} An array with the calculated averages placed at the midpoint of each interval.
 */
export const calculateAverageValues = (
  timestamps: number[],
  values: number[],
  averageIntervalSec: number = 30,
): (number | null)[] => {
  let tempValues: number[] = []; // Temporary storage for values within the current interval.
  const averages: (number | null)[] = new Array(timestamps.length).fill(null);
  let intervalStart: number = timestamps[0]; // Starting point of the current interval.

  for (let i = 0; i < timestamps.length; i += 1) {
    // If current timestamp is within or at the edge of the interval, add value to tempValues.
    if (timestamps[i] - intervalStart <= averageIntervalSec) {
      tempValues.push(values[i]);
    } else {
      // Calculate average for the interval if tempValues is not empty.
      const avg =
        tempValues.length > 0
          ? tempValues.reduce((acc, cur) => acc + cur, 0) / tempValues.length
          : 0;
      // Calculate the midpoint index of the interval.
      const midpointIndex = Math.floor((i - 1 + i - tempValues.length) / 2);

      averages[midpointIndex] = avg;

      // Reset for the next interval.
      intervalStart = timestamps[i];
      tempValues = [values[i]];
    }
  }

  // Handle the last interval separately.
  if (tempValues.length > 0) {
    const avg = tempValues.reduce((acc, cur) => acc + cur, 0) / tempValues.length;
    const lastIntervalStartIndex = timestamps.length - tempValues.length;
    const midpointIndex = Math.floor((timestamps.length - 1 + lastIntervalStartIndex) / 2);

    averages[midpointIndex] = avg;
  }

  return averages;
};
