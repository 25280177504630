import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import { HistogramChart } from './HistogramChart';
import { HistogramSummary } from './HistogramSummary';
import { PulseSummary } from '../PulseSummary';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const GET_HISTOGRAM_DATA = gql`
  query GetHistorgramData($getMyRecordingId: MongoObjectID!) {
    getMyRecording(id: $getMyRecordingId) {
      analysis {
        rate
        avgHeartrate
        beatCount
        bradycardiaEpisodes {
          end
          duration_s
          mean_hr
          start
        }
        pauseEpisodes {
          startIndex
          endIndex
        }
        minHeartrate
        maxHeartrate
      }
    }
  }
`;

type HistogramProps = {
  recordingId: string;
};

export function Histogram({ recordingId }: HistogramProps) {
  const classes = useStyles();

  const { data, loading } = useQuery(GET_HISTOGRAM_DATA, {
    variables: {
      getMyRecordingId: recordingId,
    },
  });

  if (loading) return null;

  const {
    rate,
    avgHeartrate,
    beatCount,
    bradycardiaEpisodes,
    pauseEpisodes,
    minHeartrate,
    maxHeartrate,
  } = data?.getMyRecording?.analysis;

  return (
    <div className={classes.root}>
      <HistogramChart rate={rate} />
      <HistogramSummary rate={rate} />
      <PulseSummary
        avgHeartrate={avgHeartrate}
        beatCount={beatCount}
        bradycardiaEpisodes={bradycardiaEpisodes}
        pauseEpisodes={pauseEpisodes}
        minHeartrate={minHeartrate}
        maxHeartrate={maxHeartrate}
      />
    </div>
  );
}
