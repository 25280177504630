import { useMutation, gql } from '@apollo/client';

const CREATE_PATIENT = gql`
  mutation CreatePatient($patientInput: CreatePatientInput!) {
    createPatient(patientInput: $patientInput) {
      error
      success
      patient {
        name
        clinicId
      }
    }
  }
`;

export function useCreatePatientMutation() {
  const [createPatient, { data, loading, error }] = useMutation(CREATE_PATIENT, {
    refetchQueries: ['GetPatientsByClinic'],
  });

  return {
    createPatient,
    patient: data?.createPatient?.patient,
    loading,
    error,
  };
}
