import { gql } from '@apollo/client';

export const GET_RECORDING_ANALYZE_DATE = gql`
  query GetRecordingAnalyzeDate($getMyRecordingId: MongoObjectID!) {
    getMyRecording(id: $getMyRecordingId) {
      analysis {
        id
        analysedAt
      }
    }
  }
`;
