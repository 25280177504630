import Alert from '@material-ui/lab/Alert';
import { useCallback } from 'react';
import { GlobalHotKeys, configure } from 'react-hotkeys';

configure({
  stopEventPropagationAfterHandling: true,
  ignoreRepeatedEventsWhenKeyHeldDown: false,
});

export function KeyboardShortcuts({
  jumpToNextAnnotation,
  closestIndexVisible,
  updateAnnotationHandler,
  closestIndex,
  expectingNewAnnotation,
  setExpectingNewAnnotation,
  middlePoint,
  updateScale,
  scale,
  scrollState,
  scrollStates,
  setScrollState,
}) {
  return (
    <GlobalHotKeys
      keyMap={{
        TOGGLE_SCROLL: 'space',
        SET_N: 'n',
        JUMP_TO_NEXT_N: 'shift+n',
        JUMP_TO_PREV_N: 'shift+alt+n',
        SET_S: 's',
        JUMP_TO_NEXT_S: 'shift+s',
        JUMP_TO_PREV_S: 'shift+alt+s',
        SET_V: 'v',
        JUMP_TO_NEXT_V: 'shift+v',
        JUMP_TO_PREV_V: 'shift+alt+v',
        SET_U: 'u',
        JUMP_TO_NEXT_U: 'shift+u',
        JUMP_TO_PREV_U: 'shift+alt+u',
        SET_E: 'e',
        JUMP_TO_NEXT_E: 'shift+e',
        JUMP_TO_PREV_E: 'shift+alt+e',
        NEW_ANNOTATION: 'a',
        DELETE_ANNOTATION: 'd',
        SCROLL_LEFT_ONE: 'shift+left',
        SCROLL_RIGHT_ONE: 'shift+right',
        SCROLL_LEFT_START: { sequence: 'left', action: 'keydown' },
        SCROLL_LEFT_STOP: { sequence: 'left', action: 'keyup' },
        SCROLL_RIGHT_START: {
          sequence: 'right',
          action: 'keydown',
        },
        SCROLL_RIGHT_STOP: { sequence: 'right', action: 'keyup' },
        ZOOM_OUT_START: { sequence: 'down', action: 'keydown' },
        ZOOM_OUT_END: { sequence: 'down', action: 'keyup' },
        ZOOM_IN_START: { sequence: 'up', action: 'keydown' },
        ZOOM_IN_END: { sequence: 'up', action: 'keyup' },
      }}
      handlers={{
        JUMP_TO_NEXT_N: (event) => {
          event?.preventDefault();
          jumpToNextAnnotation('N', true);
        },
        JUMP_TO_PREV_N: (event) => {
          event?.preventDefault();
          jumpToNextAnnotation('N', false);
        },
        JUMP_TO_NEXT_S: (event) => {
          event?.preventDefault();
          jumpToNextAnnotation('S', true);
        },
        JUMP_TO_PREV_S: (event) => {
          event?.preventDefault();
          jumpToNextAnnotation('S', false);
        },
        JUMP_TO_NEXT_V: (event) => {
          event?.preventDefault();
          jumpToNextAnnotation('V', true);
        },
        JUMP_TO_PREV_V: (event) => {
          event?.preventDefault();
          jumpToNextAnnotation('V', false);
        },
        JUMP_TO_NEXT_U: (event) => {
          event?.preventDefault();
          jumpToNextAnnotation('U', true);
        },
        JUMP_TO_PREV_U: (event) => {
          event?.preventDefault();
          jumpToNextAnnotation('U', false);
        },
        DELETE_ANNOTATION: (event) => {
          // eslint-disable-next-line react/destructuring-assignment
          event?.preventDefault();

          // eslint-disable-next-line
          if (
            // eslint-disable-next-line no-restricted-globals
            confirm('Are you sure you want to delete the highlighted annotation?')
          ) {
            return updateAnnotationHandler({
              sampleIndex: closestIndex,
              value: null,
            });
          }

          return null;
        },
        NEW_ANNOTATION: (event) => {
          event?.preventDefault();

          if (!expectingNewAnnotation) {
            setExpectingNewAnnotation(true);
          } else {
            setExpectingNewAnnotation(false);
            updateAnnotationHandler({
              sampleIndex: Math.round(middlePoint),
              value: 'N',
            });
          }
        },
        SCROLL_LEFT_ONE: (event) => {
          event?.preventDefault();
          updateScale([scale[0] - 1, scale[1] - 1]);
        },
        SCROLL_RIGHT_ONE: (event) => {
          event?.preventDefault();
          updateScale([scale[0] + 1, scale[1] + 1]);
        },
        TOGGLE_SCROLL: useCallback(
          (event) => {
            event?.preventDefault();

            if (scrollState === scrollStates.AUTOSCROLLING) {
              setScrollState(scrollStates.STOPPED);
            } else {
              setScrollState(scrollStates.AUTOSCROLLING);
            }
          },
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [scrollState],
        ),
        SCROLL_LEFT_START: (event) => {
          event?.preventDefault();
          setScrollState(scrollStates.SCROLLING_LEFT);
        },
        SCROLL_LEFT_STOP: (event) => {
          event?.preventDefault();
          setScrollState(scrollStates.STOPPED);
        },
        SCROLL_RIGHT_START: (event) => {
          event?.preventDefault();
          setScrollState(scrollStates.SCROLLING_RIGHT);
        },
        SCROLL_RIGHT_STOP: (event) => {
          event?.preventDefault();
          setScrollState(scrollStates.STOPPED);
        },
        SET_N: (event) => {
          event?.preventDefault();

          if (!closestIndexVisible) {
            return <Alert severity="warning">No annotation is visible right now</Alert>;
          }

          return updateAnnotationHandler({
            sampleIndex: closestIndex,
            value: 'N',
          });
        },
        SET_S: (event) => {
          event?.preventDefault();

          if (!closestIndexVisible) {
            return <Alert severity="warning">No annotation is visible right now</Alert>;
          }

          return updateAnnotationHandler({
            sampleIndex: closestIndex,
            value: 'S',
          });
        },
        SET_V: (event) => {
          event?.preventDefault();

          if (!closestIndexVisible) {
            return <Alert severity="warning">No annotation is visible right now</Alert>;
          }

          return updateAnnotationHandler({
            sampleIndex: closestIndex,
            value: 'V',
          });
        },
        SET_U: (event) => {
          event?.preventDefault();

          if (!closestIndexVisible) {
            return <Alert severity="warning">No annotation is visible right now</Alert>;
          }

          return updateAnnotationHandler({
            sampleIndex: closestIndex,
            value: 'U',
          });
        },
        ZOOM_OUT_START: (event) => {
          event?.preventDefault();
          setScrollState(scrollStates.ZOOMING_OUT);
        },
        ZOOM_OUT_END: (event) => {
          event?.preventDefault();
          setScrollState(scrollStates.STOPPED);
        },
        ZOOM_IN_START: (event) => {
          event?.preventDefault();
          setScrollState(scrollStates.ZOOMING_IN);
        },
        ZOOM_IN_END: (event) => {
          event?.preventDefault();
          setScrollState(scrollStates.STOPPED);
        },
      }}
      allowChanges
    />
  );
}
