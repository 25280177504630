import { gql, useQuery } from '@apollo/client';
import { HealthIndication, Recording } from 'types/schema.type';
import { mapHealthIndicationsToRecordings } from './utils/mapHealthIndicationsToRecordings';

const GET_HEALTH_INDICATIONS = gql`
  query GetHealthIndications {
    getHealthIndications {
      id
      recordedAt
      content
      type
      duration_s
    }
  }
`;

const GET_MY_RECORDINGS = gql`
  query GetMyRecordingsForHealthIndications {
    getMyRecordings {
      id
      recordingStartedAt
      recordingEndedAt
    }
  }
`;

export const useHealthIndications = () => {
  const {
    loading: recordingsLoading,
    data: recordingsData,
    error: recordingsError,
  } = useQuery<{ getMyRecordings: Recording[] }>(GET_MY_RECORDINGS);

  const {
    loading: healthIndicationLoading,
    data: healthIndicationData,
    error: healthIndicationError,
  } = useQuery<{ getHealthIndications: HealthIndication[] }>(GET_HEALTH_INDICATIONS);

  const healthIndications = healthIndicationData?.getHealthIndications ?? [];
  const recordings = recordingsData?.getMyRecordings ?? [];

  const data = mapHealthIndicationsToRecordings(recordings, healthIndications);
  const loading = recordingsLoading || healthIndicationLoading;
  const error = recordingsError || healthIndicationError;

  return { loading, error, data };
};
