import {
  BradycardiaEpisode,
  IntervalAnnotation,
  PauseEpisode,
  Recording,
  Sequence,
} from 'types/schema.type';

type AFibsEnriched = IntervalAnnotation &
  Pick<Recording, 'recordingStartedAt'> & { recordingId: string; isValid?: boolean };
type BradycardiaEnriched = BradycardiaEpisode &
  Pick<Recording, 'recordingStartedAt'> & {
    recordingId: string;
    avgBeat: number;
    minBeat: number;
    isValid?: boolean;
  };
type PauseEnriched = PauseEpisode &
  Pick<Recording, 'recordingStartedAt'> & { recordingId: string; isValid?: boolean };

export type SequenceEpisodes = {
  afibs: AFibsEnriched[];
  bradycardiaEpisodes: BradycardiaEnriched[];
  pauseEpisodes: PauseEnriched[];
};

function findIndexOfSmallestNumber(arr, startIdx, endIdx): number {
  let minIndex = null;

  for (let i = startIdx; i <= endIdx; i += 1) {
    const val = arr[i];

    if (val !== null && (minIndex === null || val < arr[minIndex])) {
      minIndex = i;
    }
  }

  return minIndex ?? 0;
}

export function getSequenceAnalysisEpisodes(
  sequence: Sequence,
  averageRates: (number | null)[],
): SequenceEpisodes {
  const annotationIndexMap =
    sequence?.analysis?.annotations?.reduce((acc, annotation, index) => {
      acc[annotation.sampleIndex] = index;

      return acc;
    }, {}) || {};
  const annotationSignalIndexes = sequence?.analysis?.annotations.map((v) => v.sampleIndex) || [];

  const afibs = sequence?.analysis?.afibs?.map((v) => ({
    ...v,
    isValid: v.isValid == null ? true : v.isValid,
    recordingStartedAt: sequence.startedAt,
    recordingId: sequence.id,
  }));

  const bradycardiaEpisodes = sequence?.analysis?.bradycardiaEpisodes?.map((v) => {
    const startIndex = annotationIndexMap[v.start || 0];
    const endIndex = annotationIndexMap[v.end || 0];

    const smallestIndex =
      annotationSignalIndexes[findIndexOfSmallestNumber(averageRates, startIndex, endIndex)];

    const beats = sequence?.analysis?.rate?.slice(startIndex, endIndex) || [];
    const minBeat = Math.min(...beats);
    const avgBeat = Math.round(beats.reduce<number>((acc, beat) => acc + beat, 0) / beats.length);

    return {
      ...v,
      startIndex: v.start,
      endIndex: v.end,
      recordingStartedAt: sequence.startedAt,
      recordingId: sequence.id,
      avgBeat,
      minBeat,
      lowestAvgBeatIndex: smallestIndex,
      isValid: v.isValid == null ? true : v.isValid,
    };
  });

  const pauseEpisodes = sequence?.analysis?.pauseEpisodes?.map((v) => ({
    ...v,
    isValid: v.isValid == null ? true : v.isValid,
    recordingStartedAt: sequence.startedAt,
    recordingId: sequence.id,
  }));

  const result = {
    afibs: afibs || [],
    bradycardiaEpisodes: bradycardiaEpisodes || [],
    pauseEpisodes: pauseEpisodes || [],
  };

  return result;
}
