import { gql } from '@apollo/client';

export const CREATE_POINT_COMMENT = gql`
  mutation Mutation($input: CreatePointCommentInput!) {
    createPointComment(input: $input) {
      success
      error
      pointComment {
        id
        recordingId
        sampleIndex
        comment
      }
    }
  }
`;
