import { gql } from '@apollo/client';

export const DOWNLOAD_RECORDINGS_BATCH = gql`
  mutation Mutation($recordingIds: [String]!) {
    downloadRecordingsBatch(recordingIds: $recordingIds) {
      success
      error
    }
  }
`;
