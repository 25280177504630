import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import omit from 'lodash/omit';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { useUser } from 'actions/useUser';
import { Analysis, Recording, Role } from 'types/schema.type';
import { RecordingChart } from './RecordingChart';
import { GET_RECORDING_ANNOTATIONS, GET_RECORDING_DOWNLOAD_URL } from '../queries';
import { downloadUri, downloadString } from '../utilities';
import { FlagSelector } from './FlagSelector';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  date: {
    minWidth: 110,
  },
  hideOverflow: {
    display: 'block',
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

function getAnalysisStatus(analysis: Analysis | undefined) {
  if (!analysis) return 'Not analyzed';

  if (analysis.success) return 'Success';

  return analysis.error ?? 'Error';
}

type RecordingItemProps = Partial<Recording> & {
  recordingId: Recording['id'];
  flagOptions: string[];
  updatedAt: number | undefined;
  onDelete: (param: any) => void;
  onReanalyze: (param: any) => void;
};

export const RecordingItem = React.memo((props: RecordingItemProps) => {
  const {
    recordingId,
    analysis,
    channelCount,
    comment,
    fileName,
    flagOptions,
    flags,
    updatedAt,
    recordingStartedAt,
    onDelete,
    onReanalyze,
  } = props;

  const { user } = useUser();

  const classes = useRowStyles();

  const [open, setOpen] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const isUserRole = user?.role === Role.User;
  const isClinicRole = user?.role === Role.Clinic;

  const [getAnnotations, { data: annotationData }] = useLazyQuery(GET_RECORDING_ANNOTATIONS, {
    variables: { recordingId },
    onCompleted: (data) => {
      const annotations = data?.getMyRecording?.analysis?.annotations;

      if (annotations) {
        const cleaned = annotations.map((it: any) => omit(it, '__typename'));
        const uri = JSON.stringify(cleaned, null, 4);

        downloadString(uri, 'annotations.json');
      } else {
        alert('Failed to download annotations');
      }
    },
  });

  const [getDownloadUri, { data: uriData }] = useLazyQuery(GET_RECORDING_DOWNLOAD_URL, {
    variables: { recordingId },
    onCompleted: (data) => {
      const uri = data?.getMyRecording?.downloadUrl;

      if (uri) {
        downloadUri(uri, fileName);
      } else {
        alert('Failed to download file');
      }
    },
  });

  const handleAnnotationDownload = () => {
    const annotations = annotationData?.getMyRecording?.analysis?.annotations;

    if (annotations) {
      const cleaned = annotations.map((it: any) => omit(it, '__typename'));
      const uri = JSON.stringify(cleaned, null, 4);

      downloadString(uri, 'annotations.json');
    } else {
      getAnnotations();
    }
  };

  const handleFileDownload = () => {
    const uri = uriData?.getMyRecording?.downloadUrl;

    if (uri) {
      // takes uri from cache
      downloadUri(uri, fileName);
    } else {
      // if cache empty, refetch
      getDownloadUri();
    }
  };

  const handleReanalyze = () => {
    if (isUserRole) return;

    onReanalyze({
      variables: {
        analyzeRecordingRecordingId: recordingId,
      },
    });
  };

  const handleDelete = () => {
    if (isUserRole || isClinicRole) return;

    try {
      // show delete recording confirmation
      onDelete({
        variables: {
          deleteRecordingRecordingId: recordingId,
        },
      });
    } catch (e) {
      alert('Failed to delete');
    }
  };

  const analysisStatus = getAnalysisStatus(analysis);
  const rPeaksCount = analysis?.rpeaksCount ?? 0;

  return (
    <>
      <TableRow className={classes.root} selected={open} hover>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.date} component="th" scope="row" align="center">
          {recordingStartedAt ? format(recordingStartedAt, 'yyy-MM-dd H:mm:ss') : 'NA'}
        </TableCell>
        <TableCell className={classes.date} component="th" scope="row" align="center">
          {updatedAt ? format(updatedAt, 'yyy-MM-dd H:mm:ss') : ''}
        </TableCell>
        <TableCell align="center">{channelCount}</TableCell>
        <TableCell align="center">{fileName}</TableCell>
        <TableCell align="center">
          <Tooltip title={analysisStatus}>
            <div className={classes.hideOverflow}>{analysisStatus}</div>
          </Tooltip>
        </TableCell>
        <TableCell>{rPeaksCount}</TableCell>
        <TableCell align="center">
          <Tooltip title={comment || ''}>
            <div className={classes.hideOverflow}>{comment}</div>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <FlagSelector recordingId={recordingId} flags={flags} flagOptions={flagOptions} disabled={isUserRole} />
        </TableCell>
        <TableCell align="center">
          <IconButton
            color="primary"
            aria-label="download annotations"
            onClick={handleAnnotationDownload}
          >
            <CloudDownloadIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton color="primary" aria-label="download file" onClick={handleFileDownload}>
            <CloudDownloadIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton
            color="primary"
            aria-label="reanalyze"
            disabled={isUserRole}
            onClick={handleReanalyze}
          >
            <AutorenewIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton
            color="secondary"
            aria-label="delete file"
            disabled={isUserRole || isClinicRole}
            onClick={() => setIsDialogOpen(true)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RecordingChart recordingId={recordingId} />
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog open={isDialogOpen} PaperComponent={Paper} aria-labelledby="draggable-dialog-title">
        <DialogTitle>Delete recording</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete Recording ID <strong>{recordingId}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

RecordingItem.displayName = 'RecordingItem';
