import uPlot from 'uplot';
import { Interval, Annotation } from 'utilities/types';
import { getSamplePosition } from './getSamplePosition';
import { getDurationFromSampleCount } from './getDurationFromSampleCount';

type ComputeIntervalPositionsProps = {
  u: uPlot;
  data: [number[], number[]];
  sourceFrom: number[];
  sourceTo: number[];
  annotations?: Annotation[];
  onClick?: (id: string) => void;
};

// Takes two lists of samples and builds intervals between points in them
export const computeInvervalPositions = ({
  data,
  sourceFrom,
  sourceTo,
  u,
  annotations,
  onClick = () => {},
}: ComputeIntervalPositionsProps) => {
  const intervals: Interval[] = [];

  sourceFrom.forEach((sample, index) => {
    const from = sample;
    const to = sourceTo[index];
    const closestQSample = sourceTo.filter((qSample) => qSample > sample)[0];

    const [fromX, fromY] = getSamplePosition({
      u,
      sampleIndex: sample,
      data,
    });
    const [toX, toY] = getSamplePosition({
      u,
      sampleIndex: closestQSample,
      data,
    });

    let annotation: Annotation | undefined;

    if (annotations) {
      const currentAnnotation = annotations.find(({ startIndex, endIndex }) => {
        const [start, end] = [startIndex, endIndex].sort((a, b) => a - b);

        return start === from && end === to;
      });

      if (currentAnnotation) {
        annotation = currentAnnotation;
      }
    }

    if (closestQSample) {
      intervals.push({
        fromX,
        toX,
        fromY,
        toY,
        content: `${getDurationFromSampleCount(closestQSample - sample)}s`,
        annotationId: annotation?.id ?? '',
        isFromAnalysis: Boolean(annotation?.isFromAnalysis),
        onClick: () => {
          if (window.confirm(`Delete annotation interval [${from}, ${to}]?`)) {
            onClick(annotation?.id ?? '');
          } else {
            console.log('confirm cancel');
          }
        },
      });
    }
  });

  return intervals;
};
