import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { format } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';

import { Patient } from 'types/schema.type';
import { usePatients } from './usePatients';
import { CreatePatientForm } from './CreatePatientForm';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  row: {
    '& > th': {
      fontWeight: 'bold',
    },
  },
  container: {
    marginTop: theme.spacing(2),
  },
  generateButton: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export function PatientList() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(undefined);

  const { patients, loading: patientsLoading } = usePatients();

  if (patientsLoading) {
    return <div>Loading patients...</div>;
  }

  const handleCreatePatiant = () => {
    setSelectedPatient(undefined);
    setOpen(true);
  };

  const handleEditPatient = (patient) => {
    setSelectedPatient(patient);
    setOpen(true);
  };
  
  const handleClose = () => {
    setSelectedPatient(undefined);
    setOpen(false);
  };

  return (
    <Container className={classes.container} maxWidth={false}>
      <Button
        className={classes.generateButton}
        variant="contained"
        color="primary"
        onClick={handleCreatePatiant}
      >
        Create Patient
      </Button>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Patient list">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Surname</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Reference ID</TableCell>
              <TableCell>Sex</TableCell>
              <TableCell>Height</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Date of birth</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients?.map((patient) => (
              <TableRow key={patient.id}>
                <TableCell> {patient.name} </TableCell>
                <TableCell>{patient.surname}</TableCell>
                <TableCell>{patient.email}</TableCell>
                <TableCell>{patient.patientRefId}</TableCell>
                <TableCell>{patient.sex}</TableCell>
                <TableCell>{patient.height}</TableCell>
                <TableCell>{patient.weight}</TableCell>
                <TableCell>
                  {patient.dateOfBirth && format(new Date(patient.dateOfBirth), 'yyy-MM-dd')}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    aria-label="edit patient"
                    onClick={() => handleEditPatient(patient)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <CreatePatientForm patient={selectedPatient} onClose={handleClose} />
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
