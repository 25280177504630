import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAuth0Patients } from 'components/features/patients/useAuth0Patients';
import useSessionStorage from 'hooks/useSessionStorage';
import { ShortcutsText } from './shortcuts';
import { ChartSettings } from './chart-settings';
import { ButtonMenuLayout } from './button-menu.layout';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  autocomplete: {
    minWidth: 400,
    backgroundColor: 'white',
    borderRadius: 4,
    '& .MuiInputBase-root': {
      padding: theme.spacing(1),
    },
  },
  buttonLink: {
    color: 'white',
    marginRight: theme.spacing(2),
  },
  clearIndicator: {
    visibility: 'visible',
  }
}));

function getPatientFullName(patient) {
  let fullName = 'Unknown';

  if (patient.name && patient.surname) {
    fullName = `${patient.name} ${patient.surname}`;
  } else if (patient.name) {
    fullName = patient.name;
  } else if (patient.email) {
    fullName = patient.email;
  }

  if (patient.dateOfBirth) {
    const dateOfBirth = patient.dateOfBirth.split('T')[0];

    fullName += ` (${dateOfBirth})`;
  }

  return fullName;
}

export function PatientManagementLayout() {
  const classes = useStyles();
  const { patients, loading } = useAuth0Patients();
  const [selectedPatientId, setSelectedPatientId] = useSessionStorage<string>(
    'selectedPatientId',
    '',
  );

  const patientsMap = useMemo(
    () =>
      patients.reduce((acc, patient) => {
        acc[patient.id] = {
          ...patient,
          fullName: getPatientFullName(patient),
        };

        return acc;
      }, {}),
    [patients],
  );

  const selectedPatient = selectedPatientId ? patientsMap[selectedPatientId] || null : null;

  const handlePatientChange = (event, newValue) => {
    if (newValue && newValue.id) {
      setSelectedPatientId(newValue.id);
    } else {
      setSelectedPatientId('');
    }

    window.location.reload();
  };

  if (loading) {
    return null;
  }

  const options = Object.values(patientsMap);

  return (
    <>
      <Button className={classes.buttonLink} component={RouterLink} to="/recording-sequences">
        Recording Sequences
      </Button>
      <Button className={classes.buttonLink} component={RouterLink} to="/recordings">
        Recordings
      </Button>

      <Autocomplete
        value={selectedPatient || null}
        options={options}
        getOptionLabel={(option) => option.fullName}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} placeholder="Select patient..." variant="outlined" fullWidth />
        )}
        className={classes.autocomplete}
        classes={{
          clearIndicator: classes.clearIndicator, // Targeting the clear button specifically
        }}
        onChange={handlePatientChange}
      />

      <div className={classes.grow} />
      <ShortcutsText />
      <ChartSettings />
      <ButtonMenuLayout />
    </>
  );
}
