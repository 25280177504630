import { gql } from '@apollo/client';

export const REANALYZE_RECORDING = gql`
  mutation Mutation($analyzeRecordingRecordingId: MongoObjectID!) {
    analyzeRecording(recordingId: $analyzeRecordingRecordingId) {
      success
      error
    }
  }
`;
