import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ExtremesHeartRateChart } from './ExtremesHeartRateChart';
import { getTime } from '../utils/getTime';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  chart: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type ExtremesHeartRateChartListProps = {
  recordingStartedAt: number;
  extremes: {
    min: number;
    minIndex: number;
    minExcerpt: number[];
    max: number;
    maxIndex: number;
    maxExcerpt: number[];
  };
  rpeaks: number[];
};

export function ExtremesHeartRateChartList(props: ExtremesHeartRateChartListProps) {
  const classes = useStyles();
  const { t } = useTranslation(); // Importing the useTranslation hook

  const { recordingStartedAt, extremes } = props;

  if (!recordingStartedAt || !extremes) {
    return null;
  }

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        {t('extremesHeartRateChartList.title')}
      </Typography>
      <div className={classes.chart}>
        <ExtremesHeartRateChart
          title={t('extremesHeartRateChartList.lowestHeartRateTitle', {
            min: extremes.min,
            time: getTime(recordingStartedAt, extremes.minIndex),
          })}
          recordingStartedAt={recordingStartedAt ?? 0}
          startIndex={extremes.minIndex - 3000}
          signal={extremes.minExcerpt}
          rpeaks={props.rpeaks}
        />
      </div>
      <div className={classes.chart}>
        <ExtremesHeartRateChart
          title={t('extremesHeartRateChartList.highestHeartRateTitle', {
            max: extremes.max,
            time: getTime(recordingStartedAt, extremes.maxIndex),
          })}
          recordingStartedAt={recordingStartedAt ?? 0}
          startIndex={extremes.maxIndex - 3000}
          signal={extremes.maxExcerpt}
          rpeaks={props.rpeaks}
        />
      </div>
    </Container>
  );
}
