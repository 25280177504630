/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { useMutation } from '@apollo/client';
import React, { memo, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { UPDATE_RECORDING } from '../mutations';
import './CommentBox.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  field: {
    width: 400,
  },
  button: {
    paddingLeft: theme.spacing(2),
  },
}));

type CommentBoxProps = {
  recordingId: string;
  comment: string;
};

export const CommentBox = memo(({ recordingId, comment = '' }: CommentBoxProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(comment);
  const [editing, setEditing] = useState(false);

  const [updateRecording] = useMutation(UPDATE_RECORDING);

  const handleChange = (e) => {
    setValue(e.target.value);
    setEditing(true);
  };

  const handleSave = () => {
    updateRecording({
      variables: {
        updateRecordingId: recordingId,
        updateRecordingInput: { comment: value },
      },
    });
    setEditing(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.field}>
        <TextField
          id="filled-multiline-flexible"
          rows={3}
          value={value}
          variant="filled"
          placeholder="Add comment"
          multiline
          fullWidth
          onChange={handleChange}
        />
      </div>
      <div className={classes.button}>
        <Button variant="contained" color="primary" disabled={!editing} onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
});

CommentBox.displayName = 'CommentBox';
