import { useMemo } from 'react';
import UPlotReact from 'uplot-react';
import uPlot from 'uplot';
import { useTranslation } from 'react-i18next';
import { AnnotationValue } from 'types/schema.type';

type ExcerptChartProps = {
  clusterNumber: number;
  annotationValue: AnnotationValue;
  data: number[][];
};

export function ExcerptMultipleChart(props: ExcerptChartProps) {
  const { annotationValue, data } = props;
  const { t } = useTranslation();

  const options = useMemo<uPlot.Options>(
    () => ({
      title: t('excerptMultipleChart.chartTitle', {
        annotationValue,
        clusterNumber: props.clusterNumber + 1,
        dataLength: data.length,
      }),
      width: 250,
      height: 200,
      series: [
        {
          label: t('excerptMultipleChart.seriesIndexLabel'),
        },
        ...data.map(() => ({
          label: t('excerptMultipleChart.seriesVoltageLabel'), // Translated label for Voltage
          stroke: 'rgba(255, 0, 0, 0.5)', // Red color with 50% opacity
        })),
      ],
      legend: {
        show: false,
      },
      scales: { x: { time: false }, y: { range: [-2, 3] } },
    }),
    [annotationValue, data, props.clusterNumber, t],
  );

  const chartData = useMemo(() => {
    const xAxis = Array.from({ length: data[0].length }, (_, i) => i);

    return [xAxis, ...data];
  }, [data]) as uPlot.AlignedData;

  return <UPlotReact key="episode-excerpts" options={options} data={chartData} />;
}
