import React, { createContext, useContext } from 'react';
import { useLocalStorage } from './useLocalStorage';

type ShowChart = {
  rate: boolean;
  histogram: boolean;
  interval: boolean;
  annotation: boolean;
  tachycardia: boolean;
  healthIndication: boolean;
};

type VisiblePeaks = {
  P: boolean;
  Q: boolean;
  R: boolean;
  S: boolean;
  T: boolean;
  E: boolean;
};

type VisibleAnnotations = {
  ML: boolean;
};

type VisibleIntervals = {
  PQ: boolean;
  QRS: boolean;
  QT: boolean;
  RR: boolean;
  U: boolean;
  AFib: boolean;
  PE: boolean;
};

type ChartSettingsContext = {
  xAxisTime: boolean;
  scrollSpeed: number;
  allowCreatePointComment: boolean;
  jumpToML: boolean;
  showCharts: ShowChart;
  visiblePeaks: VisiblePeaks;
  visibleAnnotations: VisibleAnnotations;
  visibleIntervals: VisibleIntervals;
  setXAxisTime: React.Dispatch<React.SetStateAction<boolean>>;
  setScrollSpeed: React.Dispatch<React.SetStateAction<number>>;
  setAllowCreatePointComment: React.Dispatch<React.SetStateAction<boolean>>;
  setJumpToML: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCharts: React.Dispatch<React.SetStateAction<ShowChart>>;
  setVisiblePeaks: React.Dispatch<React.SetStateAction<VisiblePeaks>>;
  setVisibleAnnotations: React.Dispatch<React.SetStateAction<VisibleAnnotations>>;
  setVisibleIntervals: React.Dispatch<React.SetStateAction<VisibleIntervals>>;
};

const initialState = {
  xAxisTime: false,
  scrollSpeed: 1,
  allowCreatePointComment: false,
  jumpToML: false,
  showCharts: {
    rate: false,
    histogram: false,
    interval: false,
    annotation: false,
    tachycardia: false,
    healthIndication: false,
  },
  visiblePeaks: {
    P: false,
    Q: false,
    R: true,
    S: false,
    T: false,
    E: false,
  },
  visibleAnnotations: {
    // Automatic Rpeak annotations
    ML: false,
  },
  visibleIntervals: {
    PQ: false,
    QRS: false,
    QT: false,
    RR: true,
    U: true,
    AFib: false,
    PE: false,
  },
};

function useProvideSettings(): ChartSettingsContext {
  const [xAxisTime, setXAxisTime] = useLocalStorage<boolean>(
    'chart-settings/xAxis',
    initialState.xAxisTime,
  );
  const [scrollSpeed, setScrollSpeed] = useLocalStorage<number>(
    'chart-settings/scrollSpeed',
    initialState.scrollSpeed,
  );
  const [allowCreatePointComment, setAllowCreatePointComment] = useLocalStorage<boolean>(
    'chart-settings/pointComment',
    initialState.allowCreatePointComment,
  );
  const [jumpToML, setJumpToML] = useLocalStorage<boolean>(
    'chart-settings/jumpToML',
    initialState.jumpToML,
  );

  const [showCharts, setShowCharts] = useLocalStorage<ShowChart>(
    'chart-settings/showCharts',
    initialState.showCharts,
  );
  const [visiblePeaks, setVisiblePeaks] = useLocalStorage<VisiblePeaks>(
    'chart-settings/visiblePeaks',
    initialState.visiblePeaks,
  );
  const [visibleAnnotations, setVisibleAnnotations] = useLocalStorage<VisibleAnnotations>(
    'chart-settings/visibleAnnotations',
    initialState.visibleAnnotations,
  );
  const [visibleIntervals, setVisibleIntervals] = useLocalStorage<VisibleIntervals>(
    'chart-settings/visibleIntervals',
    initialState.visibleIntervals,
  );

  return {
    xAxisTime,
    scrollSpeed,
    allowCreatePointComment,
    jumpToML,
    showCharts,
    visiblePeaks,
    visibleAnnotations,
    visibleIntervals,
    setXAxisTime,
    setScrollSpeed,
    setAllowCreatePointComment,
    setJumpToML,
    setShowCharts,
    setVisiblePeaks,
    setVisibleAnnotations,
    setVisibleIntervals,
  };
}

const initialContext = {
  ...initialState,
  setXAxisTime: () => {},
  setScrollSpeed: () => {},
  setAllowCreatePointComment: () => {},
  setJumpToML: () => {},
  setShowCharts: () => {},
  setVisiblePeaks: () => {},
  setVisibleAnnotations: () => {},
  setVisibleIntervals: () => {},
};
const SettingsContext = createContext<ChartSettingsContext>(initialContext);

export function ProvideChartSettings({ children }) {
  const settings = useProvideSettings();

  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>;
}

export const useChartSettings = () => useContext(SettingsContext);
