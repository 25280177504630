import { AnnotationValue, Sequence } from 'types/schema.type';

/**
 * This function returns data that is needed for CHARTS!
 */
export function getSequenceEctopicBeats(sequence: Sequence | undefined) {
  const ventricularDuplets = sequence?.analysis?.ventricularDuplets
    ?.filter((it) => it.isValid)
    ?.slice(0, 10)
    .map((v) => ({
      ...v,
      sequenceStartedAt: sequence.startedAt,
      annotationValue: AnnotationValue.V,
    }));
  const ventricularTriplets = sequence?.analysis?.ventricularTriplets
    ?.filter((it) => it.isValid)
    ?.slice(0, 10)
    .map((v) => ({
      ...v,
      sequenceStartedAt: sequence.startedAt,
      annotationValue: AnnotationValue.V,
    }));
  const ventricularRuns = sequence?.analysis?.ventricularRuns
    ?.filter((it) => it.isValid)
    ?.slice(0, 10)
    .map((v) => ({
      ...v,
      sequenceStartedAt: sequence.startedAt,
      annotationValue: AnnotationValue.V,
    }));
  const supraVentricularDuplets = sequence?.analysis?.supraVentricularDuplets
    ?.filter((it) => it.isValid)
    ?.slice(0, 10)
    .map((v) => ({
      ...v,
      sequenceStartedAt: sequence.startedAt,
      annotationValue: AnnotationValue.S,
    }));
  const supraVentricularTriplets = sequence?.analysis?.supraVentricularTriplets
    ?.filter((it) => it.isValid)
    ?.slice(0, 10)
    .map((v) => ({
      ...v,
      sequenceStartedAt: sequence.startedAt,
      annotationValue: AnnotationValue.S,
    }));
  const supraVentricularRuns = sequence?.analysis?.supraVentricularRuns
    ?.filter((it) => it.isValid)
    ?.slice(0, 10)
    .map((v) => ({
      ...v,
      sequenceStartedAt: sequence.startedAt,
      annotationValue: AnnotationValue.S,
    }));

  const result = {
    ventricularDuplets: ventricularDuplets || [],
    ventricularTriplets: ventricularTriplets || [],
    ventricularRuns: ventricularRuns || [],
    supraVentricularDuplets: supraVentricularDuplets || [],
    supraVentricularTriplets: supraVentricularTriplets || [],
    supraVentricularRuns: supraVentricularRuns || [],
  };

  return result;
}
