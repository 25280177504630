import { useQuery, gql } from '@apollo/client';
import { User } from 'types/schema.type';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      email
      emailVerified
      role
      name
      surname
      sex
      height
      weight
      dateOfBirth
      dateOfSignup
      timezone
      isImpersonated
      permissions
    }
  }
`;

export function useUser() {
  const { data, loading, error, refetch } = useQuery<{ getCurrentUser: User }>(GET_CURRENT_USER);

  return {
    user: data?.getCurrentUser,
    loading,
    error,
    refetch,
  };
}
