import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import styles from './HistogramChart.module.css';

function groupByRange(data: number[]) {
  return data.reduce<{number?: number[]}>((acc, val) => {
    if (acc[val]) {
      acc[val].push(val);
    } else {
      acc[val] = [val];
    }

    return acc;
  }, {});
}

type HistogramSummaryProps = {
  rate: number[];
};

export function HistogramSummary({ rate }: HistogramSummaryProps) {
  const summaryObject: Record<number, number[]> = groupByRange(rate);
  const lessThan60 = ((rate.filter((it) => it < 60).length / rate.length) * 100).toFixed(3);
  const moreThan100 = ((rate.filter((it) => it > 100).length / rate.length) * 100).toFixed(3);

  return (
    <div className={styles.summary}>
      <Typography variant="h6" gutterBottom>
        Histogram Summary
      </Typography>

      <Table aria-label="range statistics">
        <TableHead>
          <TableRow>
            <TableCell>BPM Range</TableCell>
            <TableCell>% of BPM</TableCell>
            <TableCell>BPM Count of Total {rate.length}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            // example: { key: 60; value: [64, 67, 67] }
            Object.entries(summaryObject).map(([key, val]) => {
              const rangeLabel = key;
              // const rangeLabel = `${key} - ${Number(key) + 10}`;
              const rangePercent = Math.round((val.length / rate.length) * 100);
              const rangeCount = val.length;

              return (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {rangeLabel}
                  </TableCell>
                  <TableCell>{rangePercent}</TableCell>
                  <TableCell>{rangeCount}</TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>

      <Table aria-label="time percentage">
        <TableHead>
          <TableRow>
            <TableCell>BPM</TableCell>
            <TableCell>% of time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {`< 60`}
            </TableCell>
            <TableCell>{lessThan60}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {`> 100`}
            </TableCell>
            <TableCell>{moreThan100}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
