import { useQuery, gql } from '@apollo/client';
import { SharedToken } from 'types/schema.type';

const GET_SHARED_TOKENS = gql`
  query GetSharedTokens {
    getSharedTokens {
      success
      error
      sharedTokens {
        id
        token
        userId
        createdAt
        expiresAt
      }
    }
  }
`;

export function useGetSharedTokens() {
  const { data, loading, error, refetch } =
    useQuery<{ getSharedTokens: { sharedTokens: [SharedToken] } }>(GET_SHARED_TOKENS);

  return {
    tokens: data?.getSharedTokens?.sharedTokens,
    loading,
    error,
    refetch,
  };
}
