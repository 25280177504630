import { Sequence } from 'types/schema.type';

enum TachycardiaTypes {
  VENTRICULAR_DUPLETS = 'ventricularDuplets',
  VENTRICULAR_TRIPLETS = 'ventricularTriplets',
  VENTRICULAR_RUNS = 'ventricularRuns',
  SUPRA_VENTRICULAR_DUPLETS = 'supraVentricularDuplets',
  SUPRA_VENTRICULAR_TRIPLETS = 'supraVentricularTriplets',
  SUPRA_VENTRICULAR_RUNS = 'supraVentricularRuns',
}

type TachycardiaResults = {
  ventricularDuplets: number[];
  ventricularTriplets: number[];
  ventricularRuns: number[];
  supraVentricularDuplets: number[];
  supraVentricularTriplets: number[];
  supraVentricularRuns: number[];
};

export function getSequenceAnalysisTachycardia(sequence: Sequence) {
  const defaultReturnValue: { [key in TachycardiaTypes]: [] } = {
    ventricularDuplets: [],
    ventricularTriplets: [],
    ventricularRuns: [],
    supraVentricularDuplets: [],
    supraVentricularTriplets: [],
    supraVentricularRuns: [],
  };

  if (!sequence?.analysis) {
    return defaultReturnValue;
  }

  const { analysis } = sequence;

  const extractFirstSampleIndex = (item: { sampleIndexes: number[] }): number =>
    item?.sampleIndexes[0];
  const convertToTimestamp = (sampleIndex: number): number => sequence.startedAt + (sampleIndex / 200) * 1000;

  const result = Object.values(TachycardiaTypes).reduce<TachycardiaResults>((acc, tachycardiaType) => {
    const beats = analysis[tachycardiaType] ?? [];
    const beatTimestamps = beats
      .filter((beat) => beat.isValid)
      .map(extractFirstSampleIndex)
      .filter(Number.isInteger)
      .map(convertToTimestamp);

    acc[tachycardiaType].push(...beatTimestamps);

    return acc;
  }, defaultReturnValue);

  return result;
}