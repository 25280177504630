export function getIntervals(sourceFrom, sourceTo) {
  const res = [];

  sourceFrom.forEach((from) => {
    const to = sourceTo.find((it) => it > from);

    if (!to) return;

    const interval = (to - from) / 200;

    res.push(interval);
  });

  const avg = res.slice(0, 5).reduce((a, b) => a + b, 0) / 5;

  const avgFixed = Number(avg.toFixed(3));

  const combinedIntervals = [avgFixed].concat(res);

  return combinedIntervals;
}
