import { gql } from '@apollo/client';

export const GET_ENUMS = gql`
  query GetEnums($enumName: String!) {
    __type(name: $enumName) {
      enumValues {
        name
      }
    }
  }
`;
