/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import uPlot from 'uplot';
import lodash from 'lodash';
import { format } from 'date-fns';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';

import { PauseEpisodeDto } from 'types/episode';
import { useChartSettings } from 'hooks/use-chart-settings';
import { AnnotationValue, AutomaticRpeakAnnotation, RpeakAnnotation } from 'types';
import { AnnotationSummary } from 'components/annotation-summary.component';
import { Chart } from './Chart';
import { CommentBox } from './CommentBox';
import { RateChart } from './RateChart';
import { IntervalChart } from './charts/IntervalChart';
import { KeyboardShortcuts } from './header/shortcuts';
import { TwoThumbsDraggableTrack } from './DoubleSlider';
import { Histogram } from './Histogram/Histogram';
import { TachycardiaResultsContainer } from './tables/TachycardiaResultsContainer';
import {
  getSampleIndexFromTimestamp,
  HealthIndicationContainer,
  useHealthIndications,
} from './features/healthIndication';

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginBottom: theme.spacing(4),
    minWidth: 1200,
  },
  settings: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  summary: {
    padding: theme.spacing(2),
  },
  chartContainer: {
    width: 1200,
    margin: 'auto',
  },
  chartWrapper: {
    paddingTop: theme.spacing(3),
    paddingbottom: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  thumbs: { width: 1200, marginTop: 20, marginBottom: 20 },
}));

const ChartWrapper = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingbottom: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}))(Paper);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
  },
  expanded: {},
})(MuiAccordionSummary);

type ThreeChannelChartProps = {
  recordingId: string;
  recordingStartedAt: string;
  data: any;
  updateAnnotationHandler: any;
  rpeaks: RpeakAnnotation[];
  qpeaks: number[];
  speaks: number[];
  tpeaks: number[];
  ppeaks: number[];
  automaticRpeakAnnotations: AutomaticRpeakAnnotation[];
  rate: number[];
  noises: any;
  afibs: any;
  pauseEpisodes: PauseEpisodeDto;
  pointComments: any;
  comment: any;
  onCreateNoise: any;
  onDeleteNoise: any;
  onCreateAFib: any;
  onDeleteAFib: any;
  onUpdatePointComment: any;
  onDeletePointComment: any;
  onCreatePointComment: any;
  onEditAnnotationInterval: any;
};

export function ThreeChannelChart(props: ThreeChannelChartProps) {
  const {
    recordingId,
    recordingStartedAt,
    data,
    updateAnnotationHandler,
    rpeaks,
    qpeaks,
    speaks,
    tpeaks,
    ppeaks,
    automaticRpeakAnnotations,
    rate,
    noises,
    afibs,
    pauseEpisodes,
    pointComments,
    comment,
    onCreateNoise,
    onDeleteNoise,
    onCreateAFib,
    onDeleteAFib,
    onUpdatePointComment,
    onDeletePointComment,
    onCreatePointComment,
    onEditAnnotationInterval,
  } = props;
  const chartSync = uPlot.sync(recordingId);
  const classes = useStyles() as any;

  const {
    xAxisTime,
    scrollSpeed,
    allowCreatePointComment,
    jumpToML,
    showCharts,
    visiblePeaks,
    visibleAnnotations,
    visibleIntervals,
    setAllowCreatePointComment,
  } = useChartSettings();

  const { data: healthIndicationMap } = useHealthIndications();

  const [expectingNewAnnotation, setExpectingNewAnnotation] = useState(false);

  const scrollStates = {
    STOPPED: 0,
    AUTOSCROLLING: 1,
    SCROLLING_LEFT: 2,
    SCROLLING_RIGHT: 3,
    ZOOMING_IN: 4,
    ZOOMING_OUT: 5,
  };
  const [scrollState, setScrollState] = useState(scrollStates.STOPPED);

  const limits = [0, data.ch1[0].length];

  const [scale, setScale] = useState<[number, number]>([0, 2500]);

  const updateScale = (newScale) => {
    const processedScale = newScale;

    if (processedScale[0] === scale[0] || processedScale[1] === scale[1]) {
      return;
    }

    if (processedScale[1] > data.ch1[0].length) {
      processedScale[1] = data.ch1[0].length;
    }

    if (processedScale[0] < 0) {
      processedScale[0] = 0;
    }

    if (processedScale[1] - processedScale[0] < 100) {
      return;
    }

    setScale(processedScale);
  };

  useEffect(() => {
    const autoscrollStepLength = scrollSpeed * 4;
    const stepLength = (scale[1] - scale[0]) / 100;

    if (scrollState !== scrollStates.STOPPED) {
      const interval = setInterval(() => {
        switch (scrollState) {
          case scrollStates.AUTOSCROLLING:
            updateScale([scale[0] + autoscrollStepLength, scale[1] + autoscrollStepLength]);
            break;
          case scrollStates.SCROLLING_LEFT:
            updateScale([scale[0] - stepLength, scale[1] - stepLength]);
            break;
          case scrollStates.SCROLLING_RIGHT:
            updateScale([scale[0] + stepLength, scale[1] + stepLength]);
            break;
          case scrollStates.ZOOMING_IN:
            updateScale([scale[0] + stepLength, scale[1] - stepLength]);
            break;
          case scrollStates.ZOOMING_OUT:
            updateScale([scale[0] - stepLength, scale[1] + stepLength]);
            break;
          default:
            break;
        }
      }, (1000 / 200) * 4);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollStates, scale, scrollSpeed]);

  const middlePoint = scale[0] + (scale[1] - scale[0]) / 2;
  const closestIndex =
    (rpeaks.length &&
      lodash(rpeaks)
        .map(({ sampleIndex }) => ({
          sampleIndex,
          closeness: Math.abs(middlePoint - sampleIndex),
        }))
        .sortBy(['closeness'])
        .first()?.sampleIndex) ||
    0;

  const closestIndexVisible = closestIndex > scale[0] && closestIndex < scale[1];

  const jumpToNextAnnotation = (type: AnnotationValue, direction: boolean) => {
    let nextAnnotation: RpeakAnnotation | AutomaticRpeakAnnotation | undefined;

    // jump to next if direction TRUE
    if (direction) {
      if (jumpToML) {
        nextAnnotation = lodash(automaticRpeakAnnotations)
          .sortBy('sampleIndex')
          .find((item) => item.sampleIndex > closestIndex && item.annotationValue === type);
      } else {
        nextAnnotation = lodash(rpeaks)
          .sortBy('sampleIndex')
          .find((item) => item.sampleIndex > closestIndex && item.annotationValue === type);
      }
    } else if (jumpToML) {
      nextAnnotation = lodash(automaticRpeakAnnotations)
        .sortBy('sampleIndex')
        .reverse()
        .find((item) => item.sampleIndex < closestIndex && item.annotationValue === type);
    } else {
      nextAnnotation = lodash(rpeaks)
        .sortBy('sampleIndex')
        .reverse()
        .find((item) => item.sampleIndex < closestIndex && item.annotationValue === type);
    }

    if (nextAnnotation) {
      const zoomLevel = scale[1] - scale[0];

      updateScale([
        nextAnnotation.sampleIndex - zoomLevel / 2,
        nextAnnotation.sampleIndex + zoomLevel / 2,
      ]);
    } else {
      alert(`There are no more annotations of type ${type}`);
    }
  };

  const healthIndications = (healthIndicationMap[recordingId] ?? []).map((item) => ({
    sampleIndex: getSampleIndexFromTimestamp(Number(recordingStartedAt), item.recordedAt),
    time: format(item.recordedAt, 'yyy-MM-dd H:mm:ss'),
    type: item.type,
    content: item.content,
    duration_s: item.duration_s,
  }));

  const ch1Chart = (
    <Chart
      sync={chartSync}
      updateAnnotation={updateAnnotationHandler}
      recordingStartedAt={recordingStartedAt}
      xAxisTime={xAxisTime}
      plotScale={scale}
      rpeaks={rpeaks}
      data={data.ch1}
      highlightIndex={closestIndex}
      highlightMiddleSample={expectingNewAnnotation}
      qpeaks={qpeaks}
      speaks={speaks}
      tpeaks={tpeaks}
      ppeaks={ppeaks}
      automaticRpeakAnnotations={automaticRpeakAnnotations}
      noises={noises}
      afibs={afibs}
      pauseEpisodes={pauseEpisodes}
      pointComments={pointComments}
      healthIndications={healthIndications}
      setAllowCreatePointComment={setAllowCreatePointComment}
      allowCreatePointComment={allowCreatePointComment}
      showPeaks={visiblePeaks}
      showAnnotations={visibleAnnotations}
      showIntervals={visibleIntervals}
      showCharts={showCharts}
      onPlotScaleChange={updateScale}
      onCreateNoise={onCreateNoise}
      onDeleteNoise={onDeleteNoise}
      onCreateAFib={onCreateAFib}
      onDeleteAFib={onDeleteAFib}
      onUpdatePointComment={onUpdatePointComment}
      onDeletePointComment={onDeletePointComment}
      onCreatePointComment={onCreatePointComment}
      onEditAnnotationInterval={onEditAnnotationInterval}
    />
  );

  let ch2Chart = <span />;

  if (data.ch2) {
    ch2Chart = (
      <Chart
        sync={chartSync}
        updateAnnotation={updateAnnotationHandler}
        recordingStartedAt={recordingStartedAt}
        xAxisTime={xAxisTime}
        plotScale={scale}
        rpeaks={rpeaks}
        data={data.ch2}
        highlightIndex={closestIndex}
        highlightMiddleSample={expectingNewAnnotation}
        qpeaks={qpeaks}
        speaks={speaks}
        tpeaks={tpeaks}
        ppeaks={ppeaks}
        automaticRpeakAnnotations={automaticRpeakAnnotations}
        noises={noises}
        afibs={afibs}
        pauseEpisodes={pauseEpisodes}
        pointComments={pointComments}
        healthIndications={healthIndications}
        allowCreatePointComment={allowCreatePointComment}
        setAllowCreatePointComment={setAllowCreatePointComment}
        showPeaks={visiblePeaks}
        showAnnotations={visibleAnnotations}
        showIntervals={visibleIntervals}
        showCharts={showCharts}
        onPlotScaleChange={updateScale}
        onCreateNoise={onCreateNoise}
        onDeleteNoise={onDeleteNoise}
        onCreateAFib={onCreateAFib}
        onDeleteAFib={onDeleteAFib}
        onUpdatePointComment={onUpdatePointComment}
        onDeletePointComment={onDeletePointComment}
        onCreatePointComment={onCreatePointComment}
        onEditAnnotationInterval={onEditAnnotationInterval}
      />
    );
  }

  let ch3Chart = <span />;

  if (data.ch3) {
    ch3Chart = (
      <Chart
        sync={chartSync}
        updateAnnotation={updateAnnotationHandler}
        recordingStartedAt={recordingStartedAt}
        xAxisTime={xAxisTime}
        plotScale={scale}
        rpeaks={rpeaks}
        data={data.ch3}
        highlightIndex={closestIndex}
        highlightMiddleSample={expectingNewAnnotation}
        qpeaks={qpeaks}
        speaks={speaks}
        tpeaks={tpeaks}
        ppeaks={ppeaks}
        automaticRpeakAnnotations={automaticRpeakAnnotations}
        noises={noises}
        afibs={afibs}
        pauseEpisodes={pauseEpisodes}
        pointComments={pointComments}
        healthIndications={healthIndications}
        allowCreatePointComment={allowCreatePointComment}
        setAllowCreatePointComment={setAllowCreatePointComment}
        showPeaks={visiblePeaks}
        showAnnotations={visibleAnnotations}
        showIntervals={visibleIntervals}
        showCharts={showCharts}
        onPlotScaleChange={updateScale}
        onCreateNoise={onCreateNoise}
        onDeleteNoise={onDeleteNoise}
        onCreateAFib={onCreateAFib}
        onDeleteAFib={onDeleteAFib}
        onUpdatePointComment={onUpdatePointComment}
        onDeletePointComment={onDeletePointComment}
        onCreatePointComment={onCreatePointComment}
        onEditAnnotationInterval={onEditAnnotationInterval}
      />
    );
  }

  const rpeakCounts = lodash(rpeaks).countBy('annotationValue').value();
  const { N = 0, S = 0, V = 0, U = 0 } = rpeakCounts;

  return (
    <div className={classes.root}>
      <div className={classes.settings}>
        <Paper elevation={2}>
          <div className={classes.summary}>
            <Typography variant="subtitle1" gutterBottom>
              Recording ID: {recordingId}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              R-Peak Counts: N={N} S={S} V={V} Noise={U} automaticRpeakAnnotations=
              {automaticRpeakAnnotations.length}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Sample Rate: 200 per second
            </Typography>
          </div>
        </Paper>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Comments</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CommentBox recordingId={recordingId} comment={comment} />
          </AccordionDetails>
        </Accordion>
      </div>

      <div className={classes.chartContainer}>
        <div className={classes.thumbs}>
          <TwoThumbsDraggableTrack
            limits={limits}
            values={scale}
            onChange={(values) => {
              if (values[1] - values[0] < 100) {
                return;
              }

              setScale(values);
            }}
          />
        </div>

        <KeyboardShortcuts
          jumpToNextAnnotation={jumpToNextAnnotation}
          closestIndexVisible={closestIndexVisible}
          updateAnnotationHandler={updateAnnotationHandler}
          closestIndex={closestIndex}
          expectingNewAnnotation={expectingNewAnnotation}
          setExpectingNewAnnotation={setExpectingNewAnnotation}
          middlePoint={middlePoint}
          updateScale={updateScale}
          scale={scale}
          scrollState={scrollState}
          scrollStates={scrollStates}
          setScrollState={setScrollState}
        />

        <ChartWrapper elevation={2}>
          {ch1Chart}
          {ch2Chart}
          {ch3Chart}
        </ChartWrapper>

        {showCharts.rate && (
          <ChartWrapper elevation={2}>
            <RateChart
              rate={rate}
              rpeaks={rpeaks}
              recordingStartedAt={recordingStartedAt}
              xAxisTime={xAxisTime}
              sync={chartSync}
            />
          </ChartWrapper>
        )}

        {showCharts.histogram && (
          <ChartWrapper elevation={2}>
            <Histogram recordingId={recordingId} />
          </ChartWrapper>
        )}

        {showCharts.interval && (
          <ChartWrapper elevation={2}>
            <IntervalChart
              data={data.ch1}
              rpeaks={rpeaks}
              ppeaks={ppeaks}
              qpeaks={qpeaks}
              speaks={speaks}
              tpeaks={tpeaks}
              recordingStartedAt={recordingStartedAt}
              plotScale={scale}
              sync={chartSync}
            />
          </ChartWrapper>
        )}

        {showCharts.annotation && (
          <ChartWrapper elevation={2}>
            <AnnotationSummary recordingId={recordingId} scale={scale} setScale={setScale} />
          </ChartWrapper>
        )}

        {showCharts.tachycardia && (
          <TachycardiaResultsContainer
            recordingId={recordingId}
            scale={scale}
            setScale={setScale}
          />
        )}

        {showCharts.healthIndication && (
          <HealthIndicationContainer
            recordingId={recordingId}
            recordingStartedAt={Number(recordingStartedAt)}
            scale={scale}
            setScale={setScale}
          />
        )}
      </div>
    </div>
  );
}
