/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useMemo, useEffect } from 'react';
import UPlotReact from 'uplot-react';

import { getIntervals, chunkArray } from '../../utilities';

function getUnixTime(timeStamp, index) {
  // 200 samples per 1000 ms = 1 sample index per 5 ms
  const time = (timeStamp + index * 5) / 1000;

  return time;
}

function withAvgIntervals(arr) {
  const interval = arr.reduce((a, b) => a + b) / arr.length;

  return parseFloat(interval.toFixed(3));
}

function getSTSegment(data, speaks, qpeaks) {
  const res = [];

  speaks.forEach((from) => {
    const to = qpeaks.find((it) => it > from);

    if (!to) return;

    const segment = data[to] - data[from];

    res.push(segment);
  });

  return res;
}

export function IntervalChart({
  data: voltageData,
  rpeaks: rpeaksSamples,
  qpeaks,
  ppeaks,
  speaks,
  tpeaks,
  recordingStartedAt,
  plotScale,
  sync,
}) {
  const isTimeSeries = Boolean(recordingStartedAt);

  const rpeaks = useMemo(() => rpeaksSamples.map((rpeak) => rpeak.sampleIndex), [rpeaksSamples]);
  const rrIntervals = useMemo(() => getIntervals(rpeaks, rpeaks), [rpeaks]);
  const pqIntervals = useMemo(() => getIntervals(ppeaks, qpeaks), [ppeaks, qpeaks]);
  const qrsIntervals = useMemo(() => getIntervals(qpeaks, speaks), [qpeaks, speaks]);
  const qtIntervals = useMemo(() => getIntervals(qpeaks, tpeaks), [qpeaks, tpeaks]);
  const stSegment = useMemo(
    () => getSTSegment(voltageData[1], speaks, tpeaks),
    [voltageData, speaks, tpeaks],
  );

  const xAxis = useMemo(
    () => (isTimeSeries ? rpeaks.map((rpeak) => getUnixTime(recordingStartedAt, rpeak)) : rpeaks),
    [isTimeSeries, recordingStartedAt, rpeaks],
  );

  const [sampleSize, setSampleSize] = useState(5);
  const [options, setOptions] = useState(
    useMemo(
      () => ({
        title: 'Peak intervals and segments per time',
        width: 1200,
        height: 200,
        axes: [
          {},
          {
            label: 'Interval (sec)',
            scale: 'sec',
          },
          {
            side: 1,
            label: 'Voltage (mV)',
            scale: 'mv',
            size: 70,
            values: (u, vals) => vals.map((v) => `${+v.toFixed(2)} mV`),
            grid: { show: false },
          },
        ],
        series: [
          {
            label: isTimeSeries ? 'Time' : 'SampleIndex',
          },
          {
            label: 'RR (sec)',
            scale: 'sec',
            width: 1 / devicePixelRatio,
            drawStyle: 0,
            lineInterpolation: null,
            stroke: 'black',
            spanGaps: true,
          },
          {
            label: 'PQ (sec)',
            scale: 'sec',
            width: 1 / devicePixelRatio,
            drawStyle: 0,
            lineInterpolation: null,
            stroke: 'orange',
            spanGaps: true,
          },
          {
            label: 'QRS (sec)',
            scale: 'sec',
            width: 1 / devicePixelRatio,
            drawStyle: 0,
            lineInterpolation: null,
            stroke: 'red',
            spanGaps: true,
          },
          {
            label: 'QT (sec)',
            scale: 'sec',
            width: 1 / devicePixelRatio,
            drawStyle: 0,
            lineInterpolation: null,
            stroke: 'blue',
            spanGaps: true,
          },
          {
            label: 'ST Segment (mV)',
            scale: 'mv',
            width: 1 / devicePixelRatio,
            drawStyle: 0,
            lineInterpolation: null,
            stroke: 'green',
            spanGaps: true,
          },
        ],
        plugins: [],
        scales: { x: { time: isTimeSeries } },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  );

  const [data, setData] = useState(
    useMemo(
      () => [
        xAxis,
        chunkArray(rrIntervals, sampleSize, withAvgIntervals),
        chunkArray(pqIntervals, sampleSize, withAvgIntervals),
        chunkArray(qrsIntervals, sampleSize, withAvgIntervals),
        chunkArray(qtIntervals, sampleSize, withAvgIntervals),
        chunkArray(stSegment, sampleSize, withAvgIntervals),
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  );

  useEffect(() => {
    const [min, max] = plotScale;
    const xMin = isTimeSeries ? getUnixTime(recordingStartedAt, min) : min;
    const XMax = isTimeSeries ? getUnixTime(recordingStartedAt, max) : max;

    const newOptions = {
      ...options,
      scales: {
        x: {
          time: isTimeSeries,
          min: xMin,
          max: XMax,
        },
      },
    };

    setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotScale]);

  // Recalculate average intervals when chunk size changes
  useEffect(() => {
    const newRRIntervals = chunkArray(rrIntervals, sampleSize, withAvgIntervals);
    const newPQIntervals = chunkArray(pqIntervals, sampleSize, withAvgIntervals);
    const newQRSIntervals = chunkArray(qrsIntervals, sampleSize, withAvgIntervals);
    const newQTIntervals = chunkArray(qtIntervals, sampleSize, withAvgIntervals);
    const newSTSegment = chunkArray(stSegment, sampleSize, withAvgIntervals);

    const newData = [
      xAxis,
      newRRIntervals,
      newPQIntervals,
      newQRSIntervals,
      newQTIntervals,
      newSTSegment,
    ];

    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleSize]);

  return (
    <>
      <label htmlFor="samples">Average interval bucket size:</label>
      <input
        name="samples"
        type="range"
        id="samples"
        min="5"
        max="200"
        step="5"
        value={sampleSize}
        onChange={(e) => setSampleSize(Number(e.target.value))}
      />{' '}
      {sampleSize}
      <UPlotReact
        key={sync}
        options={options}
        data={data}
        onDelete={(/* chart: uPlot */) => console.log('Rate chart deleted from hooks')}
        onCreate={(/* chart: uPlot */) => console.log('Rate chart created from hooks')}
      />
    </>
  );
}
