/**
 * This function checks if the annotation should be rendered based on the previous and current annotation voltage.
 * Sometimes annotation voltage is way out of bounds, and we render only from -2 to 2 mhz voltage.
 *
 * @param previousAnnotationVoltage
 * @param currentAnnotationVoltage
 *
 */
export function shouldRenderChartAnnotation(
  previousAnnotationVoltage: number,
  currentAnnotationVoltage: number,
): boolean {
  const result =
    previousAnnotationVoltage < 3 &&
    previousAnnotationVoltage > -2 &&
    currentAnnotationVoltage < 3 &&
    currentAnnotationVoltage > -2;

  return result;
}
