import { useMutation, gql } from '@apollo/client';

const CREATE_SHARED_TOKEN = gql`
  mutation CreateSharedToken {
    createSharedToken {
      error
      success
      token {
        createdAt
        expiresAt
        id
        token
        userId
      }
    }
  }
`;

export function useCreateSharedToken() {
  const [createSharedToken, { data, loading, error }] = useMutation(CREATE_SHARED_TOKEN, {
    refetchQueries: ['GetSharedTokens'],
  });

  return {
    createSharedToken,
    data: data?.createSharedToken,
    loading,
    error,
  };
}
