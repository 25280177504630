import { useMemo } from 'react';
import UPlotReact from 'uplot-react';
import format from 'date-fns/format';
import $ from 'jquery';
import uPlot from 'uplot';

import { buildIntervalIndicator } from 'utilities';
import { chunkArray } from '../utils/chunkArray';
import { shouldRenderChartAnnotation } from '../utils/shouldRenderChartAnnotation';

type ExtremesHeartRateChartProps = {
  title: string;
  startIndex: number;
  recordingStartedAt: number;
  signal: number[];
  rpeaks: number[];
};

export function ExtremesHeartRateChart({
  title,
  startIndex,
  recordingStartedAt,
  signal,
  rpeaks,
}: ExtremesHeartRateChartProps) {
  const rpeakIndexesMap = rpeaks.reduce((acc, rpeak) => {
    acc[rpeak] = true;

    return acc;
  }, {} as Record<number, boolean>);

  const xAxis = useMemo(
    () => Array.from({ length: signal.length }, (_, i) => startIndex + i),
    [signal.length, startIndex],
  );
  const rpeaksIndexes = useMemo(
    () => xAxis.map((x) => (rpeakIndexesMap[x] ? x : null)),
    [xAxis, rpeakIndexesMap],
  );

  const signalChunks = useMemo(() => chunkArray(signal), [signal]);
  const xAxisChunks = useMemo(() => chunkArray(xAxis), [xAxis]);
  const rpeaksChunks = useMemo(() => chunkArray(rpeaksIndexes), [rpeaksIndexes]);

  let firstRenderableChunkFound = false;

  return (
    <>
      {signalChunks.map((chunk, index) => {
        const isChunkRenderable = chunk.some((value) => value !== null && value !== 0);

        if (!isChunkRenderable) {
          return null;
        }

        const isThisFirstRenderableChunk = !firstRenderableChunkFound;

        if (isThisFirstRenderableChunk) {
          firstRenderableChunkFound = true;
        }

        const options = {
          title: isThisFirstRenderableChunk ? title : '',
          width: 700, // A4 format - 210mm == 800px
          height: 220,
          series: [
            {
              label: 'Time',
              value: (_, rawValue) =>
                format(recordingStartedAt + (rawValue / 200) * 1000, 'yyy-MM-dd H:mm:ss'),
            },
            { label: 'Voltage, mV', stroke: 'red' },
          ],
          axes: [
            {
              values: (_, splits) =>
                splits.map((idx) => format(recordingStartedAt + (idx / 200) * 1000, 'H:mm:ss')),
            },
            { label: 'Voltage, mV' },
          ],
          hooks: {
            drawAxes: [
              (u: uPlot) => {
                let previousSampleIndex;

                rpeaksChunks[index].forEach((currentSampleValue, idx, arr) => {
                  if (currentSampleValue === null) return;

                  if (!previousSampleIndex) {
                    previousSampleIndex = idx;

                    return;
                  }

                  // currentSampleIndex is the index of the sample in the chunk
                  const currentAnnotationVoltage = u.data[1][idx] as number;
                  const currentSamplePosX = Math.round(
                    u.valToPos(currentSampleValue as number, 'x', false),
                  );
                  const currentSamplePosY = Math.round(
                    u.valToPos(currentAnnotationVoltage, 'y', false),
                  );

                  // previousSampleIndex is the index of the sample in the chunk
                  const previousAnnotationVoltage = u.data[1][previousSampleIndex] as number;
                  const previousSampleValue = arr[previousSampleIndex] as number;
                  const previousSamplePosX = Math.round(
                    u.valToPos(previousSampleValue, 'x', false),
                  );
                  const previousSamplePosY = Math.round(
                    u.valToPos(previousAnnotationVoltage, 'y', false),
                  );

                  const shouldRenderAnnotation = shouldRenderChartAnnotation(
                    previousAnnotationVoltage,
                    currentAnnotationVoltage,
                  );

                  if (shouldRenderAnnotation) {
                    $(u.root.querySelector('.u-over')).append(
                      buildIntervalIndicator({
                        fromX: previousSamplePosX,
                        fromY: previousSamplePosY,
                        toX: currentSamplePosX,
                        toY: currentSamplePosY,
                        content: `${(currentSampleValue - previousSampleValue) / 200}s`,
                      }),
                    );
                  }

                  previousSampleIndex = idx;
                });
              },
            ],
          },
          plugins: [],
          legend: { show: false },
          scales: { x: { time: true }, y: { range: [-2, 3] } },
        };

        return (
          <div key={`${index + 1}`}>
            <UPlotReact options={options as any} data={[xAxisChunks[index] as number[], chunk]} />
          </div>
        );
      })}
    </>
  );
}
