import { useMemo } from 'react';
import UPlotReact from 'uplot-react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chartContainer: {
    marginBottom: theme.spacing(4),
  },
}));

interface RateChartSequencesProps {
  annotationTimestamps: number[];
  rate: (null | number)[];
}

const chunkDataInSixHours = (
  timestamps: number[],
  rate: (null | number)[],
): { timestamps: number[]; rates: (null | number)[] }[] => {
  const SIX_HOURS_IN_SECONDS = 6 * 60 * 60;
  const chunkedData: { timestamps: number[]; rates: number[] }[] = [];
  let chunkStart = timestamps[0];
  let tempTimestamps: any[] = [];
  let tempRates: any[] = [];

  for (let i = 0; i < timestamps.length; i += 1) {
    if (timestamps[i] - chunkStart < SIX_HOURS_IN_SECONDS) {
      tempTimestamps.push(timestamps[i]);
      tempRates.push(rate[i]);
    } else {
      chunkedData.push({ timestamps: tempTimestamps, rates: tempRates });
      tempTimestamps = [timestamps[i]];
      tempRates = [rate[i]];
      chunkStart = timestamps[i];
    }
  }

  // Add the last chunk if it's not empty
  if (tempTimestamps.length > 0) {
    chunkedData.push({ timestamps: tempTimestamps, rates: tempRates });
  }

  return chunkedData;
};

export function RateChart({ annotationTimestamps, rate }: RateChartSequencesProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const chunks = useMemo(
    () => chunkDataInSixHours(annotationTimestamps, rate),
    [annotationTimestamps, rate],
  );

  return (
    <Container className={classes.root} component={Paper}>
      {chunks.map((chunk, index) => {
        const chunkLength = chunk.timestamps.length;
        const bpm100Line = new Array(chunkLength).fill(100);
        const bpm50Line = new Array(chunkLength).fill(50);

        const options = {
          title: index === 0 ? t('rateChart.title') : '',
          width: 754,
          height: 220,

          axes: [
            {
              grid: {
                show: true,
                width: 1 / devicePixelRatio,
                stroke: '#e3e3e3',
              },
              ticks: {
                width: 1 / devicePixelRatio,
                stroke: '#e3e3e3',
              },
            },
            {
              label: t('rateChart.axisRateLabel'),
            },
          ],
          series: [
            {
              label: t('rateChart.seriesTimeLabel'),
            },
            {
              label: t('rateChart.seriesRateLabel'),
              width: 1 / devicePixelRatio,
              drawStyle: 0,
              lineInterpolation: null,
              stroke: '#E24D42',
              spanGaps: true,
            },
            {
              label: t('rateChart.series100BpmLabel'),
              stroke: 'black', // Custom color for 100 bpm line
              width: 1, // Custom line width
              // Optional: dashed line style
              dash: [10, 5],
            },
            {
              label: t('rateChart.series50BpmLabel'),
              stroke: 'black', // Custom color for 50 bpm line
              width: 1, // Custom line width
              // Optional: dashed line style
              dash: [10, 5],
            },
          ],
          plugins: [],
          scales: { x: { time: true } },
        };

        return (
          <div key={`${index + 1}`} className={classes.chartContainer}>
            <UPlotReact
              options={options}
              data={[chunk.timestamps, chunk.rates, bpm100Line, bpm50Line]}
            />
          </div>
        );
      })}
    </Container>
  );
}
