import { format, formatDuration, intervalToDuration } from 'date-fns';
import { t } from 'i18next';

export function formatTimeDuration({
  durationInMilliseconds,
  showSeconds = false,
  detailed = false,
}: {
  durationInMilliseconds: number;
  showSeconds?: boolean;
  detailed?: boolean;
}) {
  const duration = intervalToDuration({ start: 0, end: durationInMilliseconds });
  const timeFormat = ['hours', 'minutes'];

  if (showSeconds) {
    timeFormat.push('seconds');
  }

  const formattedDuration = formatDuration(duration, {
    format: timeFormat,
    locale: {
      formatDistance: (token, count) => t(`time.${token}`, { count }),
    },
  });

  if (detailed) {
    return formattedDuration
      .replace('h', t('time.hours'))
      .replace('min', t('time.minutes'))
      .replace('sec', t('time.seconds'));
  }

  return formattedDuration;
}

export function formatDateTimeRange({
  fromMiliseconds,
  toMiliseconds,
  showSeconds = false,
  detailed = false,
}: {
  fromMiliseconds: number;
  toMiliseconds: number;
  showSeconds?: boolean;
  detailed?: boolean;
}) {
  const dayFormat = 'yyyy-MM-dd';
  const timeFormat = showSeconds ? 'HH:mm:ss' : 'HH:mm';
  const formatString = `${dayFormat} ${timeFormat}`;

  const startedFull = format(fromMiliseconds, formatString);
  const endedFull = format(toMiliseconds, formatString);

  if (detailed) {
    return `${t('time.from')} ${startedFull} ${t('time.to')} ${endedFull}`;
  }

  const isSameDay = format(fromMiliseconds, 'yyyy-MM-dd') === format(toMiliseconds, 'yyyy-MM-dd');
  const dateRange = isSameDay
    ? `${startedFull} - ${format(toMiliseconds, timeFormat)}`
    : `${startedFull} ${t('time.to')} ${endedFull}`;

  return dateRange;
}
