export const calculateAverageRate = (timestamps: number[], rate: number[]): (null | number)[] => {
  const THIRTY_SECONDS = 30;
  let tempRates: number[] = [];
  const averages = new Array(timestamps.length).fill(null);
  let intervalStart = timestamps[0];

  for (let i = 0; i < timestamps.length; i += 1) {
    // Check if the current timestamp is within the 30-second interval
    if (timestamps[i] - intervalStart < THIRTY_SECONDS) {
      tempRates.push(rate[i]);
    } else {
      // Calculate the average for the interval
      const avg = Math.round(tempRates.reduce((acc, cur) => acc + cur, 0) / tempRates.length);
      // Place the average at the midpoint of the interval
      const midpointIndex = Math.ceil((i - 1 + i - tempRates.length) / 2);

      averages[midpointIndex] = avg;

      // Reset for the next interval
      intervalStart = timestamps[i];
      tempRates = [rate[i]];
    }
  }

  // Handle the last interval if it's not empty
  if (tempRates.length > 0) {
    const avg = Math.round(tempRates.reduce((acc, cur) => acc + cur, 0) / tempRates.length);
    const midpointIndex = Math.ceil(
      (timestamps.length - 1 + timestamps.length - 1 - tempRates.length) / 2,
    );

    averages[midpointIndex] = avg;
  }

  return averages;
};