import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    name
    surname
    dateOfBirth
    sex
    email
    weight
    height
  }
`;

export const ANALYSIS_FRAGMENT = gql`
  fragment AnalysisFragment on Analysis {
    id
    beatCount
    avgHeartrate
    maxHeartrate
    minHeartrate
    rate
    annotations {
      sampleIndex
      annotationValue
    }
    automaticRpeakAnnotations {
      annotationValue
      sampleIndex
    }
    bradycardiaEpisodes {
      start
      end
      duration_s
      isValid
    }
    pauseEpisodes {
      startIndex
      endIndex
      isValid
    }
    afibs {
      id
      startIndex
      endIndex
      annotationValue
      isFromAnalysis
      isValid
    }
    supraVentricularDuplets {
      id
      sampleIndexes
      isValid
    }
    supraVentricularRuns {
      id
      sampleIndexes
      isValid
    }
    supraVentricularTriplets {
      id
      sampleIndexes
      isValid
    }
    ventricularDuplets {
      id
      sampleIndexes
      isValid
    }
    ventricularRuns {
      id
      sampleIndexes
      isValid
    }
    ventricularTriplets {
      id
      sampleIndexes
      isValid
    }
  }
`;

export const EPISODES_AND_AFIBS_FRAGMENT = gql`
  fragment EpisodesAndAfibsFragment on Analysis {
    bradycardiaEpisodes {
      start
      end
      duration_s
      isValid
      excerpt {
        ch1
      }
    }
    pauseEpisodes {
      startIndex
      endIndex
      isValid
      excerpt {
        ch1
      }
    }
    afibs {
      id
      startIndex
      endIndex
      annotationValue
      isFromAnalysis
      isValid
      excerpt {
        ch1
      }
    }
    supraVentricularDuplets {
      id
      sampleIndexes
      excerpt {
        ch1
      }
      isValid
    }
    supraVentricularRuns {
      id
      sampleIndexes
      excerpt {
        ch1
      }
      isValid
    }
    supraVentricularTriplets {
      id
      sampleIndexes
      excerpt {
        ch1
      }
      isValid
    }
    ventricularDuplets {
      id
      sampleIndexes
      excerpt {
        ch1
      }
      isValid
    }
    ventricularRuns {
      id
      sampleIndexes
      excerpt {
        ch1
      }
      isValid
    }
    ventricularTriplets {
      id
      sampleIndexes
      excerpt {
        ch1
      }
      isValid
    }
  }
`;
