import $ from 'jquery';
import { Interval } from 'utilities/types';

export const buildPauseEpisodeInterval = ({ fromX, toX, content }: Interval) => {
  const height = 130;
  const top = 0;

  const isPartialLeft = fromX < 0;
  const isPartialRight = toX > 1100;

  const left = isPartialLeft ? 0 : fromX;
  const right = isPartialRight ? 1100 : toX;

  const width = right - left;

  const container = $('<div></div>').css({
    top,
    left,
    width,
    height,
    position: 'absolute',
  });

  const ruler = $(`
    <div class="ruler-pause">
        <div class="content">
            ${content}
        </div>
  </div>`);

  container.append(ruler);

  container.addClass('uplot-additional-element');

  return container;
};
