export function downloadString(content, fileName) {
  const hiddenElement = document.createElement('a');

  hiddenElement.href = `data:attachment/text,${encodeURI(content)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName;
  document.body.appendChild(hiddenElement);
  hiddenElement.click();
  document.body.removeChild(hiddenElement);
}
