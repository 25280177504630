import { gql } from '@apollo/client';

export const CREATE_NOISE = gql`
  mutation CreateNoiseMutation($createNoiseInput: CreateIntervalInput!) {
    createNoise(input: $createNoiseInput) {
      success
      error
      noise {
        id
        startIndex
        endIndex
        annotationValue
      }
    }
  }
`;
