import { useMemo } from 'react';
import UPlotReact from 'uplot-react';
import uPlot from 'uplot';
import format from 'date-fns/format';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

import { buildIntervalIndicator } from 'utilities';
import { createEpisodeContentSeries } from '../utils/createEpisodeContentSeries';
import { shouldRenderChartAnnotation } from '../utils/shouldRenderChartAnnotation';
import { formatDateTimeRange, formatTimeDuration } from '../utils/formatTime';

type PauseEpisodeChartProps = {
  recordingStartedAt: number;
  startIndex: number;
  endIndex: number;
  signal: number[];
  rpeaks: number[];
  showTitle?: boolean;
};

export function PauseEpisodeChart({
  recordingStartedAt,
  startIndex,
  endIndex,
  signal,
  rpeaks,
  showTitle = true,
}: PauseEpisodeChartProps) {
  const { t } = useTranslation();

  const dateRange = formatDateTimeRange({
    fromMiliseconds: recordingStartedAt + (startIndex / 200) * 1000,
    toMiliseconds: recordingStartedAt + (endIndex / 200) * 1000,
    showSeconds: true,
  });
  const duration = formatTimeDuration({
    durationInMilliseconds: (endIndex - startIndex) * 5,
    showSeconds: true,
  });

  const rpeakIndexesMap = rpeaks.reduce((acc, rpeak) => {
    acc[rpeak] = true;

    return acc;
  }, {} as Record<number, boolean>);

  const xAxis = useMemo(
    () => Array.from({ length: signal.length }, (_, i) => startIndex - 1000 + i),
    [signal.length, startIndex],
  );

  const rpeaksIndexes = useMemo(
    () => xAxis.map((x) => (rpeakIndexesMap[x] ? x : null)),
    [xAxis, rpeakIndexesMap],
  );

  const options = useMemo<uPlot.Options>(
    () => ({
      title: showTitle ? `${t('pauseEpisodeChart.title', { dateRange, duration })}` : '',
      width: 700, // A4 format - 210mm == 800px
      height: 135,
      series: [
        {
          label: t('pauseEpisodeChart.seriesTimeLabel'),
          value: (_, rawValue) =>
            format(recordingStartedAt + (rawValue / 200) * 1000, 'yyy-MM-dd H:mm:ss'),
        },
        { label: t('pauseEpisodeChart.seriesVoltageLabel'), stroke: 'red' },
        { fill: 'rgba(128, 128, 128, 0.2)' },
        { fill: 'rgba(128, 128, 128, 0.2)' },
      ],
      axes: [
        {
          values: (_, splits) =>
            splits.map((idx) => format(recordingStartedAt + (idx / 200) * 1000, 'H:mm:ss')),
        },
        { label: t('pauseEpisodeChart.axisVoltageLabel') },
      ],
      hooks: {
        drawAxes: [
          (u: uPlot) => {
            let previousSampleIndex;

            rpeaksIndexes.forEach((currentSampleValue, idx, arr) => {
              if (currentSampleValue === null) return;

              if (!previousSampleIndex) {
                previousSampleIndex = idx;

                return;
              }

              // currentSampleIndex is the index of the sample in the chunk
              const currentAnnotationVoltage = u.data[1][idx] as number;
              const currentSamplePosX = Math.round(
                u.valToPos(currentSampleValue as number, 'x', false),
              );
              const currentSamplePosY = Math.round(
                u.valToPos(currentAnnotationVoltage, 'y', false),
              );

              // previousSampleIndex is the index of the sample in the chunk
              const previousAnnotationVoltage = u.data[1][previousSampleIndex] as number;
              const previousSampleValue = arr[previousSampleIndex] as number;
              const previousSamplePosX = Math.round(u.valToPos(previousSampleValue, 'x', false));
              const previousSamplePosY = Math.round(
                u.valToPos(previousAnnotationVoltage, 'y', false),
              );

              const shouldRenderAnnotation = shouldRenderChartAnnotation(
                previousAnnotationVoltage,
                currentAnnotationVoltage,
              );

              if (shouldRenderAnnotation) {
                $(u.root.querySelector('.u-over')).append(
                  buildIntervalIndicator({
                    fromX: previousSamplePosX,
                    fromY: previousSamplePosY,
                    toX: currentSamplePosX,
                    toY: currentSamplePosY,
                    content: `${(currentSampleValue - previousSampleValue) / 200}s`,
                  }),
                );
              }

              previousSampleIndex = idx;
            });
          },
        ],
      },
      plugins: [],
      legend: { show: false },
      scales: { x: { time: true }, y: { range: [-2, 3] } },
    }),
    [t, dateRange, duration, recordingStartedAt, rpeaksIndexes, showTitle],
  );

  const chartData = useMemo(() => {
    const episodeContentTopSeries = createEpisodeContentSeries({
      xAxis,
      start: startIndex,
      end: endIndex,
      yAxisValue: 2,
    });
    const episodeContentBottomSeries = createEpisodeContentSeries({
      xAxis,
      start: startIndex,
      end: endIndex,
      yAxisValue: -2,
    });

    return [xAxis, signal, episodeContentTopSeries, episodeContentBottomSeries];
  }, [endIndex, signal, startIndex, xAxis]) as uPlot.AlignedData;

  return <UPlotReact key="pause-episode-chart" options={options} data={chartData} />;
}
