function average(arr) {
  const number = arr.reduce((a, b) => a + b) / arr.length;

  return Math.round(number);
}

export function chunkArray(arr, size, avgFn) {
  const getAverage = avgFn || average;

  const result = [];

  for (let i = 0; i < arr.length; i += size) {
    const chunk = arr.slice(i, i + size);

    const median = getAverage(chunk);

    const medianIndex = Math.round(chunk.length / 2);
    const res = chunk.map((_, idx) => (idx === medianIndex ? median : null));

    result.push(...res);
  }

  return result;
}
