import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AnnotationValue, RecordingJson } from 'types/schema.type';
import { ExcerptChart } from './ExcerptChart';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  chart: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export enum EctopyType {
  VentricularDuplet = 'VentricularDuplet',
  VentricularTriplet = 'VentricularTriplet',
  VentricularRun = 'VentricularRun',
  SupraVentricularDuplet = 'SupraVentricularDuplet',
  SupraVentricularTriplet = 'SupraVentricularTriplet',
  SupraVentricularRun = 'SupraVentricularRun',
}

type Beat = {
  sequenceStartedAt: number;
  annotationValue: AnnotationValue;
  sampleIndexes: number[];
  excerpt: RecordingJson;
};

type EctopyChartListProps = {
  title: EctopyType;
  beats: Beat[] | undefined;
};

export function EctopyChartList(props: EctopyChartListProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!props?.beats?.length) {
    return null;
  }

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        {t('ectopyChartList.titleExcerpt', { title: t(`ectopyChartList.${props.title}`) })}
      </Typography>
      {props.beats.map((beat) => (
        <div className={classes.chart} key={beat.sampleIndexes[0]}>
          <ExcerptChart
            title={t(`excerptChart.${props.title}`)}
            sequenceStartedAt={beat.sequenceStartedAt}
            sampleIndexes={beat.sampleIndexes}
            annotationValue={beat.annotationValue}
            signal={beat.excerpt.ch1}
          />
        </div>
      ))}
    </Container>
  );
}
