import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { HistogramChart as Histogram } from 'components/Histogram/HistogramChart';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export function HistogramChart({ rate }: { rate: number[] }) {
  const classes = useStyles();

  return (
    <Container className={classes.root} component={Paper}>
      <Histogram rate={rate} />
    </Container>
  );
}
