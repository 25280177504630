import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { RecordingJson } from 'types/schema.type';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { BradycardiaChart } from './BradycardiaChart';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  chart: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type Episode = {
  recordingStartedAt?: number;
  startIndex: number;
  endIndex: number;
  lowestAvgBeatIndex: number;
  excerpt: RecordingJson;
  avgBeat: number;
  isValid: boolean;
};

type EpisodeChartListProps = {
  episodes: Episode[] | undefined;
  rpeaks: number[];
  isLoading: boolean;
  edit?: boolean;
};

export function BradycardiaChartList(props: EpisodeChartListProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const validEpisodes =
    props.episodes?.filter((ep) => ep.isValid && ep?.excerpt?.ch1.length > 0) || [];

  // We want to show only the first 3 episodes with the lowest average heart rate
  const getThreeEpisodesWithLowestHearRates = () =>
    validEpisodes.sort((a, b) => a.avgBeat - b.avgBeat).slice(0, 3);

  const episodes = props.edit ? validEpisodes : getThreeEpisodesWithLowestHearRates();

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        {t('bradycardiaChart.listTitle')}
      </Typography>
      {props.isLoading ? (
        <LoadingSpinner />
      ) : (
        episodes?.map((episode) => (
          <div className={classes.chart} key={episode.startIndex}>
            <BradycardiaChart
              recordingStartedAt={episode.recordingStartedAt ?? 0}
              startIndex={episode.startIndex}
              endIndex={episode.endIndex}
              smallestAverageIndex={episode.lowestAvgBeatIndex}
              signal={episode.excerpt.ch1}
              rpeaks={props.rpeaks}
            />
          </div>
        ))
      )}
    </Container>
  );
}
