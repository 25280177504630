import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { gql, useMutation } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useUser } from 'actions/useUser';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - 120px)`, // subtract the height of the sticky header
  },
  message: {
    marginBottom: theme.spacing(2),
  },
}));

const EmailVerificationError = () => {
  const classes = useStyles();
  const [resendEmailVerificationMutation, { data, error }] = useMutation(gql`
    mutation Mutation {
      resendEmailVerification {
        success
        error
      }
    }
  `);

  async function resendVerificationEmail() {
    await resendEmailVerificationMutation();
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.message}>
        Please verify your email to see content
      </Typography>
      <Button variant="contained" color="primary" onClick={resendVerificationEmail}>
        Resend verification email
      </Button>
      {error && <Alert severity="error">{error.message}</Alert>}
      {data && data.resendEmailVerification.success && (
        <Alert severity="success">
          Email sent! Please check your inbox and click the link to verify your email.
        </Alert>
      )}
    </div>
  );
};

export const ProtectedRoute = ({ component: Component, ...args }: React.PropsWithChildren<any>) => {
  const { user, loading } = useUser();

  if (loading) {
    return <div>Loading user...</div>;
  }

  if (user?.isImpersonated) {
    return <Component />;
  }

  if (user && !user?.emailVerified) {
    return <EmailVerificationError />;
  }

  const AuthenticatedComponent = withAuthenticationRequired(Component, args);

  return <AuthenticatedComponent />;
};
