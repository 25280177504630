import { gql } from '@apollo/client';

export const DELETE_POINT_COMMENT = gql`
  mutation Mutation($deletePointCommentId: MongoObjectID) {
    deletePointComment(id: $deletePointCommentId) {
      success
      error
      pointComment {
        id
        sampleIndex
      }
    }
  }
`;
