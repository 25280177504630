import { User } from '@auth0/auth0-react';
import { format } from 'date-fns';
import { Role } from 'types/schema.type';

function getUserSex(auth0User: User | undefined) {
  const defaultValue = 'N/A';
  const validValues = ['MALE', 'FEMALE'];

  const value = auth0User?.['https://zive.io/sex'];

  if (validValues.includes(value)) return value;

  return defaultValue;
}

function getUserAge(auth0User: User | undefined) {
  const defaultValue = '';

  const value: Date = auth0User?.['https://zive.io/dateOfBirth'];

  if (!value) return defaultValue;

  try {
    const date = format(new Date(value), 'yyy-MM-dd');

    return date;
  } catch (error) {
    console.error(error);

    return defaultValue;
  }
}

function getUserWeight(auth0User: User | undefined) {
  const defaultValue = '';

  const value = auth0User?.['https://zive.io/weight'];

  const number = parseInt(value, 10);

  if (Number.isNaN(number)) return defaultValue;

  if (number > 0 && number < 300) return value;

  return defaultValue;
}

function getUserHeight(auth0User: User | undefined) {
  const defaultValue = '';

  const value = auth0User?.['https://zive.io/height'];

  const number = parseInt(value, 10);

  if (Number.isNaN(number)) return defaultValue;

  if (number > 0 && number < 300) return value;

  return defaultValue;
}

export class UserMapper {
  static toDto(auth0User: User | undefined) {
    return {
      id: auth0User?.sub?.split('auth0|')[1] || '',
      email: auth0User?.email || '',
      name: auth0User?.given_name || '',
      surname: auth0User?.family_name || '',
      role: auth0User?.['https://zive.io/role'] || Role.User,
      sex: getUserSex(auth0User),
      dateOfBirth: getUserAge(auth0User),
      weight: getUserWeight(auth0User),
      height: getUserHeight(auth0User),
    };
  }
}
