import { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { format, add } from 'date-fns';
import { useCheckSubscription } from 'hooks/useCheckSubscription';

const ACTIVATE_CODE = gql`
  mutation ActivateCode($code: String!, $userId: ID!) {
    activateCode(code: $code, userId: $userId) {
      success
      error
      activationCode {
        id
        code
        isActive
        userId
        createdAt
        activatedAt
      }
    }
  }
`;

const validationSchema = yup.object({
  activationCode: yup.string().required('Activation Code is required').min(16).max(16),
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 400, // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  subscription: {
    paddingTop: theme.spacing(3),
  },
}));

interface UpdateValues {
  activationCode: string;
}

export function ActivateCodeForm({ user }) {
  const classes = useStyles();

  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState(false);

  const { data, loading } = useCheckSubscription();

  const [activateCodeMutation] = useMutation(ACTIVATE_CODE);

  const formik = useFormik<UpdateValues>({
    initialValues: {
      activationCode: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm, validateForm }) => {
      if (error) setError('');
      try {
        const res = await activateCodeMutation({
          variables: {
            code: values.activationCode,
            userId: user.id,
          },
        });

        const { success, error: activateCodeError } = res?.data?.activateCode;

        if (!success) {
          // Handle mutation error
          setError(activateCodeError);
          setSubmitting(false);
          return;
        }

        // Handle success
        setOpen(true);
        setError('');
        resetForm();
      } catch (error: any) {
        // Handle any other errors
        setError(error.message);
        setSubmitting(false);
      }
    },
  });

  if (loading) return <p>Loading...</p>;

  const isSubscribed = data?.isSubscribed;
  const activatedAt = data?.activationCode?.activatedAt;

  const handleClose: any = (_event, reason) => {
    if (reason === 'clickaway') return;
    setError('');
    setOpen(false);
  };

  let subscriptionValidUntil = '';

  if (activatedAt) {
    const nextYear = add(activatedAt, { years: 1 });

    subscriptionValidUntil = format(nextYear, 'yyyy-MM-dd');
  }

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <SubscriptionsIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Subscription Activation
        </Typography>

        {isSubscribed ? (
          <Typography className={classes.subscription} component="h3" variant="h6">
            Your subscription is active until {subscriptionValidUntil}
          </Typography>
        ) : (
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Grid spacing={2} container>
              <Grid xs={12} item>
                <TextField
                  variant="outlined"
                  id="activationCode"
                  label="Activation Code"
                  name="activationCode"
                  value={formik.values.activationCode}
                  error={formik.touched.activationCode && Boolean(formik.errors.activationCode)}
                  helperText={formik.touched.activationCode && formik.errors.activationCode}
                  required
                  fullWidth
                  onChange={formik.handleChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!formik.isValid || formik.isSubmitting}
                  fullWidth
                >
                  Activate
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          elevation={6}
          variant="filled"
          severity={error ? 'error' : 'success'}
          onClose={handleClose}
        >
          {error || 'Subscription successfully activated!'}
        </Alert>
      </Snackbar>
    </div>
  );
}
