import { gql } from '@apollo/client';

export const RECORDING_JSON = gql`
  query RecordingJson($getMyRecordingId: MongoObjectID!) {
    getMyRecording(id: $getMyRecordingId) {
      id
      recordingStartedAt
      channelCount
      comment
      analysis {
        id
        annotations {
          sampleIndex
          annotationValue
        }
      }
      json {
        ch1
        ch2
        ch3
      }
      pointComments {
        id
        recordingId
        sampleIndex
        comment
      }
    }
  }
`;
