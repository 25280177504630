import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { useLocalStorage } from 'hooks/useLocalStorage';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
    },
    sections: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    column: {
      flex: 1, // Take up equal space in the flex container
      backgroundColor: theme.palette.background.paper,
      margin: theme.spacing(1),
    },
  }),
);

type HiddenSections = {
  hideUserInfo?: boolean;
  hideSummary?: boolean;
  hideHistogram?: boolean;
  hideRateChart?: boolean;
  hideHealthIndications?: boolean;
  hideAFib?: boolean;
  hideBradycardia?: boolean;
  hidePauseEpisodes?: boolean;
  hideWarnings?: boolean;
  hideQtcChart?: boolean;
  hideExtremesHR?: boolean;
  hideEctopyCharts?: boolean;
  hideClusters?: boolean;
  hideHistogramSummary?: boolean;
};

const sectionOptions: { key: keyof HiddenSections; label: string }[] = [
  { key: 'hideUserInfo', label: 'Hide User Information' },
  { key: 'hideSummary', label: 'Hide Summary' },
  { key: 'hideHistogram', label: 'Hide Histogram Chart' },
  { key: 'hideRateChart', label: 'Hide Rate Chart' },
  { key: 'hideHealthIndications', label: 'Hide Health Indications Table' },
  { key: 'hideAFib', label: 'Hide AFib Table' },
  { key: 'hideBradycardia', label: 'Hide Bradycardia Table' },
  { key: 'hidePauseEpisodes', label: 'Hide Pause Episodes Table' },
  { key: 'hideHistogramSummary', label: 'Hide Beats Summary' },
  { key: 'hideQtcChart', label: 'Hide Qtc Interval Chart' },
  { key: 'hideExtremesHR', label: 'Hide Extremes Heart Rate Chart' },
  { key: 'hideEctopyCharts', label: 'Hide Ectopy Charts' },
  { key: 'hideClusters', label: 'Hide Cluster Charts' },
  { key: 'hideWarnings', label: 'Hide Warnings' },
];

type SectionVisibilityListProps = {
  sequenceId: string;
};

export function SectionVisibilityList({ sequenceId }: SectionVisibilityListProps) {
  const classes = useStyles();
  const [hiddenSections, setHiddenSections] = useLocalStorage(`hiddenSections_${sequenceId}`, {});

  const handleToggle = (key: string) => () => {
    setHiddenSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const middleIndex = Math.ceil(sectionOptions.length / 2);
  const leftColumnOptions = sectionOptions.slice(0, middleIndex);
  const rightColumnOptions = sectionOptions.slice(middleIndex);

  return (
    <Container className={classes.root} component={Paper}>
      <Typography variant="h6">Edit Report Sections</Typography>
      <div className={classes.sections}>
        <List className={classes.column} dense>
          {leftColumnOptions.map(({ key, label }) => (
            <ListItem key={key} button onClick={handleToggle(key)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={!!hiddenSections[key]}
                  tabIndex={-1}
                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${key}` }}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={`checkbox-list-label-${key}`} primary={label} />
            </ListItem>
          ))}
        </List>
        <List className={classes.column} dense>
          {rightColumnOptions.map(({ key, label }) => (
            <ListItem key={key} button onClick={handleToggle(key)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={!!hiddenSections[key]}
                  tabIndex={-1}
                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${key}` }}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={`checkbox-list-label-${key}`} primary={label} />
            </ListItem>
          ))}
        </List>
      </div>
    </Container>
  );
}
