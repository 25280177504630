import React, { useState, useEffect } from 'react';

/**
 * A custom hook for managing state with session storage.
 *
 * @param key - The key under which the value is stored in session storage.
 * @param initialValue - The initial value to use if no value is found in session storage.
 * @returns A tuple containing the current value and a function to update it.
 */
function useSessionStorage<T>(
  key: string,
  initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  // Get the initial value from session storage, or use the provided initial value
  const getStoredValue = (): T => {
    try {
      const storedValue = sessionStorage.getItem(key);

      if (storedValue) {
        return JSON.parse(storedValue) as T;
      }
    } catch (error) {
      // If there's an error parsing JSON, use the initial value
      console.error(`Error parsing session storage key "${key}":`, error);
    }

    return initialValue;
  };

  const [value, setValue] = useState<T>(getStoredValue);

  useEffect(() => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting session storage key "${key}":`, error);
    }
  }, [key, value]);

  return [value, setValue];
}

export default useSessionStorage;
