import { formatDateTimeRange, formatTimeDuration } from './formatTime';

export function getSequenceDuration({
  from,
  to,
  seconds,
}: {
  from: number;
  to: number;
  seconds: number;
}) {
  const dateRange = formatDateTimeRange({
    fromMiliseconds: from,
    toMiliseconds: to,
  });

  const durationHumanReadable = formatTimeDuration({ durationInMilliseconds: seconds * 1000 });

  const itemText = `${dateRange} (${durationHumanReadable})`;

  return itemText;
}
