import { gql } from '@apollo/client';

export const UPDATE_RECORDING = gql`
  mutation UpdateRecordingMutation(
    $updateRecordingInput: RecordingInput!
    $updateRecordingId: MongoObjectID
  ) {
    updateRecording(input: $updateRecordingInput, id: $updateRecordingId) {
      success
      error
      recording {
        id
        flags
        comment
        externalAnnotationMetadata {
          startedAt
          completedAt
          startedBy
          completedBy
        }
      }
    }
  }
`;
