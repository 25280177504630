import { Navigate, Route, Routes } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { Role } from 'types/schema.type';
import { ProvideChartSettings } from 'hooks/use-chart-settings';
import { FileUploader } from 'components/uploader/FileUploader';
import { Header } from 'components/header';
import { Footer } from 'components/footer/footer.comopnent';
import { RecordingList } from './components/RecordingList';
import { Account } from './components/features/account/Account';
import { PatientList } from './components/features/patients/PatientList';
import { AdminDashboard } from './components/dashboard/AdminDashboard';
import { ProtectedRoute } from './components/ProtectedRoute';
import { RecordingView } from './components/dashboard/RecordingView';
import {
  SequenceReport,
  SequenceLayout,
  SequenceListItem,
  SequenceListItemEdit,
} from './components/report';
import { RequiredRoles } from './components/RequireRoles';
import { UploadProvider } from './context/UploadContext';

import '../node_modules/uplot/dist/uPlot.min.css';
import './App.css';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <ProvideChartSettings>
      <UploadProvider>
        <div className={classes.wrapper}>
          <Header />

          <Routes>
            {/* Default route to redirect to /recording-sequences */}
            <Route path="/" element={<Navigate to="/recording-sequences" replace />} />
            
            <Route
              path="/recording-sequences/*"
              element={<ProtectedRoute component={() => <SequenceLayout />} />}
            >
              <Route
                path=":sequenceId"
                element={<ProtectedRoute component={() => <SequenceListItem />} />}
              />
              <Route
                path=":sequenceId/edit"
                element={
                  <ProtectedRoute
                    component={() => (
                      <RequiredRoles requiredRoles={[Role.Doctor, Role.Clinic]}>
                        <SequenceListItemEdit />
                      </RequiredRoles>
                    )}
                  />
                }
              />
            </Route>
            <Route
              path="/recordings"
              element={
                <ProtectedRoute
                  component={() => (
                    <RequiredRoles requiredRoles={[Role.User, Role.Doctor, Role.Clinic]}>
                      <RecordingList />
                    </RequiredRoles>
                  )}
                />
              }
            />
            <Route
              path="/upload"
              element={
                <ProtectedRoute
                  component={() => (
                    <RequiredRoles requiredRoles={[Role.Doctor, Role.Clinic]}>
                      <FileUploader />
                    </RequiredRoles>
                  )}
                />
              }
            />
            <Route
              path="/patients"
              element={
                <ProtectedRoute
                  component={() => (
                    <RequiredRoles requiredRoles={[Role.Clinic]}>
                      <PatientList />
                    </RequiredRoles>
                  )}
                />
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoute
                  component={() => (
                    <RequiredRoles requiredRoles={[Role.User, Role.Doctor, Role.Clinic]}>
                      <Account />
                    </RequiredRoles>
                  )}
                />
              }
            />
            <Route
              path="/admin-dashboard"
              element={
                <ProtectedRoute
                  component={() => (
                    <RequiredRoles requiredRoles={[Role.Admin]}>
                      <AdminDashboard />
                    </RequiredRoles>
                  )}
                />
              }
            />
            <Route
              path="/admin-dashboard/recording"
              element={
                <ProtectedRoute
                  component={() => (
                    <RequiredRoles requiredRoles={[Role.Admin]}>
                      <RecordingView />
                    </RequiredRoles>
                  )}
                />
              }
            />
            <Route path="/report/:sequenceId" element={<SequenceReport />} />
          </Routes>
          <Footer />
        </div>
      </UploadProvider>
    </ProvideChartSettings>
  );
}
