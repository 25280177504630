/* eslint-disable react/jsx-props-no-spreading */
import { Navigate, useLocation } from 'react-router';

import { Role } from 'types/role';
import { useUser } from 'actions/useUser';

function hasUserRequiredRole(user, requiredRoles, roleType) {
  const isAdminUser = user.role === roleType.ADMIN;

  if (isAdminUser) return true;

  return requiredRoles.includes(user.role);
}

const defaultPath = '/recording-sequences';

export function RequiredRoles({ children, requiredRoles }) {
  const location = useLocation();
  const { user, loading } = useUser();

  if (loading) return <div>Loading user info...</div>;

  if (!user) return <Navigate to={defaultPath} state={{ from: location }} replace />;

  if (location.pathname === '/account' && user.isImpersonated) {
    return <Navigate to={defaultPath} state={{ from: location }} replace />;
  }

  if (!hasUserRequiredRole(user, requiredRoles, Role)) {
    return <Navigate to={defaultPath} state={{ from: location }} replace />;
  }

  return children;
}
