import { useQuery, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { SequenceResponse, SequenceStatus, User } from 'types/schema.type';
import { LoadingSpinner } from '../LoadingSpinner';
import { getSequenceAnalysisAnnotations } from './utils/getSequenceAnnotations';
import { getSequenceAnalysisEpisodes } from './utils/getSequenceEpisodes';
import { getSequenceDuration } from './utils/getSequenceDuration';

import { EctopyType } from './ectopy/EctopyDataWithCharts';
import { calculateAverageRate } from './utils/calculateAverageRate';
import { ANALYSIS_FRAGMENT, EPISODES_AND_AFIBS_FRAGMENT } from './fragments';
import { AFibTableWithCharts } from './afib';
import { getSequenceEctopicBeats } from './utils/getSequenceEctopicBeats';
import { getSequenceAnalysisAutomaticAnnotationsData } from './utils/getSequenceAutomaticAnnotationsData';
import { BradycardiaTableWithCharts } from './bradycardia';
import { PauseEpisodesWithCharts } from './pause';
import { ClusterDataWithCharts } from './cluster';
import { EctopyDataWithCharts } from './ectopy';
import { SectionVisibilityList } from './SectionVisibilityList';

export const GET_SEQUENCE_EDIT_DATA = gql`
  ${ANALYSIS_FRAGMENT}
  ${EPISODES_AND_AFIBS_FRAGMENT}

  query GetSequenceByIdEditData($sequenceId: String!) {
    getSequenceById(sequenceId: $sequenceId) {
      error
      success
      sequence {
        id
        recordingIds
        analysisId
        userId
        status
        startedAt
        endedAt
        analysis {
          ...AnalysisFragment
          ...EpisodesAndAfibsFragment
          clusterData {
            id
            annotationValues {
              annotationValue
              clusters {
                clusterNumber
                rpeakLocations
                excerpts
                isValid
              }
            }
          }
        }
      }
    }
  }
`;

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      name
      surname
      dateOfBirth
      sex
      email
      weight
      height
    }
  }
`;

type GetSequenceTableDataResponse = {
  getSequenceById: SequenceResponse;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonStart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    alignSelf: 'flex-start',
  },
  buttonMark: {
    margin: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(12),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

const ectopyTypes = [
  { key: 'supraVentricularDuplets', display: EctopyType.SupraVentricularDuplet },
  { key: 'supraVentricularTriplets', display: EctopyType.SupraVentricularTriplet },
  { key: 'supraVentricularRuns', display: EctopyType.SupraVentricularRun },
  { key: 'ventricularDuplets', display: EctopyType.VentricularDuplet },
  { key: 'ventricularTriplets', display: EctopyType.VentricularTriplet },
  { key: 'ventricularRuns', display: EctopyType.VentricularRun },
];

export function SequenceListItemEdit() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { sequenceId = '' } = useParams<{ sequenceId: string }>();

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery<{ getCurrentUser: User }>(GET_CURRENT_USER);

  const {
    data: sequenceTableData,
    loading: sequenceTableDataLoading,
    error: sequenceTableDataError,
  } = useQuery<GetSequenceTableDataResponse, { sequenceId: string }>(GET_SEQUENCE_EDIT_DATA, {
    variables: {
      sequenceId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  const isLoading = userLoading || sequenceTableDataLoading;
  const error = userError || sequenceTableDataError;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error)
    return (
      <div id="pdf-ready">
        <div>Error happened while loading data:</div>
        {error.graphQLErrors.map(({ message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i}>{message}</span>
        ))}
      </div>
    );

  if (!userData?.getCurrentUser) return <div id="pdf-ready">User not signed in</div>;

  const sequenceTable = sequenceTableData?.getSequenceById.sequence;

  if (!sequenceTable || !sequenceTable?.analysis) {
    if (sequenceTable?.status === SequenceStatus.Analyzing) {
      return (
        <div id="pdf-ready">
          <p>ECG analysis in progress...</p>
          <p>Your report will be available once ready.</p>
        </div>
      );
    }

    return (
      <div id="pdf-ready">
        <p>Sequence has not been analyzed yet.</p>
        <p>You need to trigger sequence analysis manually by pressing analysis (last) button.</p>
      </div>
    );
  }

  const sequenceAnnotationsTimestampsInSs = getSequenceAnalysisAnnotations(sequenceTable);

  const averageRates = calculateAverageRate(
    sequenceAnnotationsTimestampsInSs,
    sequenceTable.analysis.rate || [],
  );

  const { pauseEpisodes, afibs, bradycardiaEpisodes } = getSequenceAnalysisEpisodes(
    sequenceTable,
    averageRates,
  );

  const rpeakIndexes = sequenceTable.analysis.annotations.map(
    (annotation) => annotation.sampleIndex,
  );

  const ectopicBeats = getSequenceEctopicBeats(sequenceTable);

  const { clusterData } = getSequenceAnalysisAutomaticAnnotationsData(sequenceTable);

  const duration = sequenceTable.endedAt - sequenceTable.startedAt;

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="default"
        className={classes.buttonStart}
        onClick={() => navigate(`/recording-sequences/${sequenceTable.id}`)}
      >
        Go Back
      </Button>
      <Typography variant="h5" className={classes.title} gutterBottom>
        {`Edit ECG Report ${getSequenceDuration({
          from: sequenceTable.startedAt,
          to: sequenceTable.endedAt,
          seconds: duration / 1000,
        })}`}
      </Typography>

      <SectionVisibilityList sequenceId={sequenceId} />

      <AFibTableWithCharts afibs={afibs} rpeaks={rpeakIndexes} edit />

      <BradycardiaTableWithCharts
        bradycardiaEpisodes={bradycardiaEpisodes}
        rpeaks={rpeakIndexes}
        sequenceDurationMs={duration}
        analysisId={sequenceTable.analysis.id}
        edit
      />

      <PauseEpisodesWithCharts
        pauseEpisodes={pauseEpisodes}
        rpeaks={rpeakIndexes}
        analysisId={sequenceTable.analysis.id}
        edit
      />

      {ectopyTypes.map((type) => (
        <EctopyDataWithCharts
          key={type.key}
          title={type.display}
          ectopyItems={sequenceTable?.analysis?.[type.key] ?? []}
          sequenceStartedAt={sequenceTable.startedAt}
          beats={ectopicBeats[type.key] ?? []}
        />
      ))}

      <ClusterDataWithCharts data={clusterData} />
    </div>
  );
}
