import { createContext, useContext, useState } from 'react';

const UploadContext = createContext();

export const useUploadContext = () => useContext(UploadContext);

export const UploadProvider = ({ children }) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);

  return (
    <UploadContext.Provider value={{ uploadInProgress, setUploadInProgress }}>
      {children}
    </UploadContext.Provider>
  );
};
