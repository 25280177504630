import { gql } from '@apollo/client';

export const DELETE_AFIB = gql`
  mutation DeleteAFibMutation($deleteAFibId: MongoObjectID) {
    deleteAFib(id: $deleteAFibId) {
      success
      error
      afib {
        id
        startIndex
        endIndex
        annotationValue
      }
    }
  }
`;
